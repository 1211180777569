import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Paper, Typography } from '@material-ui/core';
import Draggable from 'react-draggable';
import Global from '../../../Contexts/Global';
import BasicButton from '../BasicButton';
import BasicModalHeader from '../BasicModalHeader';
import useStyles from './style';

export default function BasicModal(props) {
  const {
    children,
    open,
    closeFunction,
    isMessage,
    message,
    title,
    lowMargin,
    acceptFunction,
    acceptData,
  } = props;
  const { i18n } = useContext(Global);
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={closeFunction}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Draggable handle=".handle">
          <Paper style={{ padding: 16, textAlign: 'center', maxHeight: '85vh', maxWidth: '80vw', minHeight: '20vh', minWidth: '50vh', overflowY: 'auto' }}>
            <BasicModalHeader title={title} lowMargin={lowMargin} onClose={() => closeFunction()} />
            {isMessage
              ? (
                <>
                  <Typography style={{ marginBottom: '10px' }}>{message}</Typography>
                  <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <BasicButton title={i18n.t('CANCEL')} onClick={() => closeFunction()} />
                    <BasicButton title={i18n.t('ACCEPT')} onClick={() => acceptFunction && acceptFunction(acceptData && acceptData)} />
                  </div>
                </>
              )
              : children}
          </Paper>
        </Draggable>
      </Fade>
    </Modal>
  );
}

BasicModal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  closeFunction: PropTypes.func.isRequired,
  isMessage: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  acceptFunction: PropTypes.func,
  acceptData: PropTypes.shape({}),
  lowMargin: PropTypes.bool,
};

BasicModal.defaultProps = {
  children: null,
  isMessage: false,
  message: '',
  title: '',
  acceptFunction: null,
  acceptData: {},
  lowMargin: false,
};
