import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(3),
  },
  buttonSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '20px 0px 40px 0px',
  },
  paperStyle: {
    margin: '30px 0',
  },
  paperSection: {
    display: 'flex',
    padding: '20px',
  },
}));
export default useStyles;
