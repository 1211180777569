import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  columnTitle: {
    backgroundColor: theme.palette.primary.light,
    fontWeight: 600,
  },
  row: {
    position: 'relative',
    height: '2vh',
    borderBottom: '1px solid lightgray',
  },
  cell: {
    border: 'none',
    height: '40px',
  },
  desplegableCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '39px',
    position: 'absolute',
    right: 16,
    padding: '16.5px',
    backgroundColor: 'white',
    borderLeft: '1px solid lightgray',
    fontSize: '12px',
  },
  arrowBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
    boxShadow: '0 0 0 1px lightgray',
    borderRadius: '3px',
    backgroundColor: 'white',
    maxWidth: '27px',
    maxHeight: '31px',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
    cursor: 'pointer',
  },
  textBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 6px',
    boxShadow: '0 0 0 1px lightgray',
    borderRadius: '3px',
    color: 'white',
    fontWeight: 600,
    backgroundColor: '#5992FB',
    '&:hover': {
      backgroundColor: '#4a7ad4',
    },
    cursor: 'pointer',
  },
  cellContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
  },
  warningStatus: {
    width: 'fit-content',
    backgroundColor: 'orange',
  },
  pendingStatus: {
    width: 'fit-content',
    backgroundColor: 'lightgray',
  },
  canceledStatus: {
    width: 'fit-content',
    color: 'white',
    backgroundColor: 'black',
  },
  typography: {
    fontSize: '14px',
  },
  link: {
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
