/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress, Divider, Paper, Typography } from '@material-ui/core';
import Global from '../../Contexts/Global';
import Form from '../../Components/Generic/Form';
import Table from '../../Components/Generic/Table';
import BasicModal from '../../Components/Generic/BasicModal';
import { sendAction, getReceiversList, setCleanList, getClients } from '../../redux/actions/globalActions';
import { getActualModal } from '../../redux/actions/modalActions';
import { createReceiversFilterData, createData, initialReceiversFilterValues } from './config';
import useStyles from './style';

function List({ dispatch, apiClients, receiversList, cleanList, actualModal }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const location = useLocation();
  const [formDataValues, setFormDataValues] = useState(initialReceiversFilterValues);
  const [clients, setClients] = useState(apiClients);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isFormOpened, setIsFormOpened] = useState(false);
  const [previousLink, setPreviousLink] = useState(null);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [setResetParam] = useState('');
  const [modal, setModal] = useState(actualModal);
  const [openModal, setOpenModal] = useState(!!actualModal);
  const [popupForm, setPopupForm] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [popup, setPopup] = useState({});
  const [deleteUrl, setDeleteUrl] = useState('');

  function handleAccept() {
    dispatch(sendAction({ link: deleteUrl, method: 'PUT' }));
    setOpenPopup(false);
  }
  function handleCancel() {
    setOpenPopup(false);
  }

  function handleOpenPopup(name, link) {
    setPopup({
      header: name,
      message: i18n.t('DELETE_RECEIVER'),
    });
    setOpenPopup(true);
    setDeleteUrl(link);
  }

  useEffect(() => {
    if (!receiversList || !receiversList?.items) {
      if (cleanList) {
        dispatch(setCleanList(false));
      } else {
        dispatch(getReceiversList({ order, orderBy, formDataValues, rowsPerPage }));
        dispatch(setCleanList());
      }
    }
    setOpenModal(false);
    dispatch(getClients());
  }, []);

  useEffect(() => {
    const newFormData = {
      grid: { style: { width: '100%', margin: 'inherit' }, spacing: 1 },
      inputs: [],
      buttons: [
        { label: 'Si', gridProps: { lg: 6, style: { display: 'flex', justifyContent: 'center' } }, buttonProps: { variant: 'contained', onClick: handleAccept } },
        { label: 'No', gridProps: { lg: 6, style: { display: 'flex', justifyContent: 'flex-start' } }, buttonProps: { variant: 'outlined', onClick: handleCancel } },
      ],
    };
    setPopupForm(newFormData);
  }, [deleteUrl]);

  useEffect(() => {
    setClients(apiClients);
  }, [apiClients]);

  useEffect(() => {
    setOpenModal(!!actualModal);
    setModal(actualModal);
  }, [actualModal]);

  function getActionFromLink({ name, link }) {
    let linkFunction;
    if (name.toLowerCase() === 'borrar') {
      linkFunction = () => {
        handleOpenPopup(name, link);
      };
    } else {
      linkFunction = () => {
        const url = new URL(link);
        window.location.href = url.pathname;
      };
    }

    return linkFunction;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getClientsList() {
    let clientList = [];
    if (clients && clients.items) {
      clientList = clients.items.map((client) => ({
        id: client.client_id, name: `${client.client_code} - ${client.client_name}` }));
    }
    return clientList;
  }

  async function handleRequestSort(column) {
    let columnName;
    switch (column) {
      case 'Código':
        columnName = 'codi';
        break;
      case 'Nombre':
        columnName = 'nom';
        break;
      case 'Dirección':
        columnName = 'direccio';
        break;
      case 'Cliente':
        columnName = 'client';
        break;
      default:
        columnName = '';
        break;
    }
    setActiveSpinner(true);
    const isAsc = orderBy === columnName && order === 'asc';
    setOrderBy(columnName);
    setOrder(isAsc ? 'desc' : 'asc');
    dispatch(getReceiversList({ url: receiversList?.links?.self?.href, order, orderBy: columnName, formDataValues, rowsPerPage }));
    setTimeout(() => setActiveSpinner(false), 1000);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    if (previousLink) {
      dispatch(getReceiversList({ url: receiversList?.links?.prev?.href, order, orderBy, formDataValues, rowsPerPage }));
    } else {
      dispatch(getReceiversList({ url: receiversList?.links?.next?.href, order, orderBy, formDataValues, rowsPerPage }));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    if (previousLink) {
      dispatch(getReceiversList({ url: receiversList?.links?.prev?.href, order, orderBy, formDataValues, rowsPerPage: parseInt(event.target.value, 10), page: 1 }));
    } else {
      dispatch(getReceiversList({ url: receiversList?.links?.next?.href, order, orderBy, formDataValues, rowsPerPage: parseInt(event.target.value, 10), page: 1 }));
    }
  };

  const tableData = receiversList?.items ? {
    rows: receiversList?.items?.map((receiver) => createData(
      receiver?.item[1]?.field_value || '-',
      receiver?.item[2]?.field_value || '-',
      receiver?.item[3]?.field_value || '-',
      receiver?.item[4]?.field_value || '-',
      {
        actions: receiver.actions.map((action) => (
          {
            title: capitalizeFirstLetter(action?.name),
            icon: action?.icon,
            function: getActionFromLink({ name: action?.name, link: action?.link }),
          })),
      },
    )),
    titles: [
      { align: 'left', title: 'Código' },
      { align: 'left', title: 'Nombre' },
      { align: 'left', title: 'Dirección' },
      { align: 'left', title: 'Cliente' },
      { align: 'left', title: 'Acciones' },
    ],
    meta: receiversList.meta,
  } : null;

  function handleOnSubmit(e) {
    e.preventDefault();
    dispatch(getReceiversList({ url: receiversList?.links?.self?.href, order, orderBy, formDataValues, rowsPerPage }));
  }

  const onClear = (e) => {
    setOrder(false);
    setOrderBy(false);
    document.querySelector('form input').name = '';
    document.querySelector('form input').value = '';
    document.querySelector('form button').click();
    setFormDataValues(initialReceiversFilterValues);
    dispatch(getReceiversList({ formDataValues: initialReceiversFilterValues, rowsPerPage }));
  };

  return (
    <div>
      <Paper>
        <div style={{ padding: 10, paddingBottom: 25 }}>
          <div className={classes.bookingsFound}>
            <Typography variant="h4">{`Encontrados ${receiversList?.meta?.totalCount || 0} destinatarios`}</Typography>
          </div>
          <Divider />
          <Form newForm={createReceiversFilterData({ i18n, formDataValues, onClear, setFormDataValues, getClientsList })} onSubmit={handleOnSubmit} formOpenedObject={{ setter: setIsFormOpened, value: isFormOpened }} />
        </div>
      </Paper>

      {!tableData && (
        <div className={classes.spinnerContainer}>
          <div className={classes.spinnerBox}>
            <CircularProgress />
          </div>
        </div>
      )}
      {tableData && (
        <>
          <div id="inquiriesList">
            <Table
              className={classes.customTableStyles}
              tableData={tableData}
              hasStatusColors
              maxHeight={isFormOpened ? '46vh' : '63vh'}
              orderBy={orderBy}
              orderProducts={order}
              onOrderProducts={handleRequestSort}
              pageObject={{ page, handleChangePage }}
              rowsPerPageObject={{ rowsPerPage, handleChangeRowsPerPage }}
              pageCount={receiversList?.meta?.totalCount || 0}
              activeSpinner={activeSpinner}
            />
          </div>
          {(openPopup) && (
            <BasicModal
              open={openPopup}
              title={popup?.header}
              closeFunction={() => { setOpenPopup(false); }}
            >
              {Boolean(popup?.message) && (
                <div style={{ marginTop: '20px' }}>
                  <span>{popup?.message}</span>
                </div>
              )}
              {Boolean(popupForm) && (
                <div style={{ marginTop: '60px' }}>
                  <Form newForm={popupForm} />
                </div>
              )}
            </BasicModal>
          )}
        </>
      )}
    </div>
  );
}

List.propTypes = {
  receiversList: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({
      totalCount: PropTypes.number,
    }),
    links: PropTypes.shape({
      prev: PropTypes.shape({
        href: PropTypes.string,
      }),
      self: PropTypes.shape({
        href: PropTypes.string,
      }),
      next: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
  }),
  dispatch: PropTypes.func.isRequired,
  cleanList: PropTypes.bool,
  reduxTable: PropTypes.shape({}).isRequired,
  apiClients: PropTypes.shape({}),
  actualModal: PropTypes.shape({}),
};

List.defaultProps = {
  receiversList: {},
  cleanList: false,
  apiClients: [],
  actualModal: {},
};

function mapStateToProps({ globalReducer, tableReducer, modalReducer }) {
  return {
    receiversList: globalReducer.receiversList,
    receiversListError: globalReducer.loadError,
    cleanList: globalReducer.cleanList,
    apiClients: globalReducer.apiClients,
    reduxTable: tableReducer.table,
    actualModal: modalReducer.actualModal,
  };
}

export default connect(mapStateToProps)(List);
