import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse, Grid, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import AutocompleteField from '../AutocompleteField';
import Global from '../../../Contexts/Global';
import useStyles from './style';

export default function CollapseGrid({ form, collapse, setCollapse, formOpenedObject }) {
  const classes = useStyles();
  const { i18n } = useContext(Global);

  function fillGridItem(item) {
    let gridItem = null;

    if (item.outerLabel) {
      gridItem = (
        <Typography className={classes.outerLabel}>
          {item.outerLabel}
        </Typography>
      );
    } if (item.space) {
      gridItem = <div />;
    } if (item.labelProps) {
      gridItem = (
        <div
          className={classes.label}
          style={{ backgroundColor: item.labelProps.backgroundColor }}
        >
          <span>{item.textFieldProps.defaultValue}</span>
        </div>
      );
    } if (item.autocompleteProps) {
      gridItem = (
        <AutocompleteField
          className={classes.autocomplete}
          {...item.autocompleteProps}
          item={item}
        />
      );
    }
    return gridItem;
  }

  return (
    <>
      <Collapse in={collapse} {...form.collapseProps}>
        <Grid container {...form.collapseGrid}>
          {form?.collapsed?.map((item) => (
            <Grid id="collapse-items" item {...item.gridProps} key={item.id}>
              {
            fillGridItem(item)
          }
            </Grid>
          ))}
          {collapse && form?.buttons?.map((btn) => <Grid item {...btn.gridProps} key={btn.label}><Button {...btn.buttonProps} color="primary">{btn.label}</Button></Grid>) }
        </Grid>
      </Collapse>
      <Button
        className={classes.moreFilters}
        onClick={() => {
          setCollapse(!collapse);
          formOpenedObject.setter(!formOpenedObject.value);
        }}
        endIcon={collapse ? <ArrowDropUp /> : <ArrowDropDown />}
      >
        {i18n.t('MORE_FILTERS')}
      </Button>
    </>
  );
}

CollapseGrid.propTypes = {
  form: PropTypes.shape({
    collapseProps: PropTypes.shape({}),
    collapseGrid: PropTypes.shape({}),
    collapsed: PropTypes.arrayOf(PropTypes.shape({})),
    buttons: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  collapse: PropTypes.bool.isRequired,
  setCollapse: PropTypes.func.isRequired,
  formOpenedObject: PropTypes.shape({
    setter: PropTypes.func,
    value: PropTypes.bool,
  }),
};

CollapseGrid.defaultProps = {
  formOpenedObject: {},
};
