/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import useStyles from './style';
import { setModalFormData } from '../../../redux/actions/modalActions';
import { setTable } from '../../../redux/actions/tableActions';

const CheckboxCell = ({ item, row, selectedItems, dispatch, reduxTable }) => {
  const classes = useStyles();
  const [boolValue, setBoolValue] = useState(item.value?.field_checked);

  const handleSelectItems = (event, value) => {
    let newSelectedItems = [...selectedItems.items];
    if (value === true) {
      newSelectedItems.push(row.itemID);
    } else {
      newSelectedItems = newSelectedItems.filter((selectedItem) => selectedItem !== row.itemID);
    }
    selectedItems.setItems(newSelectedItems);

    const newReduxTable = {
      ...reduxTable,
      table: {
        ...reduxTable.table,
        rows: reduxTable.table.rows.map((tableRow) => {
          const newTableRow = { ...tableRow };
          if (newTableRow.row.itemID === row.itemID) {
            const keys = Object.keys(newTableRow.row);
            keys.forEach((key) => {
              if (newTableRow.row[key] instanceof Object && newTableRow.row[key].value) {
                newTableRow.row[key].value.field_checked = !newTableRow.row[key].value.field_checked;
              }
            });
          }
          return newTableRow;
        }),
      },
    };
    dispatch(setTable(newReduxTable));
    dispatch(setModalFormData({ id: 'contactos', value: newSelectedItems }));
    setBoolValue(value);
  };

  return (
    <div className={classes.formContainer}>
      <FormControlLabel
        className={classes.checkBox}
        checked={boolValue}
        control={(
          <Checkbox
            style={{ color: boolValue ? 'white' : 'black' }}
            id={item.id}
            onChange={(event, value) => selectedItems.setItems && handleSelectItems(event, value)}
          />
        )}
        labelPlacement="start"
      />
    </div>
  );
};

CheckboxCell.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.shape({
      field_checked: PropTypes.bool,
    }),
  }).isRequired,
  row: PropTypes.shape({}),
  value: PropTypes.number,
  formData: PropTypes.shape({}).isRequired,
  modalFormData: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
};

CheckboxCell.defaultProps = {
  value: -1,
  row: {},
};

function mapStateToProps({ globalReducer, modalReducer, tableReducer }) {
  return {
    formData: globalReducer.formData,
    modalFormData: modalReducer.modalFormData,
    reduxTable: tableReducer.table,
  };
}

export default connect(mapStateToProps)(CheckboxCell);
