import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from './style';

export default function BasicModalHeader({ title, lowMargin, onClose }) {
  const classes = useStyles();

  return (
    <>
      {Boolean(title && title.length) && (
      <div className={lowMargin ? classes.header : classes.headerMargin}>
        <div className="handle" style={{ flexGrow: 1 }}>
          <Typography variant="h6">
            {title}
          </Typography>
        </div>
        <IconButton className={classes.closeButton} aria-label="close" component="span" onClick={() => onClose()}>
          <Close />
        </IconButton>
      </div>
      )}
    </>
  );
}

BasicModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  lowMargin: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
