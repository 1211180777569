import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Global from '../../../Contexts/Global';
import useStyles from './style';
import Table from '../../Generic/Table';
import FormModal from '../FormModal';
import Form from '../../Generic/Form';

export default function TransportOrders({ detail }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const buttons = [];
  const grid = { spacing: 3 };
  const newDetail = { ...detail, form: { ...detail.form, buttons, grid } };

  const newForm = {
    inputs: [
      { label: i18n.t('ORIGIN'), placeholder: '123', textFieldProps: { fullWidth: true, select: true, required: true }, selectOptions: [{ value: 'BCN', label: 'Barcelona' }, { value: 'MDR', label: 'Madrid' }, { value: 'ZRG', label: 'Zaragoza' }], gridProps: { lg: 12 } },
      { label: i18n.t('DATE'), placeholder: '123', textFieldProps: { fullWidth: true, required: true, InputLabelProps: { shrink: true }, type: 'date' }, gridProps: { lg: 12 } },
      { label: i18n.t('HOUR'), placeholder: '123', textFieldProps: { fullWidth: true, InputLabelProps: { shrink: true }, type: 'time' }, gridProps: { lg: 12 } },
      { label: i18n.t('REFERENCE'), placeholder: '123', textFieldProps: { fullWidth: true, required: true }, gridProps: { lg: 12 } },
      { label: i18n.t('SHIPPER'), placeholder: '123', textFieldProps: { fullWidth: true, select: true, required: true }, selectOptions: [{ value: 'BCN', label: 'Barcelona' }, { value: 'MDR', label: 'Madrid' }, { value: 'ZRG', label: 'Zaragoza' }], gridProps: { lg: 12 } },
      { label: i18n.t('DESTINATION_WAREHOUSE'), placeholder: '123', textFieldProps: { fullWidth: true, select: true, required: true }, selectOptions: [{ value: 'BCN', label: 'Barcelona' }, { value: 'MDR', label: 'Madrid' }, { value: 'ZRG', label: 'Zaragoza' }], gridProps: { lg: 12 } },
      { label: i18n.t('OBSERVATIONS'), placeholder: 'Observation', textFieldProps: { fullWidth: true, multiline: true, rows: 6 }, gridProps: { lg: 12 } },
      { space: true, gridProps: { lg: 9 } },
    ],
    buttons: [
      { label: i18n.t('SAVE'), gridProps: { lg: 2 }, buttonProps: { variant: 'contained' } },
    ],
    grid: { spacing: 3 },
  };

  return (
    <div className={classes.mainContainer}>
      <section>
        {Boolean(newDetail?.form?.inputs?.length || newDetail?.form?.buttons?.length)
        && (
        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          <Form newForm={newDetail.form} />
        </div>
        )}
        {Boolean(newDetail?.table?.titles?.length || newDetail?.table?.rows?.length)
        && (
        <Table tableData={newDetail.table} />
        )}
        <FormModal
          open={openModal}
          form={newForm}
          title={i18n.t('NEW_TRANSPORT_ORDER_CAP')}
          setCloseModal={() => setOpenModal(false)}
        />
      </section>
    </div>
  );
}

TransportOrders.propTypes = {
  detail: PropTypes.shape({
    form: PropTypes.shape({}),
    table: PropTypes.shape({}),
  }).isRequired,
};

TransportOrders.defaultProps = {};
