import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import useStyles from './style';

const CheckboxField = ({ item, value, onChange, formData, modalFormData, isModal }) => {
  const classes = useStyles();
  const [boolValue, setBoolValue] = useState(item.textFieldProps?.defaultValue);

  useEffect(() => {
    setBoolValue(isModal ? modalFormData[item.id] : formData[item.id]);
  }, [isModal ? modalFormData[item.id] : formData[item.id]]);

  return (
    <div className={classes.formContainer}>
      <FormControlLabel
        {...item.textFieldProps}
        className={classes.checkBox}
        name={item.name}
        onChange={onChange}
        checked={(value === 0 || value === 1) ? value : boolValue}
        control={<Checkbox color="primary" id={item.id} />}
        label={<span style={{ fontWeight: 'bold', color: 'gray' }}>{item.label}</span>}
        labelPlacement="start"
        style={{ fontWeight: 'bold' }}
      />
    </div>
  );
};

CheckboxField.propTypes = {
  item: PropTypes.shape({
    textFieldProps: PropTypes.shape({
      defaultValue: PropTypes.number,
    }),
    informar: PropTypes.shape({
      value: PropTypes.shape({
        field_checked: PropTypes.bool,
      }),
    }),
    name: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  formData: PropTypes.shape({}).isRequired,
  modalFormData: PropTypes.shape({}).isRequired,
  isModal: PropTypes.bool.isRequired,
};

CheckboxField.defaultProps = {
  value: -1,
};

function mapStateToProps({ globalReducer, modalReducer }) {
  return {
    formData: globalReducer.formData,
    modalFormData: modalReducer.modalFormData,
  };
}

export default connect(mapStateToProps)(CheckboxField);
