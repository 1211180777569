import apiClient from '../../domain/fetchers/apiClient';
import actionTypes from './actionTypes';
import { loadError } from './globalActions';

function setMenuOptions(options) {
  return {
    type: actionTypes.SET_POPUP_OPTIONS,
    data: options,
  };
}

export function emptyMenuOptions() {
  return {
    type: actionTypes.EMPTY_POPUP_OPTIONS,
  };
}

export function getMenuOptions({ id, type }) {
  return async (dispatch) => {
    const orderType = 'order';
    const endpoint = type === orderType ? `clients-orders/other-actions-menu/${id}` : `bookings/other-actions-menu/${id}`;
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };

    try {
      const { status, data } = await apiClient.get(endpoint, config);
      if (status === 200) {
        dispatch(setMenuOptions(data[0]));
      }
    } catch (error) {
      loadError(error);
    }
  };
}

export function sendMenuAction({ link, method }) {
  return async (dispatch) => {
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };

    try {
      const response = await apiClient[method.toLowerCase()](link, config);
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}
