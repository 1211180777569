import actionTypes from './actionTypes';
import apiClient from '../../domain/fetchers/apiClient';
import { emptyActualModal } from './modalActions';
import { emptyFormData } from './globalActions';

function loadError(error) {
  return {
    type: actionTypes.LOAD_ERROR,
    data: error,
  };
}

export function setFiles(files = []) {
  return {
    type: actionTypes.SET_FILES,
    data: files,
  };
}

export function saveDocumentationFiles({ link, files, form }) {
  return async (dispatch) => {
    const endpoint = link;
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(index, file.type);
      formData.append(index, file.file);
    });

    try {
      const response = await apiClient.post(endpoint, formData, config);
      if (response?.data?.success) {
        const formAsArray = Object.keys(form);
        const newFormData = {};

        formAsArray.forEach((key) => {
          if (!key.includes('document_tipo-')) {
            newFormData[key] = form[key];
          }
        });

        dispatch(setFiles());
        dispatch(emptyFormData(newFormData));
        dispatch(emptyActualModal());
      }

      dispatch(loadError(response.data));
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}
