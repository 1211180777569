import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { setFormData } from '../../../redux/actions/globalActions';
import { setModalFormData } from '../../../redux/actions/modalActions';

function MultiselectField({ dispatch, modalFormData, isModal, item, formData }) {
  const [selectedOptions, setSelectedOptions] = useState(
    isModal ? modalFormData[item.id] : formData[item.id],
  );

  function filterOptions(options) {
    const newOptions = [];

    if (options?.length && selectedOptions instanceof Array) {
      options.forEach((option) => {
        let included = false;
        selectedOptions?.forEach((selectedOption) => {
          if (selectedOption.id === option.id) {
            included = true;
          }
        });
        if (!included) {
          newOptions.push(option);
        }
      });
    }

    return newOptions;
  }

  useEffect(() => {
    setSelectedOptions(() => (isModal ? modalFormData[item.id] : formData[item.id]));
  }, [isModal ? modalFormData[item.id] : formData[item.id]]);

  const handleOnChange = (event, value) => {
    if (isModal) {
      dispatch(setModalFormData({ id: item?.id, value }));
    } else {
      dispatch(setFormData({ id: item?.id, value }));
    }
  };

  return (
    <Autocomplete
      multiple
      defaultValue={selectedOptions || []}
      fullWidth
      key={item?.id}
      onChange={handleOnChange}
      options={filterOptions(item?.multiselectOptions)}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={item?.label}
          InputLabelProps={{
            style: { fontWeight: 'bold' },
          }}
        />
      )}
    />
  );
}

MultiselectField.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    multiselectOptions: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
  }).isRequired,
  formData: PropTypes.shape({}).isRequired,
  modalFormData: PropTypes.shape({}).isRequired,
};

MultiselectField.defaultProps = {};

function mapStateToProps({ globalReducer, modalReducer }) {
  return {
    formData: globalReducer.formData,
    modalFormData: modalReducer.modalFormData,
  };
}

export default connect(mapStateToProps)(MultiselectField);
