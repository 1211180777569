import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    margin: '0px 5px',
  },
  contrast: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    margin: '0px 5px',
  },
}));

export default useStyles;
