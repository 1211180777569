import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../Generic/Table';
import useStyles from './style';
import Form from '../../Generic/Form';

export default function Documents({ detail }) {
  const classes = useStyles();

  const grid = { spacing: 3 };
  const newDetail = { ...detail, form: { ...detail.form, grid } };

  return (
    <div className={classes.mainContainer}>
      <section>
        {Boolean(newDetail?.form?.inputs?.length || newDetail?.form?.buttons?.length)
        && (
        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          <Form newForm={newDetail.form} />
        </div>
        )}
        {Boolean(newDetail?.table?.titles?.length || newDetail?.table?.rows?.length)
        && <Table tableData={newDetail.table} />}
      </section>
    </div>
  );
}

Documents.propTypes = {
  detail: PropTypes.shape({
    form: PropTypes.shape({}),
    table: PropTypes.shape({}),
  }).isRequired,
};

Documents.defaultProps = {};
