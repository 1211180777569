/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as MaterialRow,
  Typography,
  TablePagination,
  TableSortLabel,
  CircularProgress,
} from '@material-ui/core';
import * as MaterialIcons from '@material-ui/icons';
import Global from '../../../Contexts/Global';
import TableRow from '../TableRow';
import BasicPopover from '../BasicPopover';
import { setModalFormData } from '../../../redux/actions/modalActions';
import useStyles from './style';

function Table({ dispatch,
  tableData, selectItems, allowMultipleSelects, orderBy, orderProducts, onOrderProducts,
  pageObject, rowsPerPageObject, pageCount, maxHeight, hasStatusColors, reduxTable, isModal,
  activeSpinner, modalFormData,
}) {
  const classes = useStyles();
  const { i18n } = useContext(Global);
  const [anchorElement, setAnchorElement] = useState({});
  const [openPopover, setOpenPopOver] = useState(false);
  const [rowActions, setRowActions] = useState([]);
  const [reduxRows, setReduxRows] = useState(reduxTable?.table?.rows);
  const [tableRows, setTableRows] = useState(tableData?.rows);
  const [selectedItems, setSelectedItems] = useState([]);
  const selectedRowColor = '#2e1f46';
  const tableTitles = useState(reduxTable?.table?.titles);
  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderColumn, setOrderColumn] = useState(null);
  const [flag, setFlag] = useState(true);
  const [isSpinning, setIsSpinning] = useState(false);
  useEffect(() => {
    setReduxRows(reduxTable?.table?.rows);
    if (!selectedItems.length && selectItems) {
      const newSelectedItems = [];
      reduxTable?.table?.rows?.forEach((reduxRow) => {
        Object.keys(reduxRow.row).forEach((key) => {
          if (reduxRow.row[key] instanceof Object) {
            if (reduxRow.row[key].value.field_checked === true) {
              newSelectedItems.push(reduxRow.row.itemID);
            }
          }
        });
      });
      dispatch(setModalFormData({ id: 'contactos', value: newSelectedItems }));
    }
  }, [reduxTable]);

  const handleClickActions = (event, actions) => {
    setAnchorElement(event.currentTarget);
    setRowActions(actions);
    setOpenPopOver(true);
  };
  const handleClickRow = ({ row }) => {
    let hasCheckbox = false;
    let multiple = allowMultipleSelects;
    Object.keys(row).forEach((key) => {
      if (row[key]?.checkbox) {
        hasCheckbox = true;
      }
    });
    const titles = tableTitles.length === 2 ? tableTitles[0] : tableTitles;
    if (titles.length === 7 || titles.length === 8) {
      if (titles[6].title !== 'Bultos' && titles[5].title !== 'Muelle') {
        multiple = true;
        hasCheckbox = false;
      }
    }
    if (!multiple) {
      if (!hasCheckbox && selectItems) {
        const rows = (isModal ? reduxRows : tableRows).map((actualRow) => {
          const newRow = { ...actualRow };
          if (row.itemID === actualRow.row.itemID) {
            const isSelected = newRow.selected;
            if (isSelected) {
              newRow.selected = false;
              dispatch(setModalFormData({ id: 'table_selection', value: null }));
            } else {
              newRow.selected = true;
              dispatch(setModalFormData({ id: 'table_selection', value: newRow.row }));
            }
          } else {
            newRow.selected = false;
          }
          return newRow;
        });
        if (isModal) {
          setReduxRows(rows);
        } else {
          setTableRows(rows);
        }
      }
    } else if (!hasCheckbox) {
      let newSelectedItems = [];
      if (selectedItems.includes(row?.itemID)) {
        newSelectedItems = selectedItems.filter((selectedItem) => selectedItem !== row.itemID);
      } else {
        newSelectedItems = [...selectedItems, row.itemID];
      }
      setSelectedItems(newSelectedItems);
      const rows = (isModal ? reduxRows : tableRows).map((actualRow) => {
        const newRow = { ...actualRow };
        if (row.itemID === actualRow.row.itemID) {
          const isSelected = newRow.selected;
          if (isSelected) {
            newRow.selected = false;
          } else {
            newRow.selected = true;
          }
        }
        return newRow;
      });

      if (isModal) {
        setReduxRows(rows);
        dispatch(setModalFormData({ id: 'table_selection', value: newSelectedItems }));
      } else {
        setTableRows(rows);
      }
    }
  };

  const handleCloseActions = () => {
    setAnchorElement({});
    setOpenPopOver(false);
  };

  function handleChangeOrder(column) {
    onOrderProducts(column);
  }

  function sortRows(column) {
    setIsSpinning(true);
    const isAsc = orderColumn === column && orderDirection === 'asc';
    const isDesc = orderColumn === column && orderDirection === 'desc';

    const newRows = (isModal ? [...reduxRows] : [...tableRows]);
    let orderedRows = [...newRows];
    if (isAsc) {
      orderedRows = newRows
        .sort((a, b) => {
          let result = 0;
          const firstValue = Number.isNaN(+a.row[column]) ? a.row[column] : +a.row[column];
          const secondValue = Number.isNaN(+b.row[column]) ? b.row[column] : +b.row[column];
          if (firstValue > secondValue) {
            result = 1;
          } if (firstValue < secondValue) {
            result = -1;
          }
          return result;
        });
    } else if (isDesc) {
      orderedRows = (isModal ? [...reduxTable?.table?.rows] : [...tableData?.rows])
        .map((row) => {
          if (row.row) {
            return row;
          }
          return { row, selected: false };
        });
    } else {
      orderedRows = newRows
        .sort((a, b) => {
          let result = 0;
          const firstValue = Number.isNaN(+a.row[column]) ? a.row[column] : +a.row[column];
          const secondValue = Number.isNaN(+b.row[column]) ? b.row[column] : +b.row[column];
          if (firstValue > secondValue) {
            result = 1;
          } if (firstValue < secondValue) {
            result = -1;
          }
          return result;
        });
    }

    if (isDesc) {
      setOrderColumn(false);
    } else {
      setOrderColumn(column);
    }

    if (isModal) {
      setReduxRows(orderedRows);
    } else {
      setTableRows(orderedRows);
    }

    setOrderDirection(isAsc ? 'desc' : 'asc');
    setIsSpinning(false);
  }

  function getIfSelected(row) {
    let backgroundColor = null;

    if (row.selected) {
      backgroundColor = selectedRowColor;
    } else if (row.row instanceof Object) {
      const keys = Object.keys(row.row);
      keys.forEach((key) => {
        if (row.row[key] instanceof Object && row.row[key].checkbox && row.row[key].value.field_checked === true) {
          backgroundColor = selectedRowColor;
        }
      });
    }

    return backgroundColor;
  }

  useEffect(() => {
    if (tableData) {
      if (selectedItems.length === 0 && flag) {
        setFlag(false);
        let oldSelectedItems = [];
        tableData.rows?.forEach((row) => {
          if (row?.selected === true && row?.row?.itemID) {
            oldSelectedItems = [...oldSelectedItems, row.row.itemID];
          }
        });
        setSelectedItems(oldSelectedItems);
        if (oldSelectedItems.length !== 0 && JSON.stringify(modalFormData?.table_selection) !== JSON.stringify(oldSelectedItems)) {
          dispatch(setModalFormData({ id: 'table_selection', value: oldSelectedItems }));
        }
      }
      setTableRows(tableData.rows?.map((row) => ({ row, selected: row?.selected ? row.selected : false })));
    }
  }, [tableData]);

  return (
    <>
      {(tableRows?.length || reduxRows?.length)
        ? (
          <>
            {(isSpinning || activeSpinner)
              ? (
                <>
                  <TableContainer component={Paper} style={{ position: 'relative', maxHeight }}>
                    <MaterialTable className={classes.table} aria-label="simple table">
                      <TableHead style={{ position: 'sticky', top: 0, zIndex: 100 }}>
                        <MaterialRow>
                          {(tableData?.table?.titles || tableData?.titles).map((title, index) => {
                            let cell = null;
                            const newkey = title.title + index;
                            if (title?.title?.length > 0 && title?.title !== 'E.B.L.' && title?.title !== 'E.B.O.') {
                              cell = (
                                <TableCell align="left" className={classes.columnTitle} key={newkey}>
                                  {orderProducts
                                    ? (
                                      <TableSortLabel
                                        active={orderBy === title.title}
                                        direction={orderBy === title.title ? orderProducts : 'asc'}
                                        onClick={() => handleChangeOrder(title.title)}
                                      >
                                        {i18n.t(title.title).toUpperCase()}
                                      </TableSortLabel>
                                    )
                                    : (
                                      <TableSortLabel
                                        active={orderColumn === title.title}
                                        direction={orderColumn === title.title ? orderDirection : 'asc'}
                                        onClick={() => sortRows(title.title)}
                                      >
                                        {i18n.t(title.title).toUpperCase()}
                                      </TableSortLabel>
                                    )}
                                </TableCell>
                              );
                            } else if (title?.title?.length === 0) {
                              cell = (
                                <TableCell align="center" className={classes.columnTitle} key={newkey} style={{ position: 'sticky', right: 0, zIndex: 200 }}>
                                  {i18n.t('')?.toUpperCase()}
                                </TableCell>
                              );
                            } else if (title?.title === 'E.B.O.' || title?.title === 'E.B.L.') {
                              const NewTitle = title.title === 'E.B.O.' ? 'B' : 'BL';
                              cell = (
                                <TableCell align="center" className={classes.columnTitle} key={newkey} style={{ position: 'sticky', right: title.title === 'E.B.L.' ? 64 : 89, zIndex: 200, paddingLeft: '3px', paddingRight: '3px' }}>
                                  {i18n.t(NewTitle)?.toUpperCase()}
                                </TableCell>
                              );
                            }
                            return cell;
                          })}
                        </MaterialRow>
                      </TableHead>
                    </MaterialTable>
                  </TableContainer>
                  <div className={classes.spinnerContainer}>
                    <div className={classes.spinnerBox}>
                      <CircularProgress />
                    </div>
                  </div>
                </>
              )
              : (
                <>
                  <TableContainer component={Paper} style={{ position: 'relative', maxHeight }}>
                    <MaterialTable className={classes.table} aria-label="simple table">
                      <TableHead style={{ position: 'sticky', top: 0, zIndex: 100 }}>
                        <MaterialRow>
                          {(tableData?.table?.titles || tableData?.titles)?.map((title, index) => {
                            let cell = null;
                            const newkey = title.title + index;
                            if (title?.title?.length > 0 && title?.title !== 'E.B.L.' && title?.title !== 'E.B.O.') {
                              cell = (
                                <TableCell align="left" className={classes.columnTitle} key={newkey}>
                                  {orderProducts
                                    ? (
                                      <TableSortLabel
                                        active={orderBy === title.title}
                                        direction={orderBy === title.title ? orderProducts : 'asc'}
                                        onClick={() => handleChangeOrder(title.title)}
                                      >
                                        {i18n.t(title.title).toUpperCase()}
                                      </TableSortLabel>
                                    )
                                    : (
                                      <TableSortLabel
                                        active={orderColumn === title.title}
                                        direction={orderColumn === title.title ? orderDirection : 'asc'}
                                        onClick={() => sortRows(title.title)}
                                      >
                                        {i18n.t(title.title).toUpperCase()}
                                      </TableSortLabel>
                                    )}
                                </TableCell>
                              );
                            } else if (title?.title?.length === 0) {
                              cell = (
                                <TableCell align="center" className={classes.columnTitle} key={newkey} style={{ position: 'sticky', right: 0, zIndex: 200 }}>
                                  {i18n.t('')?.toUpperCase()}
                                </TableCell>
                              );
                            } else if (title?.title === 'E.B.O.' || title?.title === 'E.B.L.') {
                              const NewTitle = title.title === 'E.B.O.' ? 'B' : 'BL';
                              cell = (
                                <TableCell align="center" className={classes.columnTitle} key={newkey} style={{ position: 'sticky', right: title.title === 'E.B.L.' ? 64 : 89, zIndex: 200, paddingLeft: '3px', paddingRight: '3px' }}>
                                  {i18n.t(NewTitle)?.toUpperCase()}
                                </TableCell>
                              );
                            }
                            return cell;
                          })}
                        </MaterialRow>
                      </TableHead>
                      <TableBody id="table" style={{ position: 'relative' }}>
                        {(isModal ? reduxRows : tableRows)?.map((row) => (
                          <TableRow
                            row={row.row}
                            key={row?.row?.itemID}
                            tableFunctions={{
                              handleClickActions,
                              rowClickFunction: handleClickRow,
                            }}
                            backgroundColor={getIfSelected(row)}
                            selectItems={selectItems}
                            selectedItems={selectItems && { items: selectedItems, setItems: setSelectedItems }}
                            hasStatusColors={hasStatusColors}
                            isModal={isModal}
                            closePopoverFunction={handleCloseActions}
                          />
                        ))}
                        <BasicPopover
                          open={openPopover}
                          anchorElement={anchorElement}
                          onClose={handleCloseActions}
                        >
                          {(rowActions?.length) && rowActions?.map((action) => {
                            let Icon = null;
                            if (action.icon) {
                              Icon = MaterialIcons[action.icon];
                            }
                            return (
                              <>
                                {Icon
                                  ? (
                                    <div className={classes.actionRow}>
                                      <Icon className={classes.icon} />
                                      <Typography
                                        className={classes.typography}
                                        onClick={action.function ? () => { action.function(); handleCloseActions(); } : null}
                                        key={action.title}
                                      >
                                        {i18n.t(action.title)}
                                      </Typography>
                                    </div>
                                  )
                                  : (
                                    <div className={classes.actionRow}>
                                      <Typography
                                        className={classes.typography}
                                        onClick={action.function ? () => action.function() : null}
                                        key={action.title}
                                      >
                                        {i18n.t(action.title)}
                                      </Typography>
                                    </div>
                                  )}
                              </>
                            );
                          })}
                        </BasicPopover>
                      </TableBody>
                    </MaterialTable>
                  </TableContainer>
                  <>
                    {(Boolean(tableData?.meta?.pageCount > 1) || Boolean(reduxTable?.meta?.pageCount > 1))
                  && (
                  <TablePagination
                    component="div"
                    count={pageCount}
                    page={pageObject?.page - 1}
                    onChangePage={pageObject.handleChangePage}
                    rowsPerPage={rowsPerPageObject.rowsPerPage}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    onChangeRowsPerPage={rowsPerPageObject.handleChangeRowsPerPage}
                    labelRowsPerPage={i18n.t('ROWS_PER_PAGE')}
                  />
                  )}
                  </>
                </>
              )}
          </>
        )
        : (
          <div>
            <TableContainer component={Paper} style={{ position: 'relative', maxHeight }}>
              <MaterialTable className={classes.table} aria-label="simple table">
                <TableHead style={{ position: 'sticky', top: 0, zIndex: 100 }}>
                  <MaterialRow className={classes.headerRow}>
                    {tableData?.titles?.length && tableData?.titles?.map((title) => {
                      let cell = null;
                      if (title?.title?.length > 0 && title?.title !== 'E.B.L.' && title?.title !== 'E.B.O.') {
                        cell = (
                          <TableCell align="left" className={classes.columnTitle} key={title.title}>
                            <TableSortLabel
                              active={orderBy === title.title}
                              direction={orderBy === title.title ? orderProducts : 'asc'}
                              onClick={() => handleChangeOrder(title.title)}
                            >
                              {i18n.t(title.title).toUpperCase()}
                            </TableSortLabel>
                          </TableCell>
                        );
                      } else if (title?.title?.length === 0) {
                        cell = (
                          <TableCell align="center" className={classes.columnTitle} key={title.title} style={{ position: 'sticky', right: 0, zIndex: 200 }}>
                            {i18n.t('ACTIONS')?.toUpperCase()}
                          </TableCell>
                        );
                      } else if (title?.title === 'E.B.O.' || title?.title === 'E.B.L.') {
                        cell = (
                          <TableCell align="center" className={classes.columnTitle} key={title.title} style={{ position: 'sticky', right: title.title === 'E.B.L.' ? 107 : 154, zIndex: 200, paddingLeft: '1px', paddingRight: '1px' }}>
                            {i18n.t(title?.title)?.toUpperCase()}
                          </TableCell>
                        );
                      }
                      return cell;
                    })}
                  </MaterialRow>
                </TableHead>
              </MaterialTable>
            </TableContainer>
            <Paper style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px 0px' }}>
              {i18n.t('NO_RESULTS')}
            </Paper>
          </div>
        )}
    </>
  );
}

Table.propTypes = {
  tableData: PropTypes.shape({
    titles: PropTypes.arrayOf(PropTypes.shape({})),
    rows: PropTypes.arrayOf(PropTypes.shape({})),
    rowActions: PropTypes.arrayOf(PropTypes.string),
    meta: PropTypes.shape({
      pageCount: PropTypes.number,
    }),
    table: PropTypes.shape({
      titles: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  modalFormData: PropTypes.shape({
    table_selection: PropTypes.shape([]),
  }).isRequired,
  reduxTable: PropTypes.shape({
    table: PropTypes.shape({
      rows: PropTypes.arrayOf(PropTypes.shape({})),
      titles: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    links: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({
      pageCount: PropTypes.number,
    }),
  }),
  selectItems: PropTypes.bool,
  allowMultipleSelects: PropTypes.bool,
  onOrderProducts: PropTypes.func,
  orderBy: PropTypes.string,
  orderProducts: PropTypes.string,
  pageObject: PropTypes.shape({
    page: PropTypes.number,
    handleChangePage: PropTypes.func,
  }),
  rowsPerPageObject: PropTypes.shape({
    rowsPerPage: PropTypes.number,
    handleChangeRowsPerPage: PropTypes.func,
  }),
  pageCount: PropTypes.number,
  maxHeight: PropTypes.string,
  hasStatusColors: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  isModal: PropTypes.bool,
  activeSpinner: PropTypes.bool,
};

Table.defaultProps = {
  reduxTable: {},
  selectItems: false,
  allowMultipleSelects: false,
  onOrderProducts: () => {},
  orderBy: '',
  orderProducts: '',
  pageObject: {
    page: 1,
    handleChangePage: () => {},
  },
  rowsPerPageObject: {
    rowsPerPage: 1,
    setRowsPerPage: () => {},
  },
  pageCount: 100,
  maxHeight: '64vh',
  hasStatusColors: false,
  isModal: false,
  activeSpinner: false,
};

function mapStateToProps({ globalReducer, tableReducer, modalReducer }) {
  return {
    modalFormData: modalReducer.modalFormData,
    inquiresList: globalReducer.inquiresList,
    inquiresListError: globalReducer.loadError,
    cleanList: globalReducer.cleanList,
    reduxTable: tableReducer.table,
  };
}

export default connect(mapStateToProps)(Table);
