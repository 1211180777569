import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dashboardWrapper: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    display: 'flex',
  },
}));

export default useStyles;
