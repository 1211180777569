import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Global from '../../../Contexts/Global';
import useStyles from './style';
import Table from '../../Generic/Table';
import FormModal from '../FormModal';
import Form from '../../Generic/Form';

export default function Incidents({ detail }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');

  const grid = { spacing: 3 };
  const newDetail = { ...detail, form: { ...detail.form, grid } };

  const newForm = {
    inputs: [
      { space: true, gridProps: { lg: 12 } },
      { label: i18n.t('DATE'), placeholder: '123', textFieldProps: { fullWidth: true, required: true, InputLabelProps: { shrink: true }, type: 'date' }, gridProps: { lg: 12 } },
      { label: i18n.t('TYPE'), placeholder: '123', textFieldProps: { fullWidth: true, select: true, required: true }, selectOptions: [{ value: 'TIPO1', label: 'TIPO1' }, { value: 'TIPO2', label: 'TIPO2' }, { value: 'TIPO3', label: 'TIPO3' }], gridProps: { lg: 12 } },
      { label: i18n.t('FROM_WHO'), placeholder: '123', textFieldProps: { fullWidth: true, select: true, required: true }, selectOptions: [{ value: 'BCN', label: 'Barcelona' }, { value: 'MDR', label: 'Madrid' }, { value: 'ZRG', label: 'Zaragoza' }], gridProps: { lg: 12 } },
      { label: i18n.t('ORIGIN'), placeholder: '123', textFieldProps: { fullWidth: true, select: true, required: true }, selectOptions: [{ value: 'BCN', label: 'Barcelona' }, { value: 'MDR', label: 'Madrid' }, { value: 'ZRG', label: 'Zaragoza' }], gridProps: { lg: 12 } },
      { label: i18n.t('COMMENT'), placeholder: i18n.t('COMMENT'), textFieldProps: { fullWidth: true, multiline: true, rows: 6 }, gridProps: { lg: 12 } },
      { space: true, gridProps: { lg: 12 } },
      { space: true, gridProps: { lg: 6 } },
    ],
    buttons: [
      { label: 'CANCEL', gridProps: { lg: 3 }, buttonProps: { variant: 'outlined' } },
      { label: 'SAVE', gridProps: { lg: 2 }, buttonProps: { variant: 'contained' } },
    ],
    grid: { spacing: 3 },
  };

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';

    if (isDesc) {
      setOrderBy(false);
    } else {
      setOrderBy(column);
    }

    setOrderProducts(isAsc ? 'desc' : 'asc');
  }

  return (
    <div className={classes.mainContainer}>
      <section>
        {Boolean(newDetail?.form?.inputs?.length || newDetail?.form?.buttons?.length)
        && (
        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          <Form newForm={newDetail.form} />
        </div>
        )}
        {Boolean(newDetail?.table?.titles?.length || newDetail?.table?.rows?.length)
        && (
          <Table tableData={newDetail.table} />
        )}
        <FormModal
          open={openModal}
          form={newForm}
          title={i18n.t('NEW_INCIDENCE_CAP')}
          setCloseModal={() => setOpenModal(false)}
        />
      </section>
    </div>
  );
}

Incidents.propTypes = {
  detail: PropTypes.shape({
    form: PropTypes.shape({}),
  }).isRequired,
};

Incidents.defaultProps = {};
