import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: '1px 30px',
    borderRadius: '20px',
  },
  button: {
    width: 'auto',
    position: 'absolute',
    top: -10,
    right: -1,
    margin: theme.spacing(1),
    padding: '5px',
    zIndex: 100,
  },
  gridItems: {
    display: 'flex',
    position: 'relative',
  },
  formContainer: {
    position: 'relative',
    width: '100%',
  },
  moreFilters: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  outerLabel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
