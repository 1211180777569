import actionKeyTypes from './actionKeyTypes';

export function setKey(key) {
  return {
    type: actionKeyTypes.SET_KEY,
    data: key,
  };
}

export function emptyKeys() {
  return {
    type: actionKeyTypes.EMPTY_KEYS,
  };
}
