export function createData(
  IEX, pc, date, reference, expedient, booking, origin,
  destiny, client, supplier, naviera, ware, etd, eta, rtd, rta, user, modal, actions, ebo, ebl,
) {
  return {
    IEX,
    pc,
    date,
    reference,
    expedient,
    booking,
    origin,
    destiny,
    client,
    supplier,
    naviera,
    ware,
    etd,
    eta,
    rtd,
    rta,
    user,
    modal,
    actions,
    ebo,
    ebl,
  };
}

export const initialReceiversFilterValues = {
  client: '',
  search: '',
};

export function createReceiversFilterData({ i18n, formDataValues, setFormDataValues,
  onClear, getClientsList }) {
  const formData = {
    grid: { style: { width: '85%', margin: 'inherit' }, spacing: 1 },
    collapseGrid: { style: { width: '100%', margin: 'inherit' }, spacing: 1 },
    collapseProps: { style: { width: '100%' } },
    inputs: [
      {
        label: i18n.t('CLIENT'),
        placeholder: i18n.t('CLIENT'),
        id: 'client',
        name: 'client',
        textFieldProps: {
          fullWidth: true,
        },
        autocompleteProps: {
          // options: getClientsList(),
          url: 'clients/search/list/',
          queryValue: 'client',
          onChange: (event, value) => (setFormDataValues({ ...formDataValues, client: value?.id })),
        },
        gridProps: { xs: 12, sm: 6, md: 6, lg: 4 },
      },
      {
        textFieldProps: {
          label: i18n.t('SEARCH'),
          placeholder: i18n.t('SEARCH'),
          id: 'search',
          name: 'search',
          fullWidth: true,
          type: 'text',
          value: formDataValues.search,
          onChange: (event, value) => (setFormDataValues({ ...formDataValues,
            search: event.target.value })),
        },
        gridProps: { xs: 12, sm: 6, md: 6, lg: 4 },
      },
    ],
    buttons: [
      { space: true, gridProps: { xs: 12, sm: 6, md: 6, lg: 4 } },
      { label: i18n.t('SEARCH'), gridProps: { lg: 1, style: { display: 'flex', alignItems: 'flex-end' } }, buttonProps: { type: 'submit', variant: 'contained' } },
      { label: i18n.t('CLEAR'), gridProps: { lg: 1, style: { display: 'flex', alignItems: 'flex-end' } }, buttonProps: { variant: 'outlined', onClick: onClear } },
    ],
  };
  return formData;
}
