/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Divider, Paper, Typography } from '@material-ui/core';
import Global from '../../Contexts/Global';
import Form from '../../Components/Generic/Form';
import Table from '../../Components/Generic/Table';
import FormModal from '../../Components/Specific/FormModal';
import { getInquiresList, sendAction, resetInquiresList, setCleanList, emptyInquiries, getExcel } from '../../redux/actions/globalActions';
import BasicModal from '../../Components/Generic/BasicModal';
import { getTable } from '../../redux/actions/tableActions';
import { buildModal, getActualModal } from '../../redux/actions/modalActions';
import { createFormData, createData, initialFormDataValues, formDataValueMap } from './config';
import useStyles from './style';

function List({ dispatch, inquiresList, cleanList, reduxTable, lastInquiresSearch }) {
  const history = useHistory();
  const location = useLocation();
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const [formDataValues, setFormDataValues] = useState(initialFormDataValues);
  const [openModal, setOpenModal] = useState(false);
  const [openAnswersModal, setOpenAnswersModal] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [actionsModal, setActionsModal] = useState(false);
  const [isFormOpened, setIsFormOpened] = useState(false);
  const [orderByModal, setOrderByModal] = useState('');
  const [orderProductsModal, setOrderProductsModal] = useState('asc');
  const defaultStateObject = { color: 'rgb(255,255,255)', name: '' };
  const [previousLink, setPreviousLink] = useState(null);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [resetParam, setResetParam] = useState('');
  const [pageUrl, setUrl] = useState(location.state?.link || null);
  let inputsFilter = [];

  useEffect(() => {
    const url = location.state?.link || null;
    const state = { ...history.location.state };
    delete state.link;
    history.replace({ ...history.location, state });
    if (url) {
      setPreviousLink(url);
    }
    if (!inquiresList || !inquiresList?.items) {
      if (cleanList) {
        dispatch(resetInquiresList({ setSpinner: setActiveSpinner }));
        dispatch(setCleanList(false));
      } else {
        dispatch(getInquiresList({ page, rowsPerPage, inputs: [], url, order: orderProducts, orderBy, resetParam, formDataValues, formDataValueMap }));
        dispatch(setCleanList());
      }
    }

    return () => {
      dispatch(emptyInquiries());
    };
  }, []);

  function getActionFromLink({
    name, link, message, method, param, modal_confirm, modal_inputs, recharge,
  }) {
    let linkFunction = null;
    const answersURL = '/inquiries-butransa/get-answers';
    if (link?.includes(answersURL)) {
      linkFunction = () => {
        dispatch(getTable({ url: answersURL, param: `booking_id=${param}` }));
        setOpenAnswersModal(true);
      };
    } else if (modal_confirm) {
      const inputs = [
        {
          field_label: 'cancelar',
          field_name: 'cancel',
          field_type: 'button',
          field_width: { lg: 4 },
        },
        { field_type: 'space', field_with: { lg: 12 } },
        {
          field_label: 'aceptar',
          field_type: 'button',
          field_width: { lg: 4 },
          link,
          method,
        },
      ];
      linkFunction = () => {
        buildModal({ name, inputs, modal_message: message, recharge }, dispatch);
      };
    } else if (modal_inputs) {
      linkFunction = () => {
        buildModal({ name, inputs: modal_inputs, message, recharge }, dispatch);
      };
    } else {
      linkFunction = () => {
        getActualModal({ modal_name: name, link, method, message, recharge }, dispatch);
      };
    }

    return linkFunction;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const addOrderBooking = {
    inputs: [
      { label: i18n.t('ACTUAL_STATE_CAP'), placeholder: '', textFieldProps: { fullWidth: true, required: true }, gridProps: { lg: 4 } },
      { label: i18n.t('ACTUAL_DATE_CAP'), placeholder: '', textFieldProps: { fullWidth: true, required: true, InputLabelProps: { shrink: true }, type: 'date' }, gridProps: { lg: 4 } },
      { label: i18n.t('CLIENT_DATE_CAP'), placeholder: '', textFieldProps: { fullWidth: true, required: true, InputLabelProps: { shrink: true }, type: 'date' }, gridProps: { lg: 4 } },
      { label: i18n.t('CONTAINER'), placeholder: '', textFieldProps: { fullWidth: true, required: true }, gridProps: { lg: 4 } },
      { label: i18n.t('ORIGIN'), placeholder: '', textFieldProps: { fullWidth: true, required: true }, gridProps: { lg: 4 } },
      { label: i18n.t('DESTINY'), placeholder: '', textFieldProps: { fullWidth: true, required: true }, gridProps: { lg: 4 } },
      { label: i18n.t('NEW_STATE_CAP'), placeholder: '', textFieldProps: { fullWidth: true, select: true, required: true }, selectOptions: [{ value: 'STST', label: 'STATE' }, { value: 'ST', label: 'STATE' }, { value: 'ST', label: 'STATE' }], gridProps: { lg: 4 } },
      { label: i18n.t('LOCATION'), placeholder: '', textFieldProps: { fullWidth: true, required: true }, gridProps: { lg: 4 } },
      { label: i18n.t('HOUR'), placeholder: '', textFieldProps: { fullWidth: true, required: true }, gridProps: { lg: 4 } },
    ],
    buttons: [
      { label: i18n.t('SAVE'), gridProps: { lg: 2 }, buttonProps: { variant: 'contained' } },
      { space: true, gridProps: { lg: 3 } },
      { label: i18n.t('CANCEL'), gridProps: { lg: 2 }, buttonProps: { variant: 'contained' } },
      { space: true, gridProps: { lg: 3 } },
      { label: i18n.t('END'), gridProps: { lg: 2 }, buttonProps: { variant: 'contained' } },
    ],
    grid: { spacing: 3 },
  };

  async function handleRequestSort(column) {
    const url = pageUrl;
    setActiveSpinner(true);
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';
    inputsFilter = Object.values(document.querySelector('form'));
    setOrderBy(column);
    setOrderProducts(isAsc ? 'desc' : 'asc');
    dispatch(getInquiresList({ page, rowsPerPage, inputs: inputsFilter, url, order: isAsc ? 'desc' : 'asc', orderBy: column, resetParam, formDataValues, formDataValueMap }));
    setTimeout(() => setActiveSpinner(false), 1000);
  }

  async function handleRequestSortModal(column) {
    const isAsc = orderByModal === column && orderProductsModal === 'asc';
    const isDesc = orderByModal === column && orderProductsModal === 'desc';

    if (isDesc) {
      setOrderByModal(false);
    } else {
      setOrderByModal(column);
    }

    setOrderProductsModal(isAsc ? 'desc' : 'asc');
  }

  const handleChangePage = (event, newPage) => {
    const url = pageUrl;
    setPage(newPage + 1);
    inputsFilter = Object.values(document.querySelector('form'));
    if (previousLink) {
      dispatch(getInquiresList({ page: newPage + 1, rowsPerPage, inputs: inputsFilter, url: previousLink, order: orderProducts, orderBy, resetParam, formDataValues, formDataValueMap }));
    } else {
      dispatch(getInquiresList({ page: newPage + 1, rowsPerPage, inputs: inputsFilter, url, order: orderProducts, orderBy, resetParam, formDataValues, formDataValueMap }));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const url = pageUrl;
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    inputsFilter = Object.values(document.querySelector('form'));
    if (previousLink) {
      dispatch(getInquiresList({ page: 1, rowsPerPage: parseInt(event.target.value, 10), inputs: inputsFilter, url: previousLink, order: orderProducts, orderBy, resetParam, formDataValues, formDataValueMap }));
    } else {
      dispatch(getInquiresList({ page: 1, rowsPerPage: parseInt(event.target.value, 10), inputs: inputsFilter, url, order: orderProducts, orderBy, resetParam, formDataValues, formDataValueMap }));
    }
  };

  const tableData = inquiresList?.items ? {
    rows: inquiresList?.items?.map((inquire) => createData(
      inquire.inquiry_order_type || ' ',
      inquire.inquiry_client_order_number || ' ',
      inquire.inquiry_date || ' ',
      {
        title: inquire.inquiry_reference || ' ',
        reference: inquire.inquiry_reference || ' ',
        link: true || ' ',
        label: 'order',
        id: inquire.inquiry_client_order_id,
      } || ' ',
      inquire.inquiry_expedient || ' ',
      {
        title: inquire.inquiry_booking || ' ',
        reference: inquire.inquiry_booking || ' ',
        link: true || ' ',
        label: 'booking',
        id: inquire.inquiry_booking_id,
      } || ' ',
      inquire.inquiry_origin || ' ',
      inquire.inquiry_destination || ' ',
      inquire.inquiry_client || ' ',
      inquire.inquiry_supplier_name || ' ',
      inquire.inquiry_shipping_company?.name || ' ',
      inquire.inquiry_merchancy || ' ',
      inquire.inquiry_state?.name || ' ',
      inquire.inquiry_etd || ' ',
      inquire.inquiry_rtd || ' ',
      inquire.inquiry_eta || ' ',
      inquire.inquiry_rta || ' ',
      inquire.inquiry_user || ' ',
      { ...inquire.inquiry_booking_state, isStatus: 'bo' } || defaultStateObject,
      { ...inquire.inquiry_bl_state, isStatus: 'bl' } || defaultStateObject,
      {
        actions: inquire.inquiry_actions.map((action) => (
          {
            title: capitalizeFirstLetter(action?.name),
            icon: action?.icon,
            function: getActionFromLink({ ...action, param: inquire.inquiry_booking_id }),
          })),
      },
    )),
    titles: [
      { align: 'left', title: 'I/E/X' },
      { align: 'left', title: 'PC' },
      { align: 'left', title: 'DATE' },
      { align: 'left', title: 'REFERENCY' },
      { align: 'left', title: 'EXPEDIENT' },
      { align: 'left', title: 'BOOKING' },
      { align: 'left', title: 'ORIGIN' },
      { align: 'left', title: 'DESTINY' },
      { align: 'left', title: 'CLIENT' },
      { align: 'left', title: 'SUPPLIER' },
      { align: 'left', title: 'NAVIERA' },
      { align: 'left', title: 'WARE' },
      { align: 'left', title: 'STATE' },
      { align: 'left', title: 'ETD' },
      { align: 'left', title: 'RTD' },
      { align: 'left', title: 'ETA' },
      { align: 'left', title: 'RTA' },
      { align: 'left', title: 'USER' },
      { align: 'left', title: 'E.B.O.' },
      { align: 'left', title: 'E.B.L.' },
      { align: 'left', title: '' },
    ],
    meta: inquiresList.meta,
  } : null;

  function handleOnSubmit(e) {
    e.preventDefault();
    setPreviousLink(null);
    setUrl(null);
    inputsFilter = Object.values(e.target[0].form);
    dispatch(getInquiresList({ page, rowsPerPage, inputs: inputsFilter, pageUrl, order: orderProducts, orderBy, setSpinner: setActiveSpinner, resetParam: '', formDataValues, formDataValueMap }));
    setResetParam('');
  }

  const onClear = (e) => {
    setPreviousLink(null);
    dispatch(resetInquiresList({ setSpinner: setActiveSpinner }));
    setFormDataValues(initialFormDataValues);
    setOrderBy(false);
    setResetParam('&reset=1');
    setUrl(null);
  };

  function handleDispatchAction({ link, method }) {
    dispatch(sendAction({ link, method }));
  }

  function sendExcel() {
    getExcel(lastInquiresSearch);
  }

  return (
    <div>
      <Paper>
        <div style={{ padding: 10, paddingBottom: 25 }}>
          <div className={classes.bookingsFound}>
            <Typography variant="h4">{`Encontradas ${inquiresList?.meta?.totalCount || 0} consultas`}</Typography>
            <Button variant="outlined" onClick={() => sendExcel()}>{i18n.t('EXCEL_EXPORT')}</Button>
          </div>
          <Divider />
          <Form newForm={createFormData({ i18n, formDataValues, onClear, setFormDataValues })} onSubmit={handleOnSubmit} formOpenedObject={{ setter: setIsFormOpened, value: isFormOpened }} />
        </div>
      </Paper>

      {!tableData && (
        <div className={classes.spinnerContainer}>
          <div className={classes.spinnerBox}>
            <CircularProgress />
          </div>
        </div>
      )}
      {tableData && (
        <>
          <div id="inquiriesList">
            <Table
              className={classes.customTableStyles}
              tableData={tableData}
              hasStatusColors
              maxHeight={isFormOpened ? '46vh' : '63vh'}
              orderBy={orderBy}
              orderProducts={orderProducts}
              onOrderProducts={handleRequestSort}
              pageObject={{ page, handleChangePage }}
              rowsPerPageObject={{ rowsPerPage, handleChangeRowsPerPage }}
              pageCount={inquiresList?.meta?.totalCount || 0}
              activeSpinner={activeSpinner}
            />
          </div>
          <BasicModal open={actionsModal?.open} isMessage title={capitalizeFirstLetter(actionsModal?.name || '')} message={capitalizeFirstLetter(actionsModal?.message || '')} closeFunction={() => setActionsModal({ ...actionsModal, open: false })} acceptFunction={() => handleDispatchAction({ link: actionsModal.link, method: actionsModal.method })} />
          <BasicModal open={openAnswersModal} closeFunction={() => setOpenAnswersModal(false)} title={i18n.t('ANSWERS_LIST').toUpperCase()}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '20px', fontSize: '10px' }}>
              <div style={{ boxSizing: 'border-box', border: '1px solid black', padding: '20px' }}>
                <Typography style={{ marginBottom: '10px', fontWeight: 600, fontSize: '15px' }}>{i18n.t('FUNCTION')}</Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 5px' }}>
                    <Typography style={{ fontSize: '12px' }}>{`27 - ${i18n.t('NOT_ACCEPTED')}`}</Typography>
                    <Typography style={{ fontSize: '12px' }}>{`44 - ${i18n.t('ACCEPTED')}`}</Typography>
                    <Typography style={{ fontSize: '12px' }}>{`11 - ${i18n.t('RECEPTOR_RECEIVED_DOCUMENT')}`}</Typography>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 5px' }}>
                    <Typography style={{ fontSize: '12px' }}>{`55 - ${i18n.t('DOCUMENT_SENT')}`}</Typography>
                    <Typography style={{ fontSize: '12px' }}>{`14 - ${i18n.t('WARNINGS')}`}</Typography>
                    <Typography style={{ fontSize: '12px' }}>{`41 - ${i18n.t('CLOSED_NO_MORE_SENDS')}`}</Typography>
                  </div>
                </div>
              </div>
            </div>
            <Table tableData={reduxTable} orderBy={orderByModal} orderProducts={orderProductsModal} onOrderProducts={handleRequestSortModal} isModal />
          </BasicModal>
          <FormModal
            open={openModal}
            form={addOrderBooking}
            title={i18n.t('NEW_TRANSPORT_ORDER_CAP')}
            setCloseModal={() => setOpenModal(false)}
          />
        </>
      )}
    </div>
  );
}

List.propTypes = {
  inquiresList: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({
      totalCount: PropTypes.number,
    }),
  }),
  dispatch: PropTypes.func.isRequired,
  cleanList: PropTypes.bool,
  reduxTable: PropTypes.shape({}).isRequired,
  lastInquiresSearch: PropTypes.string.isRequired,
};

List.defaultProps = {
  inquiresList: {},
  cleanList: false,
};

function mapStateToProps({ globalReducer, tableReducer }) {
  return {
    inquiresList: globalReducer.inquiresList,
    inquiresListError: globalReducer.loadError,
    cleanList: globalReducer.cleanList,
    lastInquiresSearch: globalReducer.lastInquiresSearch,
    reduxTable: tableReducer.table,
  };
}

export default connect(mapStateToProps)(List);
