import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import BasicModal from '../../Generic/BasicModal';
import Form from '../../Generic/Form';
import Global from '../../../Contexts/Global';
import useStyles from './style';
import BasicButton from '../../Generic/BasicButton';
import Table from '../../Generic/Table';

export default function BookingInformation({ detail }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const [openShipperModal, setOpenShipperModal] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');

  const grid = { spacing: 3 };
  const newDetail = { ...detail, form: { ...detail.form, grid } };
  function closeShipperModal() {
    setOpenShipperModal(false);
  }

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';

    if (isDesc) {
      setOrderBy(false);
    } else {
      setOrderBy(column);
    }

    setOrderProducts(isAsc ? 'desc' : 'asc');
  }

  return (
    <div className={classes.mainContainer}>
      <BasicModal open={openShipperModal} closeFunction={closeShipperModal}>
        <div style={{ textAlign: 'center', padding: '0px 30px' }}>
          <Typography>{i18n.t('ADD_SHIPPER')}</Typography>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '10px' }}>
            <TextField id="standard-basic" label={i18n.t('SHIPPER')} />
            <BasicButton style={{ width: 'fit-content', boxShadow: 'none' }}>
              <AddCircleOutlineIcon color="primary" />
            </BasicButton>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '30px' }}>
            <BasicButton title="Aceptar" variant="contained" onClick={closeShipperModal} />
          </div>
        </div>
      </BasicModal>
      <section>
        {Boolean(newDetail?.form?.inputs?.length || newDetail?.form?.buttons?.length)
        && (
        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          <Form newForm={newDetail.form} />
        </div>
        )}
        {Boolean(newDetail?.table?.titles?.length || newDetail?.table?.rows?.length)
        && (
        <Table
          tableData={newDetail.table}
          orderBy={orderBy}
          orderProducts={orderProducts}
          onOrderProducts={handleRequestSort}
        />
        )}
      </section>
    </div>
  );
}

BookingInformation.propTypes = {
  detail: PropTypes.shape({
    form: PropTypes.shape({}),
  }).isRequired,
};

BookingInformation.defaultProps = {};
