export function createFormData({ i18n, formDataValues, setFormDataValues, onClear }) {
  const formData = {
    grid: { style: { width: '85%', margin: 'inherit' }, spacing: 1 },
    collapseGrid: { style: { width: '100%', margin: 'inherit' }, spacing: 1 },
    collapseProps: { style: { width: '100%' } },
    inputs: [
      {
        label: i18n.t('ORDER'),
        id: 'inquiry_client_order_number',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'clients-orders/search/list/',
          queryValue: 'inquiry_client_order_number',
          value: formDataValues.order,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, order: value }),
        },
      },
      {
        label: i18n.t('CLIENT'),
        id: 'inquiry_client',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'clients/search/list/',
          queryValue: 'inquiry_client',
          value: formDataValues.client,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, client: value }),
        },
      },
      {
        label: i18n.t('ORIGIN'),
        id: 'inquiry_origin',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'origins/search/list/',
          queryValue: 'inquiry_origin',
          value: formDataValues.origin,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, origin: value }),
        },
      },
      {
        label: i18n.t('DESTINY'),
        id: 'inquiry_destination',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'destinations/search/list/',
          queryValue: 'inquiry_destination',
          value: formDataValues.destiny,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, destiny: value }),
        },
      },
      {
        label: i18n.t('BOOKING'),
        id: 'inquiry_booking',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'bookings/search/list/',
          queryValue: 'inquiry_booking',
          value: formDataValues.booking,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, booking: value }),
        },
      },
      {
        label: i18n.t('STATUS'),
        id: 'inquiry_state_id',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'states/search/list/',
          queryValue: 'inquiry_state_id',
          all: true,
          value: formDataValues.status,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, status: value }),
        },
      },
      {
        label: i18n.t('USER'),
        id: 'inquiry_user',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'users/search/list/',
          queryValue: 'inquiry_user',
          all: true,
          value: formDataValues.user,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, user: value }),
        },
      },
      {
        label: i18n.t('CLIENT_REF'),
        id: 'inquiry_reference',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'clients-orders/search-reference/',
          queryValue: 'inquiry_reference',
          value: formDataValues.clientRef,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, clientRef: value }),
        },
      },
      {
        label: i18n.t('SUPPLIER_FILTER'),
        id: 'inquiry_supplier',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'suppliers/search/list/',
          queryValue: 'inquiry_supplier',
          value: formDataValues.supplier,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, supplier: value }),
        },
      },
      {
        label: i18n.t('CONTAINER'),
        id: 'inquiry_container',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'containers/search/list/',
          queryValue: 'inquiry_container',
          value: formDataValues.container,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, container: value }),
        },
      },
      {
        label: i18n.t('EXPEDIENT'),
        id: 'inquiry_expedient',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'bookings/search-records/',
          queryValue: 'inquiry_expedient',
          value: formDataValues.expedient,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, expedient: value }),
        },
      },
      {
        label: i18n.t('NAVIERA'),
        id: 'inquiry_shipping_company',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'shipping-companies/search/list/',
          queryValue: 'inquiry_shipping_company',
          value: formDataValues.naviera,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, naviera: value }),
        },
      },
    ],
    buttons: [
      { space: true, gridProps: { lg: 10 } },
      { label: i18n.t('SEARCH'), gridProps: { lg: 1, style: { display: 'flex', alignItems: 'flex-end' } }, buttonProps: { type: 'submit', variant: 'contained' } },
      { label: i18n.t('CLEAR'), gridProps: { lg: 1, style: { display: 'flex', alignItems: 'flex-end' } }, buttonProps: { variant: 'outlined', onClick: onClear } },
    ],
    collapsed: [
      {
        label: i18n.t('SHIP'),
        id: 'inquiry_ship',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'ships/search/list/',
          queryValue: 'inquiry_ship',
          value: formDataValues.ship,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, ship: value }),
        },
      },
      {
        label: i18n.t('BL'),
        id: 'inquiry_bl',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'bills-landings/search/list/',
          queryValue: 'inquiry_bl',
          value: formDataValues.bl,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, bl: value }),
        },
      },
      {
        label: i18n.t('ENDED'),
        id: 'inquiry_ended',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'ended/search/list/',
          queryValue: 'inquiry_ended',
          all: true,
          value: formDataValues.ended,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, ended: value }),
        },
      },
      {
        label: i18n.t('DUA'),
        id: 'inquiry_dua',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'duas/search/list/',
          queryValue: 'inquiry_dua',
          value: formDataValues.dua,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, dua: value }),
        },
      },
      {
        label: i18n.t('ADDRESSEE'),
        id: 'inquiry_receiver',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'receivers/search/list/',
          queryValue: 'inquiry_receiver',
          value: formDataValues.addressee,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, addressee: value }),
        },
      },
      {
        label: i18n.t('SHIPPER'),
        id: 'inquiry_shipper',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'shippers/search/list/',
          queryValue: 'inquiry_shipper',
          value: formDataValues.shipper,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, shipper: value }),
        },
      },
      {
        label: i18n.t('I/E/X'),
        id: 'inquiry_type',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'iex/search/list/',
          queryValue: 'inquiry_type',
          all: true,
          value: formDataValues.iex,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, iex: value }),
        },
      },
      {
        label: i18n.t('CARRIER'),
        id: 'inquiry_carrier',
        placeholder: '',
        textFieldProps: { fullWidth: true },
        gridProps: { lg: 2 },
        autocompleteProps: {
          url: 'carriers/search/list/',
          queryValue: 'inquiry_carrier',
          value: formDataValues.carrier,
          onChange: (event, value) => setFormDataValues({ ...formDataValues, carrier: value }),
        },
      },
    ],
  };
  return formData;
}

export function createData(
  IEX, pc, date, reference, expedient, booking, origin,
  destiny, client, supplier, naviera, ware, etd, eta, rtd, rta, user, modal, actions, ebo, ebl,
) {
  return {
    IEX,
    pc,
    date,
    reference,
    expedient,
    booking,
    origin,
    destiny,
    client,
    supplier,
    naviera,
    ware,
    etd,
    eta,
    rtd,
    rta,
    user,
    modal,
    actions,
    ebo,
    ebl,
  };
}

export const initialFormDataValues = {
  order: { name: '' },
  client: { name: '' },
  origin: { name: '' },
  destiny: { name: '' },
  booking: { name: '' },
  status: { name: '' },
  user: { name: '' },
  clientRef: { name: '' },
  supplier: { name: '' },
  container: { name: '' },
  expedient: { name: '' },
  naviera: { name: '' },
  ship: { name: '' },
  bl: { name: '' },
  ended: { name: 'No', id: '0' },
  dua: { name: '' },
  addressee: { name: '' },
  shipper: { name: '' },
  iex: { name: '' },
  carrier: { name: '' },
};

export const formDataValueMap = {
  inquiry_client_order_number: 'order',
  inquiry_client: 'client',
  inquiry_origin: 'origin',
  inquiry_destination: 'destiny',
  inquiry_booking: 'booking',
  inquiry_state_id: 'status',
  inquiry_user: 'user',
  inquiry_reference: 'clientRef',
  inquiry_supplier: 'supplier',
  inquiry_container: 'container',
  inquiry_expedient: 'expedient',
  inquiry_shipping_company: 'naviera',
  inquiry_ship: 'ship',
  inquiry_bl: 'bl',
  inquiry_ended: 'ended',
  inquiry_dua: 'dua',
  inquiry_receiver: 'addressee',
  inquiry_shipper: 'shipper',
  inquiry_type: 'iex',
  inquiry_carrier: 'carrier',
};
