/* eslint-disable max-len */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  TableCell,
  TableRow as MaterialRow,
  Typography,
  Tooltip,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Global from '../../../Contexts/Global';
import { setModalFormData } from '../../../redux/actions/modalActions';
import CheckboxCell from '../CheckboxCell';
import { setFormData } from '../../../redux/actions/globalActions';
import useStyles from './style';

function TableRow({ row, style, tableFunctions, backgroundColor, selectItems, selectedItems, isModal, dispatch }) {
  const classes = useStyles();
  const { i18n } = useContext(Global);
  const rowKeys = row ? Object.keys(row) : null;
  const rowID = 'rowID';
  const itemID = 'itemID';
  const handleSaveOnChange = (event, value) => {
    let itemId = event.target.id;
    let itemValue = event.target.value;

    if (event.target.className === 'PrivateSwitchBase-input-100') {
      itemId = 'table_selection';
      itemValue = value;
    }
    if (isModal) {
      dispatch(setModalFormData({ id: itemId, value: itemValue }));
    } else {
      dispatch(setFormData({ id: itemId, value: itemValue }));
    }
  };
  return (
    <MaterialRow className={classes.row} style={backgroundColor ? { ...style, backgroundColor } : style}>
      {rowKeys && rowKeys.map((key) => {
        let cell = null;
        if (typeof row[key] === 'object') {
          if (row[key]?.id && row[key].name) {
            cell = (
              <TableCell
                align="left"
                className={classes.cell}
                style={backgroundColor ? { backgroundColor } : null}
                onClick={(tableFunctions?.rowClickFunction && selectItems) ? () => tableFunctions.rowClickFunction({ row }) : null}
              >
                <Typography
                  align="left"
                  className={classes.typography}
                  style={{ backgroundColor: backgroundColor || 'white', color: backgroundColor ? 'white' : 'black', padding: '0 2px' }}
                >
                  {row[key].name}
                </Typography>
              </TableCell>
            );
          } else if (row[key]?.link && !row[key]?.actions) {
            cell = (
              <TableCell align="left" className={classes.cell} style={backgroundColor ? { backgroundColor } : null}>
                {row[key]?.id
                  ? (
                    <Link
                      to={`/inquiries/inquiries-butransa/${row[key]?.label}/information/${row[key]?.id}`}
                      className={classes.link}
                      target="_blank"
                    >
                      {row[key]?.title}
                    </Link>
                  )
                  : (
                    <Typography
                      align="left"
                      className={classes.typography}
                      style={{ backgroundColor: backgroundColor || 'white', color: backgroundColor ? 'white' : 'black', padding: '0 2px' }}
                    >
                      {row[key]?.title}
                    </Typography>
                  )}
              </TableCell>
            );
          } else if (row[key]?.field_link) {
            cell = (
              <TableCell align="left" className={classes.cell} style={backgroundColor ? { backgroundColor } : null}>
                <a
                  href={row[key].field_link}
                  download
                  className={classes.link}
                >
                  {row[key]?.field_value}
                </a>
              </TableCell>
            );
          } else if (!row[key]?.link && row[key]?.actions) {
            cell = (
              <TableCell align="left" className={classes.cell} style={{ position: 'sticky', right: 0, zIndex: 50, backgroundColor: 'white' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div
                    className={classes.arrowBlock}
                    onClick={(event) => tableFunctions.handleClickActions(event, row[key]?.actions)}
                  >
                    <ArrowDropDownIcon />
                  </div>
                </div>
              </TableCell>
            );
          } else if (row[key]?.isStatus) {
            cell = (
              <TableCell align="left" className={classes.cell} style={{ position: 'sticky', right: row[key]?.isStatus === 'bl' ? 64 : 89, zIndex: 50, backgroundColor: 'white', paddingLeft: '3px', paddingRight: '3px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px' }}>
                  <Tooltip title={i18n.t(row[key].name)} arrow>
                    <div style={{ width: '15px', height: '15px', backgroundColor: row[key]?.color, borderRadius: '60%' }} />
                  </Tooltip>
                </div>
              </TableCell>
            );
          } else if (row[key]?.checkbox) {
            cell = (
              <TableCell
                align="center"
                className={classes.cell}
                style={backgroundColor ? { backgroundColor } : null}
                onClick={(tableFunctions?.rowClickFunction && selectItems) ? () => tableFunctions.rowClickFunction({ row }) : null}
              >
                <CheckboxCell
                  item={row[key]}
                  row={row}
                  onChange={handleSaveOnChange}
                  isTable
                  selectedItems={selectItems && selectedItems}
                />
              </TableCell>
            );
          }
        } else if ((typeof row[key] === 'string' || typeof row[key] === 'number') && key !== rowID && key !== itemID) {
          cell = (
            <TableCell
              align="left"
              className={classes.cell}
              style={backgroundColor ? { backgroundColor } : null}
              onClick={(tableFunctions?.rowClickFunction && selectItems) ? () => tableFunctions.rowClickFunction({ row }) : null}
            >
              <Typography
                align="left"
                className={classes.typography}
                style={{ backgroundColor: backgroundColor || 'white', color: backgroundColor ? 'white' : 'black', padding: '0 2px' }}
              >
                {row[key]}
              </Typography>
            </TableCell>
          );
        }
        return cell;
      })}
    </MaterialRow>
  );
}

TableRow.propTypes = {
  row: PropTypes.shape({
    row: {
      titles: PropTypes.arrayOf(PropTypes.string),
      rows: PropTypes.arrayOf(PropTypes.shape({})),
      rowActions: PropTypes.arrayOf(PropTypes.string),
    },
    selected: PropTypes.bool,
  }),
  style: PropTypes.shape({}),
  tableFunctions: PropTypes.shape({
    handleClickActions: PropTypes.func,
    rowClickFunction: PropTypes.func,
  }),
  backgroundColor: PropTypes.string,
  selectItems: PropTypes.bool,
  isModal: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  selectedItems: PropTypes.bool,
};

TableRow.defaultProps = {
  style: {},
  tableFunctions: {
    rowClickFunction: () => {},
    handleClickActions: null,
  },
  backgroundColor: 'white',
  selectItems: false,
  isModal: false,
  selectedItems: {},
  row: {},
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(TableRow);
