import actionTypes from '../actions/actionTypes';

export default function popupMenuReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionTypes.EMPTY_POPUP_OPTIONS:
      newState = { ...state, menuOptions: [] };
      break;
    case actionTypes.SET_POPUP_OPTIONS:
      newState = { ...state, menuOptions: data };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
