import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';
import * as MaterialIcons from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Global from '../../../Contexts/Global';
import useStyles from './style';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const DashboardCard = ({ info }) => {
  const classes = useStyles();
  const location = useLocation();
  const { i18n } = useContext(Global);
  const Icon = MaterialIcons[info?.icon];

  return (
    <Link to={{ pathname: '/inquiries/inquiries-butransa', state: { prevPath: location.pathname, link: info.method } }} style={{ textDecoration: 'none' }}>
      <div className={classes.dashboardCard}>
        <Paper className={classes.dashboardInfo} style={{ backgroundColor: `${info.color}8C` }}>
          <div className={classes.topCard}>
            <Typography variant="h5" style={{ color: info.textColor }}>{capitalizeFirstLetter(i18n.t(info.message))}</Typography>
            <Icon style={{ fontSize: '1.8rem' }} />
          </div>
          <Typography variant="h5" style={{ color: info.textColor }}>{info.total}</Typography>
        </Paper>
      </div>
    </Link>
  );
};

DashboardCard.propTypes = {
  info: PropTypes.shape({
    message: PropTypes.string,
    total: PropTypes.number,
    icon: PropTypes.func,
    method: PropTypes.string,
    color: PropTypes.string,
    textColor: PropTypes.string,
  }),
};

DashboardCard.defaultProps = {
  info: PropTypes.shape({}),
};

function mapStateToProps() {}

export default connect(mapStateToProps)(DashboardCard);
