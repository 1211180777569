import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  headerMargin: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '5px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    color: 'white',
  },
}));

export default useStyles;
