import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import Global from './Contexts/Global';
import Routes from './routes';
import configureStore from './redux/configureStore';
import theme from './DefaultStyles/styles';
import i18nFactory from './Literals';
import './App.css';

const i18n = i18nFactory({ lang: 'es-ES' });

const store = configureStore({
  globalReducer: { errors: [], filterList: {}, formData: { validated: false }, lastInquiresSearch: '' },
  modalReducer: { modalFormData: {}, actualModal: {} },
  keyReducer: { keys: {} },
  filesReducer: { files: [] },
  popupMenuReducer: { menuOptions: [] },
  tableReducer: {},
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Global.Provider value={{ i18n }}>
          <Routes />
        </Global.Provider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
