import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';

export default function BasicPopover({ open, anchorElement, onClose, children }) {
  return (
    <Popover
      id="simple-popover"
      open={open}
      anchorEl={anchorElement || null}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {children}
    </Popover>
  );
}

BasicPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorElement: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

BasicPopover.defaultProps = {
  children: null,
  anchorElement: null,
};
