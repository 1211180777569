import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useStyles from './style';
import Form from '../../Generic/Form';
import Table from '../../Generic/Table';
import Global from '../../../Contexts/Global';

export default function TransportType({ detail }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();

  const buttons = [];
  const grid = { spacing: 3 };
  const newDetail = { ...detail, form: { ...detail.form, buttons, grid } };

  return (
    <div className={classes.mainContainer}>
      <section>
        {(Boolean(newDetail?.form?.inputs?.length
        || newDetail?.form?.buttons?.length
        || (newDetail?.form?.inputs instanceof Object && Object.keys(newDetail?.form?.inputs)))
        )
        && (
        <div style={{ marginBottom: '10px', marginTop: '30px' }}>
          <Form newForm={newDetail.form} />
        </div>
        )}
        {Boolean(newDetail?.table?.titles?.length || newDetail?.table?.rows?.length)
        && (
        <Table tableData={newDetail.table} />
        )}
        {Boolean(newDetail?.table?.rows?.length)
        && (
          <div className={classes.containerCounter}>
            <span>
              {`Total: ${newDetail.table.rows.length} ${newDetail.table.rows.length > 1 ? i18n.t('CONTAINERS') : i18n.t('CONTAINER')}`}
            </span>
          </div>
        )}
      </section>
    </div>
  );
}

TransportType.propTypes = {
  detail: PropTypes.shape({
    form: PropTypes.shape({}),
    table: PropTypes.shape({}),
  }).isRequired,
};

TransportType.defaultProps = {};
