import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DashboardCard from '../../Components/Generic/DashboardCard';
import useStyles from './style';
import { getDashboard } from '../../redux/actions/globalActions';

const Dashboard = ({ dispatch, dashboardItems }) => {
  const classes = useStyles();

  useEffect(() => {
    if (!dashboardItems.length) {
      dispatch(getDashboard());
    }
  }, [dashboardItems]);

  return (
    <div className={classes.dashboardWrapper}>
      {dashboardItems && dashboardItems.map((item) => <DashboardCard info={item} />)}
    </div>
  );
};

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dashboardItems: PropTypes.arrayOf(PropTypes.shape({})),
};

Dashboard.defaultProps = {
  dashboardItems: [],
};

function mapStateToProps({ globalReducer }) {
  return {
    dashboardItems: globalReducer.dashboardItems,
  };
}

export default connect(mapStateToProps)(Dashboard);
