/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper, Divider, Typography } from '@material-ui/core';
import ReceiverInformation from '../../Components/Specific/ReceiverInformation';
import { getReceiver, emptyFormData, setFormData } from '../../redux/actions/globalActions';

function Receiver({ dispatch, apiReceiver }) {
  const [receiver, setReceiver] = useState(apiReceiver);
  const receiverId = useLocation().pathname.split('/')[4];

  useEffect(() => {
    setReceiver(apiReceiver);
  }, [apiReceiver]);

  useEffect(() => {
    dispatch(getReceiver(receiverId));
    dispatch(emptyFormData());
    dispatch(setFormData({ id: 'validated', value: false }));
  }, []);

  return (
    <Paper>
      <div style={{ padding: 10, paddingBottom: 25 }}>
        <div>
          { !receiverId && (
            <Typography variant="h4">Nuevo destinatario</Typography>
          )}
          { receiverId && (receiver && Object.keys(receiver).length !== 0) && (
            <Typography variant="h4">{receiver?.section?.title}</Typography>
          )}
        </div>
        <Divider />
      </div>
      {(receiver && Object.keys(receiver).length !== 0) && (
        <ReceiverInformation detail={receiver} setDetail={setReceiver} />
      )}
    </Paper>
  );
}

Receiver.propTypes = {
  dispatch: PropTypes.func,
  apiReceiver: PropTypes.shape({
    id: PropTypes.string,
    form: PropTypes.shape({
      inputs: PropTypes.shape([]),
      section: PropTypes.shape({}),
    }),
  }),
};

Receiver.defaultProps = {
  dispatch: () => {},
  apiReceiver: {},
};

function mapStateToProps({ globalReducer }) {
  return {
    apiReceiver: globalReducer.apiReceiver,
  };
}

export default connect(mapStateToProps)(Receiver);
