/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import actionTypes from './actionTypes';
import apiClient from '../../domain/fetchers/apiClient';
import { getActualModal, buildModal, sendModalAction } from './modalActions';

export function loadError(error) {
  return {
    type: actionTypes.LOAD_ERROR,
    data: error,
  };
}

export function emptyError() {
  return {
    type: actionTypes.EMPTY_ERROR,
  };
}

function loadUserSuccess(user) {
  return { type: actionTypes.SET_USER, data: user };
}

function loadInquiresSuccess(user) {
  return { type: actionTypes.SET_INQUIRES, data: user };
}

function loadReceiversSuccess(data) {
  return { type: actionTypes.SET_RECEIVERS, data };
}

function loadaRequestResponse(data) {
  return { type: actionTypes.REQUEST_RESPONSE, data };
}

export function loginUser(user) {
  return async (dispatch) => {
    const endpoint = '/users/auth-user';
    try {
      const { data } = await apiClient.post(endpoint, user);

      if (data.success) {
        const actualUser = { ...data.data.items, success: true };
        dispatch(loadUserSuccess(actualUser));
      } else {
        dispatch(loadUserSuccess({
          success: false,
          code: data.code,
          name: data.name,
          message: data.message,
        }));
        dispatch(loadError(data));
      }
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

export function setLastInquiresSearch(url) {
  return {
    type: actionTypes.SET_LAST_INQUIRES_SEARCH,
    data: url,
  };
}

function getInquiresColumnName(column) {
  let columnName = null;
  switch (column) {
    case 'PC':
      columnName = 'comandes_clients.numero';
      break;
    case 'I/E/X':
      columnName = 'comandes_clients.tipus';
      break;
    case 'DATE':
      columnName = 'comandes_clients.data';
      break;
    case 'REFERENCY':
      columnName = 'comandes_clients.referencia';
      break;
    case 'EXPEDIENT':
      columnName = 'bo.expedient';
      break;
    case 'BOOKING':
      columnName = 'bo.id';
      break;
    case 'ORIGIN':
      columnName = 'comandes_clients.codi_sortida_desc';
      break;
    case 'DESTINY':
      columnName = 'comandes_clients.codi_desti_desc';
      break;
    case 'CLIENT':
      columnName = 'c.nom';
      break;
    case 'SUPPLIER':
      columnName = 'comandes_clients.nom_proveidor';
      break;
    case 'NAVIERA':
      columnName = 'nav.nom';
      break;
    case 'WARE':
      columnName = 'comandes_clients.descripcio_mercaderia';
      break;
    case 'STATE':
      columnName = 'comandes_clients.estat_id';
      break;
    case 'ETD':
      columnName = 'bo.etd_prevista';
      break;
    case 'ETA':
      columnName = 'bo.eta_prevista';
      break;
    case 'RTD':
      columnName = 'bo.etd_real';
      break;
    case 'RTA':
      columnName = 'bo.eta_real';
      break;
    case 'USER':
      columnName = 'u.nom';
      break;
    default:
      columnName = column;
      break;
  }

  return columnName;
}

function getFieldValue(fieldId, formDataValues, formDataValueMap) {
  return !!formDataValues[formDataValueMap[fieldId]].name || !!formDataValues[formDataValueMap[fieldId]].value;
}

export function getInquiresList({
  page = 1, rowsPerPage = 10, inputs = [], url, order, orderBy, setSpinner, resetParam, formDataValues, formDataValueMap,
}) {
  return async (dispatch) => {
    if (setSpinner) {
      setSpinner(true);
    }
    let endpoint = url || null;
    let newObjParams = {};
    inputs.map((input) => {
      if (input.tagName === 'INPUT' && (input.name || input.value)) {
        if (getFieldValue(input.id, formDataValues, formDataValueMap)) newObjParams = { ...newObjParams, [input.id]: input.name || input.value };
      }
    }).filter((item) => item);
    const params = Object.keys(newObjParams).map((param) => (`&${param}=${newObjParams[param]}`)).join('');
    if (!endpoint) {
      endpoint = 'inquiries-butransa';
      if (params.length > 0) {
        endpoint += `/search?page=${page}&limit=${rowsPerPage}${params}${resetParam}`;
      } else {
        endpoint += (order && orderBy) ? `?page=${page}&limit=${rowsPerPage}` : `?page=${page}&per-page=${rowsPerPage}${params}${resetParam}`;
      }
    } else {
      endpoint += `?page=${page}&limit=${rowsPerPage}`;
      if (params.length > 0) {
        endpoint += `${params}${resetParam}`;
      }
    }
    // estado - buscar - filtro expediente
    if (order && orderBy) {
      if (params.length > 0) {
        endpoint += `&order_by=${getInquiresColumnName(orderBy)}&order=${order}`;
      } else {
        endpoint += `&order_by=${getInquiresColumnName(orderBy)}&order=${order}${resetParam}`;
      }
    }
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };

    dispatch(setLastInquiresSearch(endpoint));

    try {
      const { data } = await apiClient.get(endpoint, config);

      if (!data.success && data.data.code !== 200) {
        dispatch(loadInquiresSuccess({ items: [] }));
        throw new Error(data.data.message);
      } else if (data.success) {
        dispatch(loadInquiresSuccess({ items: data.data.items, meta: data.data._meta }));
      }
    } catch (error) {
      dispatch(loadInquiresSuccess({ items: [] }));
      dispatch(loadError(error.message));
    }
    if (setSpinner) {
      setSpinner(false);
    }
  };
}

export function setFilterList(list, id) {
  return {
    type: actionTypes.SET_FILTER_LIST,
    data: { list, id },
  };
}

export function setInquiresFromDashboard(method) {
  return async (dispatch) => {
    const endpoint = method;

    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };

    try {
      const { data } = await apiClient.get(endpoint, config);

      if (!data.success && data.data.code !== 200) {
        dispatch(loadInquiresSuccess({ items: [] }));
        throw new Error(data.data.message);
      } else if (data.success) {
        dispatch(loadInquiresSuccess({ items: data.data.items, pageInfo: data.data._meta }));
      }
    } catch (error) {
      dispatch(loadInquiresSuccess({ items: [] }));
      dispatch(loadError(error.message));
    }
  };
}

export function resetInquiresList({ setSpinner }) {
  return async (dispatch) => {
    setSpinner(true);
    const endpoint = 'inquiries-butransa?reset=1';
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };

    dispatch(setLastInquiresSearch(endpoint));

    try {
      const { data } = await apiClient.get(endpoint, config);

      if (!data.success && data.data.code !== 200) {
        dispatch(loadInquiresSuccess({ items: [] }));
        throw new Error(data.data.message);
      } else if (data.success) {
        dispatch(loadInquiresSuccess({ items: data.data.items, meta: data.data._meta }));
      }
    } catch (error) {
      dispatch(loadInquiresSuccess({ items: [] }));
      dispatch(loadError({ inquiresListError: error.message }));
    }
    setSpinner(false);
  };
}

export function downloadCSV(uri) {
  const link = document.createElement('a');
  link.download = '';
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function sendAction({ link, method = 'GET', id, postData }) {
  // const finalLink = link.replace('https://hc16.infoselfcloud.com/butransa', 'http://165.22.114.147:3082');
  const finalLink = link;
  return async (dispatch) => {
    const endpoint = id ? finalLink + id : finalLink;
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const postArray = ['POST', 'post'];
    try {
      let response = null;
      if (postArray.includes(method) && postData) {
        response = await apiClient[method.toLowerCase()](endpoint, postData, config);
      } else if (method.toLowerCase() === 'put') {
        response = await apiClient[method.toLowerCase()](endpoint, {}, config);
      } else {
        response = await apiClient[method.toLowerCase()](endpoint, config);
      }
      const { data } = response;
      if (data.success && data.data?.items) {
        const filterList = data.data?.items;
        dispatch(setFilterList(filterList));
      } else if (data) {
        dispatch(loadError(data));
        loadaRequestResponse(data);
        console.log('data>', data);
        console.log('response>', data.success, response?.config?.url, window.location.pathname);
        if (!data.success && data.excel_url && data.excel_url !== '') {
          downloadCSV(data.excel_url);
          return;
        }
        if (data.success && response?.config?.url && response.config.url.indexOf('/receivers/receiver/') !== -1) {
          const [redirect, aux] = window.location.pathname.split('/receiver/');
          window.location.href = redirect;
        }
      }
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

function setDashboard(dashboard) {
  return { type: actionTypes.SET_DASHBOARD, data: dashboard };
}

export function getDashboard() {
  return async (dispatch) => {
    const endpoint = 'dashboard/get-dashboard-all';
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };

    try {
      const { data } = await apiClient.get(endpoint, config);
      if (data.success) {
        dispatch(setDashboard(data.data.items));
      } else {
        dispatch(loadError(data));
      }
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

export function setActualBooking(booking) {
  return {
    type: actionTypes.SET_ACTUAL_BOOKING,
    data: booking,
  };
}

export function setActualOrder(order) {
  return {
    type: actionTypes.SET_ACTUAL_ORDER,
    data: order,
  };
}

export function setActualOrderType(orderType) {
  return {
    type: actionTypes.SET_ACTUAL_ORDER_TYPE,
    data: orderType,
  };
}

export function setActualBookingType(orderType) {
  return {
    type: actionTypes.SET_ACTUAL_BOOKING_TYPE,
    data: orderType,
  };
}

export function getReceiversList({ url, setSpinner, order, orderBy, resetParam, formDataValues, rowsPerPage, page }) {
  const { client, search } = formDataValues;
  console.log(client, search, order, orderBy, rowsPerPage, url);
  return async (dispatch) => {
    if (setSpinner) {
      setSpinner(true);
    }
    let isFirst = true;
    let params;
    let endpoint = 'receivers/main-list';
    if (client && client !== '') {
      endpoint += `/${client}`;
    }
    if (url) {
      let aux;
      [aux, params] = url.split('?');
    }
    if (params && !page) {
      endpoint += `?${params}`;
      isFirst = false;
    }

    if (page) {
      endpoint += `?page=${page}`;
      isFirst = false;
    }

    let prefix = isFirst ? '?' : '&';
    if ((endpoint.indexOf('search') === -1) && search && search !== '') {
      endpoint += `${prefix}search=${search}`;
      isFirst = false;
    }
    if ((endpoint.indexOf('order') === -1) && order && orderBy) {
      prefix = isFirst ? '?' : '&';
      endpoint += `${prefix}order=${order}`;
      isFirst = false;
    }
    if ((endpoint.indexOf('order_by') === -1) && orderBy) {
      prefix = isFirst ? '?' : '&';
      endpoint += `${prefix}order_by=${orderBy}`;
      isFirst = false;
    }
    if ((endpoint.indexOf('limit') === -1) && rowsPerPage) {
      prefix = isFirst ? '?' : '&';
      endpoint += `${prefix}limit=${rowsPerPage}`;
      isFirst = false;
    }
    if (resetParam) {
      endpoint += `${resetParam}`;
    }
    console.log(endpoint);
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    dispatch(setLastInquiresSearch(endpoint));

    try {
      const { data } = await apiClient.get(endpoint, config);
      if (!data.success) {
        dispatch(loadReceiversSuccess({ items: [] }));
        // throw new Error(data.data.message);
      } else if (data.success) {
        dispatch(loadReceiversSuccess({ items: data.table.items, meta: data._meta, links: data._links }));
      }
    } catch (error) {
      dispatch(loadReceiversSuccess({ items: [] }));
      dispatch(loadError(error.message));
    }
    if (setSpinner) {
      setSpinner(false);
    }
  };
}
export function setReceiver(data) {
  return {
    type: actionTypes.SET_RECEIVER,
    data,
  };
}
export function setClients(data) {
  return {
    type: actionTypes.SET_CLIENTS,
    data: data.data,
  };
}

function capitalizeFirstLetter(string) {
  const newString = string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
  return newString;
}

function buildTable(table, dispatch) {
  const newTable = {};
  if (table && table?.headers && table?.items) {
    newTable.titles = table.headers.map((header) => (
      {
        align: 'center',
        title: header.name,
      }
    ));

    newTable.rows = table.items.map((item) => {
      const rowValues = Object.values(item.item).map((value) => {
        if (value.field_value instanceof Object) {
          return value.field_value.name || '';
        } if (value.field_link) {
          return value;
        }
        return value.field_value || '';
      });

      const newItem = {};

      newTable.titles.forEach((title, index) => {
        newItem[newTable.titles[index].title] = rowValues[index];
      });

      if (item.actions) {
        newItem.actions = { actions: item.actions.map((action) => {
          let newAction = null;
          if (action.modal_confirm === true) {
            const inputs = [
              {
                field_label: 'cancelar',
                field_name: 'cancel',
                field_type: 'button',
                field_width: { lg: 4 },
              },
              { field_type: 'space', field_with: { lg: 12 } },
              {
                field_label: 'aceptar',
                field_type: 'button',
                field_width: { lg: 4 },
                link: action.link,
                method: action.method,
              },
            ];
            newAction = {
              title: capitalizeFirstLetter(action?.name),
              icon: action?.icon,
              function: () => {
                buildModal({ name: action.name, inputs, modal_message: action.modal_message }, dispatch);
              },
            };
          } else if (action.modal_inputs || action.modal_table) {
            newAction = {
              title: capitalizeFirstLetter(action?.name),
              icon: action?.icon,
              function: () => {
                buildModal({ name: action.modal_name, inputs: action.modal_inputs }, dispatch);
              },
            };
          } else if (action.link) {
            newAction = {
              title: capitalizeFirstLetter(action?.name),
              icon: action?.icon,
              function: () => {
                dispatch(sendModalAction({ link: action.link, method: action.method, name: action.modal_name }));
              },
            };
          } else {
            newAction = {
              title: capitalizeFirstLetter(action?.name),
              icon: action?.icon,
              function: () => { console.log('Pending modal'); },
            };
          }
          return newAction;
        }),
        };
      }

      return newItem;
    });

    if (newTable.rows[0] && newTable.rows[0].actions) {
      newTable.titles.push({ align: 'center', title: '' });
    }
  }

  return newTable;
}

const inputsWithAll = ['contacte_id'];
function buildInput(input) {
  let inputObject = {};
  switch (input.field_type) {
    case 'input':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { fullWidth: true, defaultValue: input.field_value },
        id: input.field_name,
        name: input.field_name,
      };
      break;
    case 'label':
      inputObject = {
        textFieldProps: { fullWidth: true, defaultValue: input.field_name.name, type: 'label' },
        labelProps: { backgroundColor: input.field_name['background-color'], textColor: input.field_name.color },
        id: input.field_name.name,
      };
      break;
    case 'date':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { fullWidth: true, defaultValue: input.field_value, InputLabelProps: { shrink: true }, type: 'date' },
        id: input.field_name,
        name: input.field_name,
      };
      break;
    case 'select':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { fullWidth: true, defaultValue: input.field_value, required: true, InputLabelProps: { shrink: true }, select: true },
        selectOptions: input.field_select || null,
        autocompleteProps: {
          url: input.field_action?.link,
          queryValue: input.field_name,
          all: inputsWithAll.includes(input.field_name),
          openModal: input.field_open_modal,
        },
        id: input.field_name,
        name: input.field_name,
      };
      break;
    case 'textArea':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { multiline: true, rows: 6, fullWidth: true, defaultValue: input.field_value },
        id: input.field_name,
        name: input.field_name,
      };
      break;
    case 'time':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { fullWidth: true, defaultValue: input.field_value, InputLabelProps: { shrink: true }, type: 'time' },
        id: input.field_name,
        name: input.field_name,
      };
      break;
    case 'checkbox':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        textFieldProps: { defaultValue: input.field_value, InputLabelProps: { shrink: false }, type: 'checkbox' },
        id: input.field_name,
        name: input.field_name,
      };
      break;
    case 'space':
      inputObject = {
        space: true,
        id: Math.random() ** Math.random(),
      };
      break;
    case 'button':
      inputObject = {
        buttonProps: {
          label: capitalizeFirstLetter(input.field_label),
          icon: input.field_modal?.icon,
          requestModalLink: input.field_modal?.link,
          requestModalMethod: input.field_modal?.method,
          buttonLink: input.link,
          buttonMethod: input.method,
        },
        id: input.field_label,
      };
      break;
    case 'multiselect':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { fullWidth: true, defaultValue: input.field_value, required: true, InputLabelProps: { shrink: true }, select: true },
        multiselectOptions: input.field_select,
        id: input.field_name,
        name: input.field_name,
      };
      break;
    case 'html':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { type: 'html', fullWidth: true, defaultValue: input.field_value, required: true, InputLabelProps: { shrink: true }, select: true },
        id: input.field_name,
      };
      break;
    case 'hidden':
      inputObject = {
        hidden: true,
        label: '',
        textFieldProps: { fullWidth: true, defaultValue: input.field_value, type: 'hidden' },
        id: input.field_name,
      };
      break;
    default:
      break;
  }

  inputObject.gridProps = !inputObject.hidden ? input.field_width || { lg: 3 } : { lg: 0 };
  if (input.field_disabled) {
    inputObject.textFieldProps.disabled = true;
  }

  if (input.parent_id) {
    inputObject.parent = input.parent_id;
  }

  return inputObject;
}

export function setFormData({ id, value }) {
  return {
    type: actionTypes.SET_FORM_DATA,
    data: { id, value },
  };
}

function buildFormInputs(inputs, dispatch) {
  let newInputs = [];
  if (inputs instanceof Array && inputs.length) {
    newInputs = inputs.map((input) => {
      const inputObject = buildInput(input);

      if (input.field_button && input.field_type !== 'button') {
        inputObject.addButton = {
          icon: input.field_button.field_icon,
          label: input.field_button.field_label,
        };
        if (input.field_button.field_modal && !input.field_button.field_modal.link) {
          inputObject.addButton.onClick = () => buildModal({
            name: input.field_button.field_modal.modal_name,
            inputs: input.field_button.field_modal.modal_inputs,
            table: input.field_button.field_modal.modal_table,
          }, dispatch);
        } else if (input.field_button.field_modal && input.field_button.field_modal.link) {
          const modalInfo = {
            modal_name: input.field_button.field_modal.modal_name,
            link: input.field_button.field_modal.link,
            method: input.field_button.field_modal.method,
          };
          inputObject.addButton.onClick = () => getActualModal(modalInfo, dispatch);
        }
      } else if (inputObject.buttonProps?.buttonLink && inputObject.buttonProps?.buttonMethod) {
        inputObject.buttonProps.onClick = () => {
          const params = {
            link: inputObject.buttonProps?.buttonLink,
            method: inputObject.buttonProps?.buttonMethod,
          };
          if (params.method.toLowerCase() === 'post') {
            const formData = new FormData(document.querySelector('form'));
            const postData = { };
            const formInputs = inputs.filter((formInput) => !!formInput.field_name).map((formInput) => {
              const value = formData.get(`${formInput.field_name}`) ?? document.getElementById(`${formInput.field_name}`).name;
              if (value && value !== '') {
                postData[`${formInput.field_name}`] = value;
              }
              return formInput;
            });
            params.postData = postData;
          }
          dispatch(sendAction(params));
        };
      } else if (inputObject.buttonProps?.requestModalLink && inputObject.buttonProps?.requestModalMethod) {
        const modalInfo = {
          modal_name: inputObject.buttonProps.label,
          link: inputObject.buttonProps.requestModalLink,
          method: inputObject.buttonProps.requestModalMethod,
          multiselect: input.field_modal?.multiselect,
        };
        inputObject.buttonProps.onClick = () => getActualModal(modalInfo, dispatch);
      } else if (input.field_modal && input.field_modal?.link && input.field_modal?.method) {
        const modalInfo = {
          modal_name: inputObject.buttonProps.label,
          link: inputObject.buttonProps.requestModalLink,
          method: inputObject.buttonProps.requestModalMethod,
        };
        inputObject.buttonProps.onClick = () => getActualModal(modalInfo, dispatch);
      } else if (input.field_modal && input.field_modal.modal_inputs) {
        inputObject.buttonProps.onClick = () => buildModal({
          name: input.field_modal.modal_name,
          inputs: input.field_modal.modal_inputs,
        }, dispatch);
      }
      return inputObject;
    });
  }

  return newInputs;
}

function buildDetail(detail, dispatch) {
  const newDetail = { section: detail?.section, form: { inputs: {}, buttons: [] }, table: {}, id: detail.order_number || detail.booking_number };
  if (detail.inputs instanceof Array) {
    let inputsArr = detail.inputs;
    if (inputsArr[1] instanceof Array) {
      const [a, b] = inputsArr;
      inputsArr = b;
    }
    newDetail.form.inputs = buildFormInputs(inputsArr, dispatch);
  } else if (detail.inputs instanceof Object) {
    const inputBlocks = Object.keys(detail.inputs);

    inputBlocks.forEach((block) => {
      newDetail.form.inputs[block] = buildFormInputs(detail.inputs[block], dispatch);
    });
  }
  if (detail.table && detail.table?.headers && detail.table?.items) {
    newDetail.table = buildTable(detail.table, dispatch);
  }
  return newDetail;
}

function setHasEquatorialTab(boolean) {
  return {
    type: actionTypes.SET_EQUATORIAL_TAB,
    data: boolean,
  };
}

export function getOrder(orderID, tab) {
  return async (dispatch) => {
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const tabsEndpoint = `clients-orders/tabs/${orderID}`;
    const newOrderKey = 'new';
    let endpoint = (orderID === newOrderKey) ? 'clients-orders/view/new/' : `clients-orders/view/${orderID}/`;
    switch (tab) {
      case 'information':
        endpoint += 'information';
        break;
      case 'comodity':
        endpoint += 'merchancy';
        break;
      case 'documentation':
        endpoint += 'documentation';
        break;
      case 'delivery-orders':
        endpoint += 'transportorders';
        break;
      case 'incidences':
        endpoint += 'incidences';
        break;
      case 'edi':
        endpoint += 'equatorial';
        break;
      default:
        break;
    }

    try {
      const { data } = await apiClient.get(tabsEndpoint, config);
      if (data?.items?.length > 5) {
        dispatch(setHasEquatorialTab(true));
      } else {
        dispatch(setHasEquatorialTab(false));
      }
    } catch (error) {
      dispatch(loadError(error));
    }
    try {
      const { data } = await apiClient.get(endpoint, config);
      dispatch(setActualOrder(buildDetail(data, dispatch)));
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

export function getBooking(modalName, bookingID, tab, query, newBookingOrderID) {
  return async (dispatch) => {
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const newOrderKey = 'new';
    let endpoint = (bookingID === newOrderKey) ? 'bookings/view/new/' : `bookings/view/${bookingID}/`;

    switch (tab) {
      case 'information':
        endpoint += 'information';
        break;
      case 'transport':
        endpoint += 'transportation';
        break;
      case 'clients-orders':
        endpoint += 'clientsorders';
        break;
      case 'bls':
        endpoint += 'bl';
        break;
      case 'documentation':
        endpoint += 'documentation';
        break;
      case 'delivery-orders':
        endpoint += 'transportorders';
        break;
      case 'incidences':
        endpoint += 'incidences';
        break;
      case 'transshipments':
        endpoint += 'transshipments';
        break;
      default:
        break;
    }

    if (query) {
      endpoint += `/${query}`;
    } else if (newBookingOrderID) {
      endpoint += `/${newBookingOrderID}`;
    }
    if (endpoint !== 'bookings/view/transport/') {
      try {
        const { data } = await apiClient.get(endpoint, config);
        console.log('booking endpoint', endpoint);
        console.log('booking data', data);
        dispatch(setActualBooking(buildDetail(data, dispatch)));
      } catch (error) {
        dispatch(loadError(error));
      }
    }
  };
}

export function getReceiver(id) {
  return async (dispatch) => {
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    let endpoint;
    if (id) {
      endpoint = `receivers/receiver/edit/${id}`;
    } else {
      endpoint = 'receivers/receiver/new';
    }
    try {
      const { data } = await apiClient.get(endpoint, config);
      dispatch(setReceiver(buildDetail(data, dispatch)));
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

export function getClients() {
  return async (dispatch) => {
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const endpoint = 'clients/search/list';
    try {
      const { data } = await apiClient.get(endpoint, config);
      dispatch(setClients(data, dispatch));
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

export function getOrderType(orderID) {
  return async (dispatch) => {
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const endpoint = `clients-orders/view/${orderID}/information`;

    try {
      const { data } = await apiClient.get(endpoint, config);
      dispatch(setActualOrderType(buildDetail(data, dispatch)));
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

export function getBookingType(orderID) {
  return async (dispatch) => {
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const endpoint = `bookings/view/${orderID}/information`;
    try {
      const { data } = await apiClient.get(endpoint, config);
      dispatch(setActualBookingType(buildDetail(data, dispatch)));
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

export function getFiltersList(url, label) {
  return async (dispatch) => {
    if (url.includes('iex')) {
      const filterList = [{ name: 'Importación', id: 'IMP' }, { name: 'Exportación', id: 'EXP' }, { name: 'CrossTrading', id: 'X' }];
      dispatch(setFilterList(filterList, label));
    } else if (url.includes('ended')) {
      const filterList = [{ name: 'Sí', id: '1' }, { name: 'No', id: '0' }];
      dispatch(setFilterList(filterList, label));
    } else {
      const token = localStorage.getItem('accessToken');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      let endpoint = url;
      if (url[url.length - 1] === '/') {
        endpoint = url.slice(0, url.length - 1);
      }
      try {
        const { data } = await apiClient.get(endpoint, config);
        if (data instanceof Object && !(data instanceof Array)) {
          if (data.success) {
            const filterList = data.data.items;
            dispatch(setFilterList(filterList, label));
          } else {
            dispatch(setFilterList([], label));
            dispatch(loadError(data));
          }
        } else if (data instanceof Array) {
          dispatch(setFilterList(data, label));
        }
      } catch (error) {
        dispatch(loadError(error));
      }
    }
  };
}

export function emptyFormData(resetFormData = {}) {
  return {
    type: actionTypes.EMPTY_FORM_DATA,
    data: resetFormData,
  };
}

export function setDocumentCreationStatus(created) {
  return {
    type: actionTypes.SER_DOCUMENT_CREATION_STATUS,
    data: created,
  };
}

export function setCleanList(cleanList) {
  return {
    type: actionTypes.SET_CLEAN_LIST,
    data: cleanList,
  };
}

export function saveForm({ data, tab, id, isNew, onSuccess = () => {} }) {
  console.log('saveForm', data, data, id, isNew);
  return async (dispatch) => {
    let flag = true;
    const actualData = { ...data };
    let endpoint = null;

    if (tab === 'order') {
      endpoint = '/clients-orders/';
    } else if (tab === 'booking') {
      endpoint = '/bookings/';
    }

    if (isNew) {
      endpoint += 'create';
    } else {
      endpoint += `update/${id}`;
    }
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const successKey = 'success';
    const filteredData = {};

    if (actualData.tipus_transport_id) {
      switch (actualData.tipus_transport_id.id) {
        case '27':
          actualData.lc_carga = undefined;
          actualData.origen_dades = undefined;
          actualData.lc_destino_final = undefined;
          actualData.desti_dades = undefined;
          break;
        case '29':
          actualData.lc_carga = undefined;
          actualData.origen_dades = undefined;
          break;
        case '28':
          actualData.lc_destino_final = undefined;
          actualData.desti_dades = undefined;
          break;
        default:
          break;
      }
    }
    if (data) {
      Object.keys(actualData).forEach((key) => {
        if (actualData[key] instanceof Array) {
          filteredData[key] = actualData[key].map((element) => element.id);
        } else if (actualData[key] instanceof Object) {
          if (actualData[key].realValue) {
            filteredData[key] = actualData[key].realValue.id || actualData[key].realValue.value;
          } else {
            filteredData[key] = actualData[key].id || actualData[key].value;
          }
        } else if (
          actualData[key] || actualData[key] === '' || actualData[key] === 0 || actualData[key] === null || actualData[key] === false
        ) {
          filteredData[key] = actualData[key];
        }
      });
    }

    if (endpoint) {
      try {
        const response = await apiClient.post(endpoint, filteredData, config);
        if (response.data.success === false || response.data.success === 'false') {
          flag = false;
        }
        if (response.data.result === successKey) {
          dispatch(loadError(response.data));
          dispatch(setDocumentCreationStatus(true));
        } else if (response.data.validate) {
          flag = false;
          dispatch(loadError(response.data));
          dispatch(setFormData({ id: 'validated', value: response.data.validate }));
        } else {
          dispatch(loadError(response.data));
        }
      } catch (error) {
        flag = false;
        dispatch(loadError(error));
      }
    }
    setTimeout(() => {
      onSuccess(flag);
    }, 2500);
  };
}

export function emptyInquiries() {
  return {
    type: actionTypes.EMPTY_INQUIRIES,
  };
}

export function setNewBookingOrderID(id) {
  return {
    type: actionTypes.SET_NEW_BOOKING_ORDER_ID,
    data: id,
  };
}

export async function getExcel(url) {
  const endpoint = url.includes('?') ? url + '&export=1' : url + '?export=1';
  // const endpoint = 'https://hc16.infoselfcloud.com/api-butransa/inquiries-butransa/search-dashboard/1?page=1&limit=20&export=1';
  const token = localStorage.getItem('accessToken');
  const config = { headers: { Authorization: `Bearer ${token}` } };

  try {
    const response = await apiClient.get(endpoint, config);
    if (response.data && response.data.excel_url) {
      downloadCSV(response.data.excel_url);
    }
    // console.log('response', response);
  } catch (error) {
    console.log('error', error);
  }
}

export function setSettedOrderNumber(orderNumber) {
  return {
    type: actionTypes.SET_SETTED_ORDER_NUMBER,
    data: orderNumber,
  };
}

export function setResendForm(resendForm) {
  return {
    type: actionTypes.SET_RESEND_FORM,
    data: resendForm,
  };
}
