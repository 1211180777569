const esES = {
  ACTIONS: 'Acciones',
  ADD_BOOKING_STATUS: 'Añadir estado Booking',
  ADD_ORDER_STATUS: 'Añadir estado Pedido',
  ADD_SHIPPER: 'Añade un nuevo transportista',
  ADDRESSEE: 'Consignee',
  BOOKING: 'Booking',
  BOOKINGS: 'bookings',
  CANCEL_BOOKING_PORTIC: 'Cancelar Booking Portic',
  CANCELED: 'Cancelado',
  CARRIER: 'Transportista',
  CHARGE: 'Carga',
  CLEAR: 'Limpiar',
  CLIENT: 'Cliente',
  CLIENT_CONTACT: 'Contacto del cliente',
  CLIENT_REF: 'Ref. Cliente',
  COMMENT: 'Comentario',
  COMMODITY: 'Mercancía',
  CONTAINER: 'Contenedor',
  DATE: 'Fecha',
  DESTINATION: 'Destino',
  DESTINY: 'Destino',
  DESTINY_WAREHOUSE: 'Almacén de destino',
  DOCUMENTATION: 'Documentación',
  DUPLICATE_BOOKING: 'Duplicar Booking',
  DUPLICATE_ORDER: 'Duplicar Pedido',
  EVENTS: 'Eventos',
  EXCEL_EXPORT: 'Exportar Excel',
  EXPEDIENT: 'Expediente',
  EXPORT: 'Exportación',
  FOUND: 'Encontrados',
  HOUR: 'Hora',
  INCIDENCE: 'Incidencias',
  MORE_FILTERS: 'Más filtros',
  MORE_INFO: 'Más información',
  NAVIERA: 'Naviera',
  NEW: 'Nuevo',
  NEW_INCIDENCE: 'Nueva incidencia',
  NEW_TRANSPORT_ORDER: 'Nueva orden de transporte',
  NUM_ORDER: 'Num. Pedido',
  NUM_BOOKING: 'Num. Booking',
  OBSERVATIONS: 'Observaciones',
  ORDER: 'Pedido',
  ORIGIN: 'Origen',
  PASSWORD: 'Contraseña',
  PENDING: 'Pendiente',
  R_EXPORT: 'exportación',
  R_COMMODITY: 'mercancía',
  R_DOCUMENTATION: 'documentación',
  R_MORE_INFO: 'más-información',
  R_DELIVERY_ORDERS: 'órdenes-de-transporte',
  R_INCIDENCE: 'incidencias',
  R_INFORMATION: 'Información',
  INFORMATION: 'Información',
  REFERENCY: 'Referencia',
  REPLIES: 'Contestaciones',
  SEARCH: 'Buscar',
  ORDERS_WO_BOOKINGS: 'Pedidos sin Bookings',
  NOT_ENDED_BOOKINGS: 'Bookings no finalizados',
  RECORDS_W_ERRORS: 'Expedientes con error',
  TERMINAL_INPUTS: 'Entradas en Terminal',
  CUSTOMS_CLEARENCE: 'Despachos aduana',
  EMBARKED: 'Embarcados',
  MERCHANDISE_IN_PORT: 'Mercancía de puerto de transbordo',
  MERCHANDISE_IN_TRANSSHIPMENT_SHIP: 'Mercancía en barco de transbordo',
  MERCHANDISE_DELIVERED_TO_CLIENT: 'Mercancía entregada en cliente',
  SEND_BOOKING_PORTIC: 'Enviar Booking a Portic',
  SEND_M_TRANS: 'Enviar M-TRANS',
  SEND_RECEIVED: 'Enviar OK Recibido',
  SHIPPER: 'Shipper',
  STATUS: 'Estado',
  SUPPLIER: 'Shipper',
  SUPPLIER_FILTER: 'Proveedor',
  TYPE: 'Tipo',
  USER: 'Usuario',
  USERNAME: 'Nombre usuario',
  WARE: 'Mercancía',
  WARNING: 'Advertencia',
  DELIVERY_ORDERS: 'Órdenes de transporte',
  REFERENCE: 'Referencia',
  DESTINATION_WAREHOUSE: 'Almacen de destino',
  WHO: 'De Quien?',
  WORK_ORDERS: 'Órdenes de trabajo',
  DESCRIPTION: 'Descripción',
  PACKAGES: 'Bultos',
  WEIGHT: 'Peso',
  VOLUME: 'Volumen',
  TEMPERATURE: 'Temperatura',
  CODE: 'Código',
  CLASS: 'Clase',
  GROUP: 'Grupo',
  PAGE: 'Página',
  SAVE: 'Guardar',
  NEW_TRANSPORT_ORDER_CAP: 'Nueva Orden de Transporte',
  DANGEROUS_COMODITY: 'M.Peligrosa',
  DANGEROUS_COMODITIES_CAP: 'Materias Peligrosas',
  NEW_INCIDENCE_CAP: 'Nueva Incidencia',
  CANCEL: 'Cancelar',
  INQUIRIES: 'Consultas',
  TRANSPORT: 'Transporte',
  CLIENT_ORDERS: 'Pedidos de clientes',
  INCIDENCES: 'Incidencias',
  ACTUAL_STATE_CAP: 'Estado Actual',
  ACTUAL_DATE_CAP: 'Fecha Actual',
  CLIENT_DATE_CAP: 'Fecha Cliente',
  NEW_STATE_CAP: 'Nuevo Estado',
  LOCATION: 'Ubicación',
  TRANSPORT_TYPE: 'Tipo transporte',
  FLETE: 'Flete',
  SERVICE_DATE: 'Fecha servicio',
  SERVICE_HOUR: 'Hora servicio',
  ORIGIN_LOADING_PORT: 'Origen / Puerto de carga',
  DESTINY_DISCHARGE_PORT: 'Destino / Puerto de descarga',
  CLIENTS_ORDERS: 'Pedidos de clientes',
  QUANTITY: 'Cantidad',
  T_PACKAGE: 'T.Bulto',
  KG: 'Kg',
  SEAL: 'Precinto',
  EDIT_CONTAINER: 'Editar contenedor',
  DUPLICATE_CONTAINER: 'Duplicar contenedor',
  DELETE_CONTAINER: 'Eliminar contenedor',
  CONTAINERS: 'Contenedores',
  EDIT_COMMAND: 'Editar comanda',
  DELETE_COMMAND: 'Eliminar comanda',
  SCALE: 'Escala',
  BOAT: 'Barco',
  FLAG: 'Bandera',
  SHIPPING: 'Naviera',
  DOCK: 'Muelle',
  INCOTERM: 'Incoterm',
  LLOYD: 'Lloyd',
  DUTIES: 'Albaranes',
  EXTRA_DUTIES: 'Albaranes extra',
  ERROR: 'Error',
  OK: 'Ok',
  SEND: 'Enviado',
  BOATS_AND_SCALES_CAP: 'Escalas y Barcos',
  FROM_WHO: 'De quien?',
  CONTACT: 'Contacto',
  NAME: 'Nombre',
  WAREHOUSE: 'Almacén',
  ETD_SCHEDULED: 'ETD prevista',
  ETD_REAL: 'ETD real',
  ETA_SCHEDULED: 'ETA prevista',
  ETA_REAL: 'ETA real',
  NOTIFY: 'Notify',
  DUA: 'DUA',
  DELIVERY_NOTE_MTRANS: 'Albarán MTrans',
  BOOKING_OBSERVATIONS: 'Observaciones Booking',
  TRANSPORT_OBSERVATIONS: 'Observaciones transporte',
  OFFICE_OBSERVATIONS: 'Observaciones despacho',
  SEND_OFFICE: 'Envío despacho',
  END: 'Final',
  FINAL_DESTINY: 'Destino final',
  NEW_BL: 'Nuevo BL',
  EDIT_BL: 'Editar BL',
  DELETE_BL: 'Eliminar BL',
  SELECT_CLIENT_ORDER: 'Seleccionar pedido de cliente',
  BL_TYPE: 'Tipo BL',
  MARKS: 'Marks',
  SELECT: 'Seleccionar',
  ORDER_NUMBER: 'Nº Pedido',
  STATE: 'Estado',
  EDIT_ORDER: 'Editar Pedido',
  DELETE_ORDER: 'Eliminar Pedido',
  MODIFY_TYPE: 'Modificar tipo',
  END_ORDER: 'Finalizar Pedido',
  ORDER_LIST: 'Listado de Pedidos',
  BOOKING_LIST: 'Listado de Bookings',
  EXPORT_EXCEL: 'Exportar Excel',
  EDIT_BOOKING: 'Editar Booking',
  DELETE_BOOKING: 'Eliminar Booking',
  END_BOOKING: 'Finalizar Booking',
  BOOKING_NUMBER: 'Nº Booking',
  SHIP: 'Barco',
  BL: 'Bl',
  ENDED: 'Finalizado',
  SITUATION: 'Situación',
  PROFILE: 'Perfil',
  MY_ACCOUNT: 'Mi cuenta',
  LOGOUT: 'Cerrar sesión',
  SEND_BOOKING: 'Enviar Booking',
  SEND_MTRANS: 'Enviar M-Trans',
  DELETE: 'Eliminar',
  NEW_ORDER: 'Nuevo Pedido',
  ASSIGN_NEW_ORDER: 'Asignar nuevo pedido',
  INQUIRIES_BUTRANSA: 'Consultas butransa',
  INQUIRES_BUTRANSA: 'Consultas butransa',
  RECEIVERS: 'Destinatarios',
  ROWS_PER_PAGE: 'Filas por página',
  EVENTS_LIST: 'Listado de eventos',
  TRACK_ID: 'Track ID',
  ANSWERS_LIST: 'Listado de contestaciones',
  NOT_ACCEPTED: 'No Aceptado',
  ACCEPTED: 'Aceptado',
  RECEPTOR_RECEIVED_DOCUMENT: 'El receptor ha recibido el documento',
  DOCUMENT_SENT: 'El documento ha sido enviado',
  WARNINGS: 'Avisos',
  CLOSED_NO_MORE_SENDS: 'Cierre. No se aceptan más envíos',
  ACCEPT: 'Aceptar',
  NO_RESULTS: 'No hay resultados...',
  TRANSSHIPMENTS: 'Transbordos',
  DELETE_RECEIVER: 'Eliminar destinatario',
};

export default esES;
