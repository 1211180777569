import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  dropZone: {
    border: '1px dashed grey',
    padding: theme.spacing(8),
    cursor: 'pointer',
  },
  selectHolders: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
  },
  container: {
    margin: theme.spacing(4),
  },
}));
export default useStyles;
