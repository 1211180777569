const actionModalTypes = {
  LOAD_ERROR: 'LOAD_ERROR',
  SET_ACTUAL_MODAL: 'SET_ACTUAL_MODAL',
  SET_MODAL_FILTER_LIST: 'SET_MODAL_FILTER_LIST',
  SET_MODAL_FORM_DATA: 'SET_MODAL_FORM_DATA',
  EMPTY_MODAL_FORM_DATA: 'EMPTY_MODAL_FORM_DATA',
  EMPTY_ACTUAL_MODAL: 'EMPTY_ACTUAL_MODAL',
};

export default actionModalTypes;
