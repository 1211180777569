/* eslint-disable no-underscore-dangle */
import actionTypes from './actionTypes';
import apiClient from '../../domain/fetchers/apiClient';
import { buildModalTable } from './modalActions';

function loadError(error) {
  return {
    type: actionTypes.LOAD_ERROR,
    data: error,
  };
}

export function setReduxTableRows(rows) {
  return {
    type: actionTypes.SET_REDUX_TABLE_ROWS,
    data: rows,
  };
}

export function setTable(table) {
  return {
    type: actionTypes.SET_TABLE,
    data: table,
  };
}

export function getTable({ url, query, page = 0, perPage = 20, param, orderBy, order }) {
  return async (dispatch) => {
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    let newUrl = null;

    if (url[url.length - 1] === '/') {
      newUrl = url.substring(0, url.length - 1);
    } else {
      newUrl = url;
    }

    if (query) {
      if (newUrl[newUrl.length - 1] === '/') {
        newUrl += `/${query}`;
      } else {
        newUrl += `/${query}`;
      }
    } if (param) {
      newUrl += `?${param}&page=${page}&limit=${perPage}`;
    } else {
      newUrl += `?page=${page}&limit=${perPage}`;
    }

    if (order && orderBy) {
      newUrl += `&order_by=${orderBy}&order=${order}`;
    }

    try {
      const { data } = await apiClient.get(newUrl, config);
      let builtTable = null;

      if (data.data) {
        builtTable = {
          table: buildModalTable(data.data.items),
          links: data.data._links,
          meta: data.data._meta,
        };
      } else {
        builtTable = {
          table: buildModalTable(data.table),
          links: data._links,
          meta: data._meta,
        };
      }
      dispatch(setTable(builtTable));
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

export function emptyReduxTable() {
  return {
    type: actionTypes.EMPTY_TABLE,
  };
}
