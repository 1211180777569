import I18n from '@s-ui/i18n';
import Polyglot from '@s-ui/i18n/lib/adapters/polyglot';

import esES from './es-ES';
import ptPT from './pt-PT';

const i18n = new I18n({ adapter: new Polyglot() });
i18n.languages = {
  'es-ES': esES,
  'pt-PT': ptPT,
};

const i18nFactory = ({ lang }) => {
  i18n.culture = lang;
  return i18n;
};

export default i18nFactory;
