import actionTypes from '../actions/actionTypes';

export default function tableReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionTypes.SET_TABLE:
      newState = { ...state, table: data };
      break;
    case actionTypes.EMPTY_TABLE:
      newState = { ...state, table: {} };
      break;
    case actionTypes.SET_REDUX_TABLE_ROWS:
      newState = {
        ...state,
        table: { ...state.table, rows: data },
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
