import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  checkBox: {
    marginRight: theme.spacing(1),
    marginLeft: 0,
  },
  formContainer: {
    position: 'relative',
    width: '100%',
  },
}));

export default useStyles;
