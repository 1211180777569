import actionKeyTypes from '../actions/actionKeyTypes';

export default function modalReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionKeyTypes.SET_KEY:
      newState = {
        ...state,
        keys:
                    { ...state.keys, [data.id]: data },
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
