import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '5px',
  },
  headerMargin: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '5px',
    marginBottom: '30px',
  },
}));

export default useStyles;
