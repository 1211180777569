import React from 'react';
import PropTypes from 'prop-types';
import Form from '../../Generic/Form';
import useStyles from './style';

export default function Comodities({ detail }) {
  const classes = useStyles();

  const buttons = [];
  const grid = { spacing: 3 };
  const newDetail = { ...detail, form: { ...detail.form, buttons, grid } };

  return (
    <div className={classes.mainContainer}>
      <section className={classes.paperSection}>
        <Form newForm={newDetail.form} />
      </section>
    </div>
  );
}

Comodities.propTypes = {
  detail: PropTypes.shape({
    form: PropTypes.shape({}),
    table: PropTypes.shape({}),
  }).isRequired,
};

Comodities.defaultProps = {};
