import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: '24px',
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  content: {
    backgroundColor: theme.palette.primary.light,
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: '100vh',
    width: '100vw',
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    marginLeft: -drawerWidth,
  },
  paper: {
    padding: theme.spacing(2),
    transition: 'all 0.2s ease-out',
    '-moz-transition': 'all 0.2s ease-out', /* Firefox 4 */
    '-webkit-transition': 'all 0.2s ease-out', /* Safari 和 Chrome */
    '-o-transition': 'all 0.2s ease-out', /* Opera */
  },
}));

export default useStyles;
