import actionTypes from '../actions/actionTypes';

export default function globalReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionTypes.LOAD_ERROR:
      newState = { ...state, error: data };
      break;
    case actionTypes.EMPTY_ERROR:
      newState = { ...state, error: null };
      break;
    case actionTypes.SET_USER:
      newState = {
        ...state,
        actualUser: data,
        userTokens: { accessToken: data.access_token, refreshToken: data.refresh_token },
      };
      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('accessToken', data.access_token);
      localStorage.setItem('refreshToken', data.refresh_token);
      break;
    case actionTypes.SET_INQUIRES:
      newState = { ...state, inquiresList: data };
      break;
    case actionTypes.SET_RECEIVERS:
      newState = { ...state, receiversList: data };
      break;
    case actionTypes.SET_ACTUAL_ORDER:
      newState = { ...state, actualOrder: data };
      break;
    case actionTypes.SET_ACTUAL_BOOKING:
      newState = { ...state, actualBooking: data };
      break;
    case actionTypes.SET_DASHBOARD:
      newState = { ...state, dashboardItems: data };
      break;
    case actionTypes.SET_FILTER_LIST:
      newState = { ...state, filterList: { ...state.filterList, [data.id]: data.list } };
      break;
    case actionTypes.SET_EQUATORIAL_TAB:
      newState = { ...state, hasEquatorialTab: data };
      break;
    case actionTypes.SET_FORM_DATA:
      newState = {
        ...state,
        formData:
          { ...state.formData, [data.id]: data.value },
      };
      break;
    case actionTypes.EMPTY_FORM_DATA:
      newState = {
        ...state,
        formData: data,
      };
      break;
    case actionTypes.SER_DOCUMENT_CREATION_STATUS:
      newState = { ...state, documentCreationStatus: data };
      break;
    case actionTypes.SET_CLEAN_LIST:
      newState = { ...state, cleanList: data };
      break;
    case actionTypes.EMPTY_INQUIRIES:
      newState = {
        ...state,
        inquiresList: {},
      };
      break;
    case actionTypes.SET_NEW_BOOKING_ORDER_ID:
      newState = {
        ...state,
        newBookingOrderID: data,
      };
      break;
    case actionTypes.SET_LAST_INQUIRES_SEARCH:
      newState = {
        ...state,
        lastInquiresSearch: data,
      };
      break;
    case actionTypes.SET_SETTED_ORDER_NUMBER:
      newState = {
        ...state,
        settedOrderNumber: data,
      };
      break;
    case actionTypes.SET_RESEND_FORM:
      newState = {
        ...state,
        resendForm: data,
      };
      break;
    case actionTypes.SET_ACTUAL_ORDER_TYPE:
      newState = {
        ...state,
        actualOrderType: data,
      };
      break;
    case actionTypes.SET_ACTUAL_BOOKING_TYPE:
      newState = {
        ...state,
        actualBookingType: data,
      };
      break;
    case actionTypes.SET_RECEIVER:
      newState = {
        ...state,
        apiReceiver: data,
      };
      break;
    case actionTypes.SET_CLIENTS:
      newState = {
        ...state,
        apiClients: data,
      };
      break;
    case actionTypes.REQUEST_RESPONSE:
      newState = {
        ...state,
        requestResponse: data,
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
