import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
  },
  paper: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  logoCont: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderTopLeftRadius: '50px',
    borderBottomRightRadius: '50px',
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
