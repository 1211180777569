import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Global from '../../../Contexts/Global';
import BasicButton from '../../Generic/BasicButton';
import Table from '../../Generic/Table';
import BasicModal from '../../Generic/BasicModal';
import FormModal from '../FormModal';
import useStyles from './style';
import Form from '../../Generic/Form';

function createFormData(pc, date, client, reference, destiny, commodity, packages, kgs) {
  return { pc, date, client, reference, destiny, commodity, packages, kgs };
}

export default function BLS({ detail }) {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');
  const [orderByModal, setOrderByModal] = useState('');
  const [orderProductsModal, setOrderProductsModal] = useState('asc');

  const buttons = [];
  const grid = { spacing: 3 };

  const newDetail = { ...detail, form: { ...detail.form, buttons, grid } };
  const formTableData = {
    rows: [
      createFormData('17815', '21/06/2021', 'MORCHEM S.A.', '14854 IND COMTECK', '-', 'RESINAS DE POLIUTERANO (IMO 3, II, UN 1866)', '20', '17760'),
      createFormData('17816', '21/06/2021', 'MORCHEM S.A.', '14854 IND COMTECK', '-', 'RESINAS DE POLIUTERANO (IMO 3, II, UN 1866)', '20', '17760'),
      createFormData('17817', '21/06/2021', 'MORCHEM S.A.', '14854 IND COMTECK', '-', 'RESINAS DE POLIUTERANO (IMO 3, II, UN 1866)', '20', '17760'),
      createFormData('17818', '21/06/2021', 'MORCHEM S.A.', '14854 IND COMTECK', '-', 'RESINAS DE POLIUTERANO (IMO 3, II, UN 1866)', '20', '17760'),
    ],
    titles: [
      { align: 'center', title: 'P.C.' },
      { align: 'center', title: 'DATE' },
      { align: 'center', title: 'CLIENT' },
      { align: 'center', title: 'REFERENCE' },
      { align: 'center', title: 'DESTINY' },
      { align: 'center', title: 'COMMODITY' },
      { align: 'center', title: 'PACKAGES' },
      { align: 'center', title: 'KGS' },
    ],
  };

  const newForm = {
    inputs: [
      { label: i18n.t('Nº'), placeholder: '', textFieldProps: { fullWidth: true, required: true }, gridProps: { lg: 12 } },
      { label: i18n.t('BL_TYPE'), placeholder: '', textFieldProps: { fullWidth: true, select: true, required: true }, selectOptions: [{ value: 'TIPO1', label: 'Valorados' }, { value: 'TIPO2', label: 'No valorados' }, { value: 'TIPO3', label: 'Sea Waybill' }], gridProps: { lg: 12 } },
      { label: i18n.t('FINAL_DESTINY'), placeholder: '', textFieldProps: { fullWidth: true, required: true }, gridProps: { lg: 12 } },
      { label: i18n.t('WEIGHT'), placeholder: '', textFieldProps: { fullWidth: true, required: true }, gridProps: { lg: 12 } },
      { label: i18n.t('VOLUME'), placeholder: '', textFieldProps: { fullWidth: true, required: true }, gridProps: { lg: 12 } },
      { label: i18n.t('SHIPPER'), placeholder: i18n.t('SHIPPER'), textFieldProps: { fullWidth: true, multiline: true, rows: 6 }, gridProps: { lg: 12 } },
      { label: i18n.t('ADDRESSEE'), placeholder: i18n.t('ADDRESSEE'), textFieldProps: { fullWidth: true, multiline: true, rows: 6 }, gridProps: { lg: 12 } },
      { label: i18n.t('NOTIFY'), placeholder: i18n.t('NOTIFY'), textFieldProps: { fullWidth: true, multiline: true, rows: 6 }, gridProps: { lg: 12 } },
      { label: i18n.t('DESCRIPTION'), placeholder: i18n.t('DESCRIPTION'), textFieldProps: { fullWidth: true, multiline: true, rows: 6 }, gridProps: { lg: 12 } },
      { label: i18n.t('MARKS'), placeholder: i18n.t('MARKS'), textFieldProps: { fullWidth: true, multiline: true, rows: 6 }, gridProps: { lg: 12 } },
    ],
    buttons: [
      { space: true, gridProps: { lg: 7 } },
      { label: i18n.t('SAVE'), gridProps: { lg: 2 }, buttonProps: { variant: 'contained' } },
      { label: i18n.t('CANCEL'), gridProps: { lg: 2 }, buttonProps: { variant: 'outlined' } },
    ],
    grid: { spacing: 3 },
  };

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';

    if (isDesc) {
      setOrderBy(false);
    } else {
      setOrderBy(column);
    }

    setOrderProducts(isAsc ? 'desc' : 'asc');
  }

  async function handleRequestSortModal(column) {
    const isAsc = orderByModal === column && orderProductsModal === 'asc';
    const isDesc = orderByModal === column && orderProductsModal === 'desc';

    if (isDesc) {
      setOrderByModal(false);
    } else {
      setOrderByModal(column);
    }

    setOrderProductsModal(isAsc ? 'desc' : 'asc');
  }

  return (
    <div className={classes.mainContainer}>
      {Boolean(newDetail?.form?.inputs?.length || newDetail?.form?.buttons?.length)
        && (
        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          <Form newForm={newDetail.form} />
        </div>
        )}
      {Boolean(newDetail?.table?.titles?.length || newDetail?.table?.rows?.length)
        && (
        <>
          <Table tableData={newDetail.table} />
          <BasicModal open={openModal} closeFunction={() => setOpenModal(false)} title={i18n.t('SELECT_CLIENT_ORDER')}>
            <Table tableData={formTableData} selectedItems={{ items: selectedItems, setItems: setSelectedItems, key: 'pc' }} />
            <section style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop: '10px' }}>
              <BasicButton title={i18n.t('SELECT')} variant="contained" />
              <BasicButton title={i18n.t('CANCEL')} variant="contained" style={{ marginLeft: '60px' }} />
            </section>
          </BasicModal>
          <FormModal
            open={openEditModal}
            form={newForm}
            title={i18n.t('BL')}
            setCloseModal={() => setOpenEditModal(false)}
          />
        </>
        )}
    </div>
  );
}

BLS.propTypes = {
  detail: PropTypes.shape({
    form: PropTypes.shape({}),
    table: PropTypes.shape({}),
  }).isRequired,
};

BLS.defaultProps = {};
