import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2e1f46',
      contrastText: '#fff',
      light: '#e1e1ec',
    },
  },
  overrides: {
    MuiAutocomplete: {
      root: {
        marginRight: '8px',
      },
    },
    MuiFormControl: {
      root: {
        flexDirection: 'row',
      },
      marginDense: {
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '36px',
      },
    },
    MuiTabs: {
      root: {
        borderBottom: '.7px solid #D3DAE3',
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
      root: {
        borderRadius: '5px',
        // backgroundColor: "#2e1f46",
        margin: '3px 0',
        '&$selected': {
          backgroundColor: '#2e1f46',
          color: '#fff',
          '&:hover': {
            color: 'inherit',
            backgroundColor: '#e1e1ec',
          },
        },
      },
      button: {
        '&:hover': {
          backgroundColor: '#e1e1ec',
        },
      },
    },
    MuiDrawer: {
      paper: {
        top: '64px',
        bottom: 0,
      },
    },
    MuiInputBase: {
      formControl: {
        width: '100%',
      },
    },
    MuiList: {
      root: {
        margin: '0 8px',
      },
    },
  },
  props: {
    MuiPaper: {
      elevation: 10,
    },
  },
});

export default theme;
