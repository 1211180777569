import { combineReducers } from 'redux';
import globalReducer from './globalReducer';
import modalReducer from './modalReducer';
import filesReducer from './filesReducer';
import keyReducer from './keyReducer';
import tableReducer from './tableReducer';
import popupMenuReducer from './popupMenuReducer';

const rootReducer = combineReducers({
  globalReducer, modalReducer, keyReducer, filesReducer, tableReducer, popupMenuReducer,
});

export default rootReducer;
