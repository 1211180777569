import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bookingsFound: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  spinnerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '100px',
  },
  spinnerBox: {
    backgroundColor: 'rgba(255,255,255,0.4)',
    padding: '40px 100px',
    borderRadius: '10px',
  },
  customTableStyles: {
    padding: 'inherit',
  },
}));

export default useStyles;
