/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Menu as MenuIcon, Close } from '@material-ui/icons';
import * as MaterialIcons from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import BasicButton from '../BasicButton';
import logo from '../../../assets/butransa-logo.png';
import Global from '../../../Contexts/Global';
import { saveForm, setCleanList, setDocumentCreationStatus, sendAction } from '../../../redux/actions/globalActions';
import { emptyActualModal, getActualModal, setActualModal } from '../../../redux/actions/modalActions';
import { getMenuOptions } from '../../../redux/actions/popupMenuActions';
import useStyles from './style';

const NavigationBar = ({
  dispatch, formData, menuActive, handleToggleDrawer, resendForm,
  documentCreationStatus, menuOptions, actualOrder, actualBooking,
}) => {
  const classes = useStyles();
  const { i18n } = useContext(Global);
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const newKey = 'new';
  const documentType = location.pathname.split('/')[3];
  const menuOptionsTypes = ['booking', 'order'];
  const orderID = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const deleteMethods = ['delete', 'DELETE', 'Delete'];
  const newOrderPath = 'new';
  const requestNumber = location.pathname.split('/')[location.pathname.split('/').length - 1];
  const createOrdersPaths = ['/inquiries/inquiries-butransa', '/dashboard'];
  const createReceiversPaths = ['/butransa/receivers', '/receivers'];
  const newBookingAction = 'Nuevo Booking';
  const assignBookingAction = 'Asignar Booking';

  function turnToLiteral(string) {
    let literalPath = string.replace('-', '_');
    if (i18n.t(literalPath.toUpperCase()) !== literalPath.toUpperCase()) {
      literalPath = i18n.t(literalPath.toUpperCase()).toLowerCase();
    }
    return literalPath;
  }

  function setBreadcrumbs(path) {
    const dividedPath = path.split('/');
    let newPath = '';

    dividedPath.forEach((subPath) => {
      if (subPath.length) {
        if (!Number.isNaN(+subPath)) {
          if (requestNumber === newOrderPath) {
            newPath += ` / ${turnToLiteral(subPath)}`;
          } else if (actualOrder || actualBooking) {
            newPath += ` / ${(actualOrder?.id || actualBooking?.id) || ''}`;
          }
        } else {
          newPath += ` / ${turnToLiteral(subPath)}`;
        }
      }
    });

    return newPath;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const returnToHome = () => {
    history.push('/dashboard');
  };
  function handleOnSave() {
    const loc = location.pathname.split('/');
    const tabName = loc[3];
    const isNew = loc[5];
    let sendId = loc[5];
    if (tabName === 'order' || tabName === 'booking') {
      if (resendForm && typeof resendForm === 'number') {
        sendId += '/' + resendForm;
      } else {
        sendId += '/0';
      }
    }
    const newForm = {
      data: formData,
      tab: tabName,
      id: sendId,
      isNew: isNew === newKey,
      onSuccess: (reload) => {
        if (reload) {
          window.location.reload();
        }
      },
    };
    dispatch(saveForm(newForm));
  }
  function handleSendAction(link, method) {
    handleClose();
    dispatch(sendAction({ link, method }));
    if (deleteMethods.includes(method)) {
      history.push('/inquiries/inquiries-butransa');
    }
  }

  function openConfirmModal({ name, message, action }) {
    const actualModal = {
      form: {
        inputs: [
          {
            buttonProps: { label: 'Cancelar', onClick: () => dispatch(emptyActualModal()), gridProps: { lg: 2 } },
          },
          {
            space: true, gridProps: { lg: 3 },
          },
          {
            buttonProps: {
              label: 'Confirmar',
              onClick: () => {
                handleSendAction(action.link, action.method);
                dispatch(emptyActualModal());
              },
              gridProps: { lg: 2 },
              id: name,
            },
          },
        ],
        grid: { spacing: 3 },
      },
      name,
      message,
    };
    dispatch(setActualModal(actualModal));
  }

  if (documentCreationStatus) {
    history.push('/inquiries/inquiries-butransa');
    dispatch(setDocumentCreationStatus(false));
    dispatch(setCleanList(true));
  }

  useEffect(() => {
    if (menuOptionsTypes.includes(documentType)) {
      dispatch(getMenuOptions({ id: orderID, type: documentType }));
    }
  }, [documentType]);

  return (
    <AppBar position="fixed" key="bar">
      <Toolbar>
        <IconButton
          onClick={handleToggleDrawer}
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuButton}
        >
          {menuActive ? <Close /> : <MenuIcon />}
        </IconButton>
        <div className={classes.root}>
          <div className={classes.startHeader}>
            <img alt="Butransa Logo" className={classes.logo} src={logo} onClick={returnToHome} />
            <div style={{ display: 'flex' }}>
              <HomeIcon color="inherit" style={{ marginRight: '3px' }} />
              <Typography>{setBreadcrumbs(location.pathname)}</Typography>
            </div>
          </div>
          {(createOrdersPaths.includes(location.pathname)) && (
            <div className={classes.endHeader}>
              <Link
                to="/inquiries/inquiries-butransa/order/information/new"
                className={classes.link}
                target="_blank"
              >
                <BasicButton title="Nuevo Pedido" variant="outlined" contrast />
              </Link>
              <Link
                to="/inquiries/inquiries-butransa/booking/information/new"
                className={classes.link}
                target="_blank"
              >
                <BasicButton title="Nuevo Booking" variant="outlined" contrast />
              </Link>
            </div>
          )}
          {(location.pathname.indexOf('receivers') !== -1) && (location.pathname.indexOf('/receiver/') === -1) && (
            <div className={classes.endHeader}>
              <Link
                to="/receivers/receiver/new"
                className={classes.link}
                target="_blank"
              >
                <BasicButton title="Nuevo Destinatario" variant="outlined" contrast />
              </Link>
            </div>
          )}
          {location.pathname.split('/')[3] === 'booking' && (
            <div className={classes.endHeader}>
              {requestNumber !== newKey
              && <BasicButton title="Más acciones" variant="outlined" contrast onClick={handleClick} />}
              <BasicButton title="Guardar" variant="outlined" contrast onClick={handleOnSave} />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {Boolean(menuOptions?.length)
                && menuOptions.map((option) => {
                  const Icon = MaterialIcons[option.icon] || null;
                  return (
                    <MenuItem
                      onClick={() => {
                        openConfirmModal(option);
                        handleClose();
                      }}
                    >
                      {Icon
                      && <Icon className={classes.icon} />}
                      {option.name}
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          )}
          {location.pathname.split('/')[3] === 'order' && (
            <div className={classes.endHeader}>
              {requestNumber !== newKey
              && <BasicButton title="Más acciones" variant="outlined" contrast onClick={handleClick} />}
              <BasicButton title="Guardar" variant="outlined" contrast onClick={handleOnSave} />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {Boolean(menuOptions?.length)
                && menuOptions.map((option) => {
                  const Icon = MaterialIcons[option.icon] || null;
                  return (
                    <>
                      {option.name === newBookingAction
                        && (
                          <MenuItem
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            <Link
                              className={classes.unlink}
                              to={{
                                pathname: '/inquiries/inquiries-butransa/booking/information/new',
                                state: { orderNumber: requestNumber },
                              }}
                            >
                              {Icon && <Icon className={classes.icon} />}
                              {option.name}
                            </Link>
                          </MenuItem>
                        )}
                      {option.name === assignBookingAction
                        && (
                          <MenuItem
                            onClick={() => {
                              const modal = {
                                ...option.action,
                                modal_name: option.name,
                              };
                              getActualModal(modal, dispatch);
                              handleClose();
                            }}
                          >
                            {Icon && <Icon className={classes.icon} />}
                            {option.name}
                          </MenuItem>
                        )}
                      {option.name !== newBookingAction && option.name !== assignBookingAction
                        && (
                          <MenuItem
                            onClick={() => {
                              openConfirmModal(option);
                              handleClose();
                            }}
                          >
                            {Icon && <Icon className={classes.icon} />}
                            {option.name}
                          </MenuItem>
                        )}
                    </>
                  );
                })}
              </Menu>
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

function mapStateToProps({ globalReducer, popupMenuReducer }) {
  return {
    resendForm: globalReducer.resendForm,
    formData: globalReducer.formData,
    documentCreationStatus: globalReducer.documentCreationStatus,
    menuOptions: popupMenuReducer.menuOptions,
    actualOrder: globalReducer.actualOrder,
    actualBooking: globalReducer.actualBooking,
  };
}
export default connect(mapStateToProps)(NavigationBar);
