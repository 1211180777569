/* eslint-disable no-debugger */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Paper, IconButton, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core';
import * as MaterialIcons from '@material-ui/icons';
import { connect } from 'react-redux';
import $ from 'jquery';
import { useLocation, useHistory } from 'react-router-dom';
import CollapseGrid from '../CollapseGrid';
import AutocompleteField from '../AutocompleteField';
import { setFormData } from '../../../redux/actions/globalActions';
import { setModalFormData } from '../../../redux/actions/modalActions';
import FileUploader from '../FileUploader';
import CheckboxField from '../CheckboxField';
import MultiselectField from '../MultiselectField';
import TextInput from '../TextInput';
import useStyles from './style';

const getItemIcon = (icon) => {
  let Icon = null;
  if (icon) {
    Icon = MaterialIcons[icon];
    return <Icon />;
  }
  return null;
};
const Form = ({
  newForm, formType, onSubmit, formOpenedObject, dispatch, formData, modalFormData, isModal, shrink,
}) => {
  const classes = useStyles();
  const [collapse, setCollapse] = useState(false);
  const form = useRef();
  const page = useLocation().pathname.split('/')[3];
  const [datachanged, setdatachanged] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (newForm?.inputs?.block3) {
      const formVal = newForm?.inputs?.block3[0]?.textFieldProps?.defaultValue?.id;
      const val = formVal?.slice(0, 2).toLowerCase();
      if (formVal && val !== 'eg') {
        $('form div:nth-child(10)').addClass('bhidden');
      }
    }
    let client = newForm?.inputs[4]?.textFieldProps?.defaultValue?.id;
    let label = newForm?.inputs[17]?.id;
    const labelVal = 'numero_albara_morchem';
    const clientVal = '4';
    if (newForm?.inputs && client !== clientVal && label === labelVal) {
      $('form div:nth-child(18)').addClass('bhidden');
    }
    client = newForm?.inputs[5]?.textFieldProps?.defaultValue?.id;
    label = newForm?.inputs[18]?.id;
    if (newForm?.inputs && client !== clientVal && label === labelVal) {
      $('form div:nth-child(19)').addClass('bhidden');
    }
    if (newForm.inputs instanceof Array) {
      newForm?.inputs?.forEach((item) => {
        if ((!formData[item.id] && formData[item.id] !== '' && formData[item.id] !== 0) && item.textFieldProps) {
          if (isModal) {
            dispatch(setModalFormData({ id: item?.id, value: item?.textFieldProps?.defaultValue }));
          } else {
            dispatch(setFormData({ id: item?.id, value: item?.textFieldProps?.defaultValue }));
          }
        }
      });
    } else if (newForm.inputs instanceof Object) {
      Object.keys(newForm.inputs).forEach((block) => {
        if (newForm?.inputs[block].length > 0) {
          newForm.inputs[block].forEach((item) => {
            if ((!formData[item.id] && formData[item.id] !== '' && formData[item.id] !== 0) && item.textFieldProps) {
              dispatch(setFormData({ id: item?.id, value: item?.textFieldProps?.defaultValue }));
            }
          });
        }
      });
    }
    if (newForm?.buttons?.length === 0) {
      $('label').addClass('bold');
    }
  }, [newForm.inputs]);

  const handleSaveOnChange = (e) => {
    const value = e.target.type === 'checkbox' ? +e.target.checked : e.target.value;
    if (isModal) {
      dispatch(setModalFormData({ id: e.target.id, value }));
    } else {
      dispatch(setFormData({ id: e.target.id, value }));
    }
  };
  function getGridItem(item) {
    let gridItem = null;
    if (item?.textFieldProps?.type === 'html') {
      gridItem = (
        <div dangerouslySetInnerHTML={{
          __html: item.textFieldProps.defaultValue }}
        />
      );
      return gridItem;
    }
    if (item.outerLabel) {
      gridItem = (
        <Typography className={classes.outerLabel}>
          {item.outerLabel}
        </Typography>
      );
    } if (item.space) {
      gridItem = <div style={{ padding: '2% 0px' }} />;
    } if (item.buttonProps && item.buttonProps.label !== 'Nuevo Documento') {
      gridItem = (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
          {item.buttonProps.icon ? (
            <Tooltip title={item.buttonProps.label} key={item.id}>
              <IconButton
                color="primary"
                variant="contained"
                style={{ fontSize: '12px' }}
                key={item.buttonProps.label}
                onClick={item.buttonProps.onClick}
              >
                {getItemIcon(item.buttonProps.icon)}
              </IconButton>
            </Tooltip>
          )
            : (
              <Button
                color="primary"
                variant="contained"
                startIcon={getItemIcon(item.buttonProps.icon)}
                style={{ fontSize: '12px' }}
                key={item.buttonProps.label}
                onClick={item.buttonProps.getsModalForm
                  ? () => item.buttonProps.onClick(modalFormData)
                  : item.buttonProps.onClick}
              >
                {item.buttonProps.label}
              </Button>
            )}
        </div>
      );
    } if (item.buttonProps && item.buttonProps.label === 'Nuevo Documento') {
      gridItem = (
        <div
          key={item.id}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
        >
          <FileUploader label={item.buttonProps.label} component={item} />
        </div>
      );
    } if (!item.space && !item.selectOptions && !item.autocompleteProps && (item.textFieldProps?.type && item.textFieldProps.type === 'checkbox')) {
      gridItem = (
        <div style={{ display: 'flex', padding: '1.5px 0' }}>
          <CheckboxField
            item={item}
            onChange={handleSaveOnChange}
            dispatch={dispatch}
            value={formData[item.id]}
            isModal={isModal}
            key={item.id}
          />
        </div>
      );
    } if (item.labelProps) {
      gridItem = (
        <div
          className={classes.label}
          style={{ backgroundColor: item.labelProps.backgroundColor }}
          key={item.id}
        >
          <span style={{ color: item.labelProps.textColor }}>
            {item.textFieldProps.defaultValue}
          </span>
        </div>
      );
    } if (item.selectOptions && !item.autocompleteProps) {
      gridItem = (
        <TextField
          {...item.textFieldProps}
          id={item.id}
          margin="dense"
          label={item.label}
          placeholder={item.placeholder}
          className={classes.textField}
          name={item.name}
          key={item.id}
          InputLabelProps={{
            style: { fontWeight: 'bold' },
          }}
        >
          {item.selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );
    } if (item.multiselectOptions) {
      gridItem = (
        <MultiselectField
          {...item.autocompleteProps}
          item={item}
          dispatch={dispatch}
          isModal={isModal}
          key={item.id}
        />
      );
    } if (item.autocompleteProps) {
      if (item.selectOptions) {
        gridItem = (
          <AutocompleteField
            {...item.autocompleteProps}
            options={item.selectOptions}
            item={item}
            isModal={isModal}
            key={item.id}
            newBookingOrderID={newForm.newBookingOrderID}
          />
        );
      } else {
        gridItem = (
          <AutocompleteField
            {...item.autocompleteProps}
            item={item}
            isModal={isModal}
            key={item.id}
            newBookingOrderID={newForm.newBookingOrderID}
          />
        );
      }
    } if (item.searchProps) {
      gridItem = (
        <TextInput
          item={item}
          classes={classes}
          value={formData[item.id]}
          isModal={isModal}
          key={item.id}
        />
      );
    } if (!item.space && !item.selectOptions && !item.autocompleteProps
      && !item.labelProps && !item.buttonProps && !item.multiselectOptions && item.textFieldProps?.type === 'text') {
      gridItem = (
        <TextField
          {...item.textFieldProps}
          name={item.name}
          key={item?.id}
          label={item.label}
        />
      );
    } else if (!item.space && !item.selectOptions && !item.autocompleteProps
      && !item.labelProps && !item.buttonProps && !item.multiselectOptions && item.textFieldProps?.type !== 'checkbox') {
      if (formType !== 'receiver') {
        gridItem = (
          <TextInput
            item={item}
            style={classes.textField}
            value={formData[item.id] ?? {}}
            isModal={isModal}
            key={item.id}
            shrink={(item.textFieldProps?.type === 'date' || item.textFieldProps?.type === 'time') ? true : shrink}
          />
        );
      } else {
        gridItem = (
          <TextField
            {...item.textFieldProps}
            name={item.name}
            key={item?.id}
            label={item.label}
          />
        );
      }
    } if (item.hidden) {
      gridItem = (
        <TextField
          {...item.textFieldProps}
          name={item.name}
          key={item?.id}
        />
      );
    } if (item.addButton) {
      gridItem = (
        <>
          {gridItem}
          <Tooltip title={item.addButton.label} key={item.id}>
            <IconButton
              color="primary"
              component="span"
              className={classes.button}
              key={item.addButton.label}
              onClick={item.addButton.onClick}
            >
              {getItemIcon(item.addButton.icon)}
            </IconButton>
          </Tooltip>
        </>
      );
    }
    return gridItem;
  }
  const tab = useLocation().pathname.split('/')[4];
  const willSave = ['information', 'comodity', 'equatorial'].includes(tab);
  return (
    <div className={classes.formContainer}>
      {newForm.inputs instanceof Array
        ? (
          <form
            ref={form}
            onSubmit={onSubmit}
            onChange={(willSave || isModal) ? handleSaveOnChange : undefined}
          >
            <Grid container {...newForm.grid}>
              {newForm && newForm.inputs?.map((item) => (
                <Grid item {...item.gridProps} className={classes.gridItems} key={item.id}>
                  {getGridItem(item)}
                </Grid>
              ))}
              {!collapse && newForm?.buttons?.map((btn) => (
                <Grid item {...btn.gridProps} key={btn.label}>
                  <Button {...btn.buttonProps} color="primary">{btn.label}</Button>
                </Grid>
              ))}
              {newForm?.collapsed && (
                <CollapseGrid
                  form={newForm}
                  collapse={collapse}
                  setCollapse={setCollapse}
                  formOpenedObject={formOpenedObject}
                />
              )}
            </Grid>
          </form>
        )
        : (newForm.inputs && Object.keys(newForm.inputs).map((block) => (
          <Paper style={{ marginBottom: '20px', padding: '20px' }}>
            <form ref={form} onSubmit={onSubmit} onChange={handleSaveOnChange}>
              <Grid container {...newForm.grid}>
                { (newForm?.inputs[block].length > 0) && newForm?.inputs[block].map((item) => (
                  <Grid item {...item.gridProps} className={classes.gridItems} key={item.id}>
                    {getGridItem(item)}
                  </Grid>
                ))}
              </Grid>
            </form>
          </Paper>
        )))}
    </div>
  );
};

Form.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    tipus_transport_id: PropTypes.shape({}),
  }).isRequired,
  newForm: PropTypes.shape([]),
  formType: PropTypes.string,
  onSubmit: PropTypes.func,
  formOpenedObject: PropTypes.shape({
    setter: PropTypes.func,
    value: PropTypes.bool,
  }),
  modalFormData: PropTypes.shape({}),
  isModal: PropTypes.bool,
  shrink: PropTypes.bool,
};

Form.defaultProps = {
  newForm: PropTypes.shape({}),
  formType: '',
  onSubmit: PropTypes.func,
  formOpenedObject: {},
  modalFormData: {},
  isModal: false,
  shrink: false,
};

function mapStateToProps({ globalReducer, modalReducer }) {
  return {
    formData: globalReducer.formData,
    modalFormData: modalReducer.modalFormData,
  };
}

export default connect(mapStateToProps)(Form);
