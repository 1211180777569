import actionTypes from '../actions/actionTypes';

export default function filesReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionTypes.SET_FILES:
      newState = { ...state, files: data };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
