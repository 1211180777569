import actionModalTypes from '../actions/actionModalTypes';

export default function modalReducer(state = {}, { type, data }) {
  let newState = null;

  switch (type) {
    case actionModalTypes.SET_ACTUAL_MODAL:
      newState = { ...state, actualModal: data };
      break;
    case actionModalTypes.EMPTY_ACTUAL_MODAL:
      newState = {
        ...state,
        actualModal: {},
        modalFormData: {},
      };
      break;
    case actionModalTypes.SET_MODAL_FORM_DATA:
      newState = {
        ...state,
        modalFormData:
        { ...state.modalFormData, [data.id]: data.value },
      };
      break;
    case actionModalTypes.EMPTY_MODAL_FORM_DATA:
      newState = {
        ...state,
        modalFormData: {},
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
}
