import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(3),
  },
  buttonSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '20px 0px 40px 0px',
  },
}));
export default useStyles;
