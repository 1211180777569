import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formModalContainer: {
    maxWidth: '50%',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '5px',
  },
  test: {
    maxWidth: '600px',
  },
}));

export default useStyles;
