/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  useTheme,
  ListItemIcon,
  ListItemText,
  Collapse,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Dashboard,
  MenuBook,
  ListAlt,
  ExpandMore,
  NavigateNext,
  AccountCircle,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Global from '../../../Contexts/Global';
import useStyles from './style';
import { sendAction } from '../../../redux/actions/globalActions';

const SideBar = ({ open: active, dispatch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { i18n } = useContext(Global);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const email = JSON.parse(localStorage.getItem('user'))?.email;
  const userName = JSON.parse(localStorage.getItem('user'))?.username;

  const categories = {
    navigation: [
      { label: 'Dashboard', icon: <Dashboard />, key: 'dashboard', route: 'dashboard' },
      {
        label: 'INQUIRIES',
        icon: <MenuBook />,
        key: 'inquiries',
        route: 'inquiries',
        subcategories: [
          { label: 'Consultas Butransa', icon: <ListAlt />, key: 'inquiries-butransa', route: 'inquiries-butransa' },
        ],
      },
      {
        label: 'RECEIVERS',
        icon: <MenuBook />,
        key: 'receivers',
        route: 'receivers',
      },
    ],
    end: [
      {
        label: userName || 'USERNAME',
        icon: <AccountCircle />,
        subcategories: [
          { label: 'Consultas Butransa', icon: <ListAlt />, key: 'inquiries-butransa', route: 'inquiries-butransa' },
        ],
      },
    ] };

  const [toggleMenus, setToggleMenus] = useState(() => {
    let collapsedMenus = {};
    categories.navigation.forEach((category) => {
      if (category && category.subcategories) {
        collapsedMenus = { [category.key]: false, ...collapsedMenus };
      }
    });
    return collapsedMenus;
  });
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  function handleToggleCollapse(prop) {
    setToggleMenus({ ...toggleMenus, [prop]: !toggleMenus[prop] });
  }

  useEffect(() => {
    setOpen(active);
  }, [active]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    const link = 'users/logout-user';
    dispatch(sendAction({ link, postData: { email }, method: 'POST' }));
    window.location.href = '/butransa/login';
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <List>
        {categories?.navigation?.map((category, index) => (
          <React.Fragment key={category.key + index}>
            <Link to={!category.subcategories && { pathname: '/' + category.route, state: { prevPath: category.key } }} style={{ display: 'flex' }} className={classes.link}>
              <ListItem
                button
                selected={selectedIndex === index}
                key={category.key}
                onClick={(event) => {
                  handleListItemClick(event, index);
                  handleToggleCollapse(category.key);
                }}
              >
                <ListItemIcon style={selectedIndex === index ? { color: theme.palette.primary.contrastText, '&:hover': { color: 'inherit' } } : null}>{category.icon}</ListItemIcon>
                <ListItemText primary={i18n.t(category.label)} />
                {category.subcategories ? (
                  toggleMenus[category.key] ? (
                    <ExpandMore />
                  ) : (
                    <NavigateNext />
                  )
                ) : null}
              </ListItem>
            </Link>
            {category.subcategories
              && category.subcategories.map((subCategory) => (
                <Collapse
                  in={toggleMenus[category.key]}
                  timeout="auto"
                  unmountOnExit
                  key={subCategory.key}
                >
                  <List component="div" disablePadding>
                    <Link to={{ pathname: '/' + category.route + '/' + subCategory.route, state: { prevPath: subCategory.key } }} className={classes.link}>
                      <ListItem key={subCategory.key} button className={classes.nested}>
                        <ListItemIcon>{subCategory.icon}</ListItemIcon>
                        <ListItemText primary={subCategory.label} />
                      </ListItem>
                    </Link>
                  </List>

                </Collapse>
              ))}
          </React.Fragment>
        ))}
      </List>
      <Divider />
      {categories?.end && (
        <div className={classes.endList}>
          <List>
            {categories.end.map((category, index) => (
              <>
                <ListItem
                  key={`listItem${index}`}
                  button
                  onClick={handleClick}
                >
                  <ListItemIcon>{category.icon}</ListItemIcon>
                  <ListItemText primary={i18n.t(category.label)} />
                </ListItem>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem onClick={logout}>{i18n.t('LOGOUT')}</MenuItem>
                </Menu>
              </>
            ))}
          </List>
        </div>
      )}
    </Drawer>
  );
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(SideBar);
