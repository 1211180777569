import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import { setModalFormData } from '../../../redux/actions/modalActions';
import { setFormData } from '../../../redux/actions/globalActions';
import useStyles from './style';

function TextInput({ dispatch, formData, item, isModal, modalFormData, shrink }) {
  const classes = useStyles();
  const [inputShrink, setInputShrink] = useState(false);
  useEffect(() => {
    const value = isModal ? modalFormData[item.id] : formData[item.id];
    if (isModal) dispatch(setModalFormData({ id: item.id, value }));
    else dispatch(setFormData({ id: item.id, value }));
    if (value || value === 0) {
      setInputShrink(true);
    } else {
      setInputShrink(false);
    }
    if (item.id === 'numero' && value !== '') {
      setInputShrink(true);
    }
  }, [isModal ? modalFormData[item.id] : formData[item.id]]);
  return (
    <TextField
      {...item.textFieldProps}
      value={isModal ? modalFormData[item.id] : formData[item.id]}
      InputLabelProps={{
        shrink: shrink || inputShrink,
        style: { fontWeight: 'bold' },
      }}
      id={item.id}
      select={false}
      margin="dense"
      label={item.label}
      placeholder={item.placeholder}
      className={classes.textField}
      name={item.name}
      disabled={item?.textFieldProps?.disabled || false}
    />
  );
}

TextInput.propTypes = {
  item: PropTypes.shape({
    parent: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
    textFieldProps: PropTypes.shape({
      defaultValue: PropTypes.shape({
        name: PropTypes.string,
      }),
      disabled: PropTypes.bool,
    }),
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  isModal: PropTypes.bool,
  keys: PropTypes.shape({}),
  value: PropTypes.string.isRequired,
  parentValueID: PropTypes.number.isRequired,
  formData: PropTypes.shape({}).isRequired,
  modalFormData: PropTypes.shape({}).isRequired,
  shrink: PropTypes.bool,
};

TextInput.defaultProps = {
  isModal: false,
  keys: {},
  shrink: false,
};

function mapStateToProps({ globalReducer, modalReducer, keyReducer }) {
  return {
    formData: globalReducer.formData,
    modalFormData: modalReducer.modalFormData,
    keys: keyReducer.keys,
  };
}

export default connect(mapStateToProps)(TextInput);
