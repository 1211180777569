/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import { getBooking, getFiltersList, setFilterList, setFormData } from '../../../redux/actions/globalActions';
import { setKey } from '../../../redux/actions/keyActions';
import { emptyActualModal, setActualModal, setModalFormData } from '../../../redux/actions/modalActions';

function AutocompleteField({
  dispatch, options, formData, list, style, settedOrderNumber,
  item, error, isModal, modalFormData, newBookingOrderID,
}) {
  const [autValue, setAutValue] = useState();
  const parentID = item?.parent && item?.parent[0]?.id;
  const [autocompleteValue, setAutocompleteValue] = useState(
    isModal ? modalFormData[item?.id] : formData[item?.id],
  );
  const [keyValue, setKeyValue] = useState(`${item?.id}${formData[item?.id]?.id}`);
  const autref = useRef();
  const page = useLocation().pathname.split('/')[3];
  const tab = useLocation().pathname.split('/')[4];
  const documentID = useLocation().pathname.split('/')[5];
  const newDocumentID = 'new';
  const clientID = 'client_id';
  const transportTypeID = 'tipus_transport_id';
  const navieraID = 'naviliera_id';
  const infoControlledIDs = ['shipper_id', 'notify_id', 'destinatari_id'];
  const findByValue = ['inquiry_bl', 'inquiry_container', 'inquiry_booking', 'inquiry_reference', 'inquiry_dua'];
  const defVal = useState({ name: 'No', id: '0' });
  const modalObject = {
    name: '¿Eliminar Portic anterior?',
    form: {
      inputs: [
        {
          gridProps: { lg: 4 },
          id: 'cancel-cancel-portic',
          buttonProps: {
            label: 'Cancelar',
            onClick: () => {
              dispatch(setFormData({ id: 'naviliera_id', value: { ...autocompleteValue, id: -10, realValue: autocompleteValue } }));
              dispatch(emptyActualModal());
            },
          },
        },
        { space: true, gridProps: { lg: 4 } },
        {
          gridProps: { lg: 4 },
          id: 'accept-cancel-portic',
          buttonProps: {
            label: 'Aceptar',
            onClick: () => {
              dispatch(emptyActualModal());
            },
          },
        },
      ],
    },
  };
  useEffect(() => {
    if (item?.id === 'inquiry_ended') {
      dispatch(setFormData({ id: item?.id, value: defVal }));
      item.autocompleteProps.onChange(null, defVal);
      setAutValue(defVal);
    }
  }, []);
  function handleChangeTransportType(actualValue) {
    if (newBookingOrderID) {
      dispatch(getBooking(null, documentID, tab, `${newBookingOrderID}/${actualValue}`));
    } else if (settedOrderNumber) {
      dispatch(getBooking(null, documentID, tab, `${settedOrderNumber}/${actualValue}`));
    } else {
      dispatch(getBooking(null, documentID, tab, actualValue));
    }
  }
  useEffect(() => {
    if (isModal) {
      setAutocompleteValue(modalFormData[item?.id]);
      setKeyValue(`${item?.id}${modalFormData[item?.id]?.id}`);
    } else {
      setAutocompleteValue(formData[item?.id]);
      setKeyValue(`${item?.id}${formData[item?.id]?.id}`);
    }
    if (item?.id === 'tipus_transport_id' && item?.textFieldProps?.defaultValue?.id) {
      if (newBookingOrderID) {
        dispatch(getBooking(null, documentID, tab, `${newBookingOrderID}/${item?.textFieldProps?.defaultValue?.id}`));
      } else if (settedOrderNumber) {
        dispatch(getBooking(null, documentID, tab, `${settedOrderNumber}/${item?.textFieldProps?.defaultValue?.id}`));
      }
    }
  }, [isModal ? modalFormData[item?.id] : formData[item?.id]]);

  useEffect(() => {
    if (formData[item?.id] && item?.id === transportTypeID) {
      handleChangeTransportType(autocompleteValue.id);
    }
  }, []);
  function handleChangeClientID(actualValue) {
    dispatch(setFormData({ id: 'shipper_id', value: '' }));
    dispatch(setFormData({ id: 'notify_id', value: '' }));
    dispatch(setFormData({ id: 'destinatari_id', value: '' }));
    dispatch(setFormData({ id: 'direccio_client', value: actualValue?.address || '' }));
    dispatch(setFormData({ id: 'direccio_shipper', value: '' }));
    dispatch(setFormData({ id: 'direccio_notify', value: '' }));
    dispatch(setFormData({ id: 'direccio_destinatari', value: '' }));
    dispatch(setFilterList([], 'Shipper'));
    dispatch(setFilterList([], 'Consignee'));
    dispatch(setFilterList([], 'Notify'));
  }

  function handleChangeInfoParents(actualValue) {
    let textAreaID = null;
    switch (item?.id) {
      case 'shipper_id':
        textAreaID = 'direccio_shipper';
        break;
      case 'notify_id':
        textAreaID = 'direccio_notify';
        break;
      case 'destinatari_id':
        textAreaID = 'direccio_destinatari';
        break;
      default:
        break;
    }
    if (actualValue) {
      dispatch(setFormData({ id: textAreaID, value: actualValue?.address }));
    } else {
      dispatch(setFormData({ id: textAreaID, value: '' }));
    }
  }

  const handleGetOptionLabel = (value) => {
    if (value?.name) return value.name;
    return '';
  };

  const handleOnChange = (event, actualValue) => {
    if (item?.id === clientID && !isModal) {
      handleChangeClientID(actualValue);
    } else if (infoControlledIDs.includes(item?.id) && !isModal) {
      handleChangeInfoParents(actualValue);
    } else if (item?.id === transportTypeID && actualValue?.id) {
      handleChangeTransportType(actualValue.id);
    } else if (item?.id === navieraID && item.autocompleteProps?.openModal) {
      dispatch(setActualModal(modalObject));
    } else if (item?.autocompleteProps?.onChange) {
      item.autocompleteProps.onChange(event, actualValue);
    }

    if (isModal) {
      dispatch(setModalFormData({ id: item?.id, value: actualValue }));
    } else {
      if (item?.id === 'lc_destino' || item?.id === 'lc_destino_fake') {
        const val = actualValue?.id.slice(0, 2).toLowerCase();
        if (val === 'eg') {
          $('form div:nth-child(10)').removeClass('bhidden');
        } else {
          $('form div:nth-child(10)').addClass('bhidden');
        }
      }
      if (item?.id === 'client_id' && actualValue?.id === '4') {
        if (formData?.comanda_client_id) {
          $('form div:nth-child(19)').removeClass('bhidden');
        } else {
          $('form div:nth-child(18)').removeClass('bhidden');
        }
      } else if (newDocumentID !== documentID && page !== 'order') {
        $('form div:nth-child(18)').addClass('bhidden');
      } else if (page !== 'order') {
        $('form div:nth-child(19)').addClass('bhidden');
      }
      if (actualValue?.id === 'X') {
        $('main').removeClass('import export').addClass('crosstrade');
        $('main .makeStyles-paper-5').removeClass('import export').addClass('crosstrade');
      } else if (actualValue?.id === 'EXP') {
        $('main').removeClass('import crosstrade').addClass('export');
        $('main .makeStyles-paper-5').removeClass('import crosstrade').addClass('export');
      } else if (actualValue?.id === 'IMP') {
        $('main').removeClass('crosstrade export').addClass('import');
        $('main .makeStyles-paper-5').removeClass('crosstrade export').addClass('import');
      }
      dispatch(setFormData({ id: item?.id, value: actualValue }));
    }
    setAutValue(actualValue);
  };

  const handleFilterChange = (event, actualValue, reason) => {
    if (findByValue.some((inquiry) => item?.id === inquiry) && reason !== 'reset') {
      dispatch(setFormData({ id: item?.id, value: actualValue }));
    }
    if (reason === 'clear') {
      dispatch(setFormData({ id: item?.id, actualValue }));
      dispatch(setKey({ id: item?.id, label: item?.label, actualValue }));
    }

    let newURL = item?.autocompleteProps?.url;

    if (item?.parent?.length) {
      newURL += formData[parentID]?.id;
    } else {
      newURL += event?.target?.value;
    }

    if (event?.target?.value) {
      dispatch(getFiltersList(newURL, item?.label));
    }
  };

  const handleGetOnOpen = () => {
    if (item?.autocompleteProps?.all) {
      let newURL = item?.autocompleteProps?.url;
      if (parentID) {
        newURL += formData[parentID]?.id;
      }
      dispatch(getFiltersList(newURL, item?.label));
    }
  };

  return (
    <>
      {!item?.autocompleteProps?.value
        ? (
          <Autocomplete
            style={style}
            freeSolo
            id={item?.id}
            key={keyValue}
            options={options.length ? options : list || null}
            onChange={handleOnChange}
            onInputChange={options.length ? null : handleFilterChange}
            getOptionLabel={handleGetOptionLabel}
            fullWidth
            defaultValue={autocompleteValue}
            renderInput={(params) => (
              <TextField
                error={error}
                inputRef={autref}
                name={autValue && autValue.id}
                {...params}
                label={item?.label}
                InputLabelProps={{
                  style: { fontWeight: 'bold' },
                }}
              />
            )}
            onOpen={handleGetOnOpen}
          />
        )
        : (
          <Autocomplete
            style={style}
            freeSolo
            id={item?.id}
            key={keyValue}
            options={options.length ? options : list || null}
            onChange={handleOnChange}
            onInputChange={options.length ? null : handleFilterChange}
            getOptionLabel={handleGetOptionLabel}
            fullWidth
            value={item.autocompleteProps.value}
            renderInput={(params) => (
              <TextField
                error={error}
                inputRef={autref}
                name={autValue && autValue.id}
                {...params}
                label={item?.label}
              />
            )}
            onOpen={handleGetOnOpen}
          />
        )}
    </>
  );
}

AutocompleteField.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  dispatch: PropTypes.func.isRequired,
  url: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  style: PropTypes.shape({}),
  saveModal: PropTypes.bool,
  keys: PropTypes.shape({}),
  parenvValueID: PropTypes.number.isRequired,
  formData: PropTypes.shape({}).isRequired,
  error: PropTypes.bool,
  isModal: PropTypes.bool,
  newBookingOrderID: PropTypes.string.isRequired,
};

AutocompleteField.defaultProps = {
  list: [],
  options: [],
  url: '',
  style: {},
  saveModal: true,
  keys: {},
  error: false,
  isModal: false,
};

function mapStateToProps({ globalReducer, modalReducer, keyReducer }, ownProps) {
  return {
    formData: globalReducer.formData,
    newBookingOrderID: globalReducer.newBookingOrderID,
    modalFormData: modalReducer.modalFormData,
    list: globalReducer.filterList[ownProps.item?.label],
    keys: keyReducer.keys,
    settedOrderNumber: globalReducer.settedOrderNumber,
  };
}

export default connect(mapStateToProps)(AutocompleteField);
