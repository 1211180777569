import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Typography, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useStyles from './style';
import BasicModal from '../BasicModal';
import AutocompleteField from '../AutocompleteField';
import { loadError } from '../../../redux/actions/globalActions';
import { setFiles, saveDocumentationFiles } from '../../../redux/actions/filesActions';
import { emptyActualModal, setActualModal } from '../../../redux/actions/modalActions';

function FileUploader({ dispatch, files, formData, label, component }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const haveType = files.map((file, index) => {
      if (!formData[`document_tipo-${index}`]) {
        return { ...file, error: true };
      }
      return { ...file, error: false };
    });
    dispatch(setFiles(haveType));
  }, [formData]);

  function handleDeleteFile(file) {
    const newDocuments = files;
    newDocuments.splice(file, 1);

    dispatch(setFiles([...newDocuments]));
  }

  function handleSaveFiles() {
    const newFiles = files.map((file, index) => ({ ...file, type: formData[`document_tipo-${index}`] && formData[`document_tipo-${index}`].id }));
    const haveType = newFiles.map((file) => {
      if (!file.type) return { ...file, error: true };
      return { ...file, error: false };
    });
    if (!haveType.find((file) => file.error)) {
      dispatch(setActualModal({ flag: true }));
      dispatch(saveDocumentationFiles({
        link: component?.buttonProps?.requestModalLink,
        files: newFiles,
        form: formData,
      }));
      setOpen(false);
      dispatch(emptyActualModal());
    } else {
      dispatch(loadError({ message: 'Falta añadir el tipo de archivo' }));
      dispatch(setFiles(haveType));
    }
  }

  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
    }));

    const newFilesObjects = newFiles.map((file) => ({ type: 'none', file, error: false }));
    dispatch(setFiles([...files, ...newFilesObjects]));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <Button color="primary" variant="contained" onClick={() => setOpen(true)}>
        {label}
      </Button>
      <BasicModal
        open={open}
        closeFunction={
          () => { setOpen(false); dispatch(emptyActualModal()); }
        }
      >
        <div className={classes.container}>

          <div className={classes.dropZone} {...getRootProps()}>
            <input {...getInputProps()} />
            {
            isDragActive
              ? <p>Drop the files here ...</p>
              : <p>Arrastra algunos archivos o haz click para seleccionar archivos</p>
              // : <p>Drag drop some files here, or click to select files</p>
          }
          </div>

          <div>
            {files && files.map((file, index) => (
              <div className={classes.selectHolders}>
                <AutocompleteField
                  style={{ width: '45%' }}
                  item={{ ...component, id: `document_tipo-${index}`, label: 'Tipo documento', autocompleteProps: { url: 'documents/types/search/list/', all: true } }}
                  placeholder="Tipo de documento"
                  error={file?.error}
                />
                <Typography noWrap>{file.file.name}</Typography>
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => handleDeleteFile(index)} style={{ padding: 0 }}>
                  <Close />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
        <Button color="primary" variant="contained" onClick={handleSaveFiles}>
          Guardar
        </Button>
      </BasicModal>

    </>
  );
}

FileUploader.propTypes = {
  label: PropTypes.string,
  component: PropTypes.shape({
    buttonProps: PropTypes.shape({
      requestModalLink: PropTypes.string,
    }),
    field_modal: PropTypes.shape({
      link: PropTypes.string,
      modal_inputs: PropTypes.arrayOf({
        field_name: PropTypes.string,
      }),
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  formData: PropTypes.shape({}).isRequired,
  modalFormData: PropTypes.shape({}).isRequired,
  actualModal: PropTypes.shape({}).isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

FileUploader.defaultProps = {
  label: 'ADD',
};

function mapStateToProps({ globalReducer, modalReducer, filesReducer }) {
  return {
    formData: globalReducer.formData,
    actualModal: modalReducer.actualModal,
    files: filesReducer.files,
  };
}

export default connect(mapStateToProps)(FileUploader);
