import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import $ from 'jquery';
import { Tabs, Tab } from '@material-ui/core';
import Global from '../../../Contexts/Global';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function getActualTab(tabs, tabName) {
  let actualTab = null;
  if (typeof tabs === 'object' && tabs.length) {
    tabs.forEach((tab, index) => {
      if (tab.link === tabName) {
        actualTab = index;
      }
    });
  }
  return actualTab;
}

const TabsNavigation = ({ tabs, type }) => {
  const history = useHistory();
  const path = useLocation().pathname.split('/');
  const location = path[4];
  const referenceID = path[5];
  const { i18n } = useContext(Global);
  const [value, setValue] = useState(getActualTab(tabs, location));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleTab({ tab, bookingNumber, actualType }) {
    if (bookingNumber) {
      history.push(`/inquiries/inquiries-butransa/${actualType}/${tab.link}/${bookingNumber}`);
    } else {
      history.push(`/inquiries/inquiries-butransa/${actualType}/${tab.link}`);
    }
  }

  setTimeout(() => {
    if (type === 'booking' || type === 'order') {
      $('label').addClass('bold');
      $('input').addClass('highlight');
      $('textarea').addClass('highlight');
      $('.MuiTab-wrapper').addClass('bold');
      $('.MuiFormLabel-root').addClass('bold');
    }
  }, 500);

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
      aria-label="full width tabs example"
    >
      {tabs
        && tabs.map((tab, index) => (
          <Tab
            label={i18n.t(tab.label)}
            key={tab.label}
            {...a11yProps(index)}
            onClick={() => handleTab({ tab, bookingNumber: referenceID, actualType: type })}
          />
        ))}
    </Tabs>
  );
};

TabsNavigation.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
};

TabsNavigation.defaultProps = {
  tabs: [],
  type: '',
};

export default TabsNavigation;
