import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  startHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
  },
  endHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '50%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logoContainer: {
    flexGrow: 1,
  },
  logo: {
    width: '100px',
    marginRight: '90px',
    cursor: 'pointer',
  },
  link: {
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
  unlink: {
    display: 'flex',
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
  icon: {
    paddingRight: '5px',
    color: 'gray',
  },
}));

export default useStyles;
