/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import $ from 'jquery';
import TabsNavigation from '../../Components/Generic/TabsNavigation';
import Incidents from '../../Components/Specific/Incidents';
import TransportOrders from '../../Components/Specific/TransportOrders';
import Documents from '../../Components/Specific/Documents';
import TransportType from '../../Components/Specific/TransportType';
import ClientsOrders from '../../Components/Specific/ClientsOrders';
import BookingInformation from '../../Components/Specific/BookingInformation';
import BLS from '../../Components/Specific/BLS';
import { emptyFormData, getBooking, setFormData, setNewBookingOrderID, setSettedOrderNumber as setOrderNumberAction, getBookingType } from '../../redux/actions/globalActions';
import { emptyModalFormData } from '../../redux/actions/modalActions';
import BasicModal from '../../Components/Generic/BasicModal';
import Form from '../../Components/Generic/Form';
import Table from '../../Components/Generic/Table';
import { getTable } from '../../redux/actions/tableActions';

const tabs = [
  { route: 'R_INFORMATION', key: 'INFORMATION', label: 'INFORMATION', link: 'information' },
  { route: 'R_TRANSPORT', key: 'TRANSPORT', label: 'TRANSPORT', link: 'transport' },
  { route: 'R_CLIENTS_ORDERS', key: 'CLIENTS_ORDERS', label: 'CLIENTS_ORDERS', link: 'clients-orders' },
  { route: 'R_BLS', key: 'BLS', label: "BL'S", link: 'bls' },
  { route: 'R_DOCUMENTATION', key: 'DOCUMENTATION', label: 'DOCUMENTATION', link: 'documentation' },
  { route: 'R_DELIVERY_ORDERS', key: 'DELIVERY_ORDERS', label: 'DELIVERY_ORDERS', link: 'delivery-orders' },
  { route: 'R_INCIDENCE', key: 'INCIDENCE', label: 'INCIDENCES', link: 'incidences' },
  { route: 'R_TRANSSHIPMENTS', key: 'TRANSSHIPMENTS', label: 'TRANSSHIPMENTS', link: 'transshipments' },
];

const newBookingTabs = [
  { route: 'R_INFORMATION', key: 'INFORMATION', label: 'INFORMATION', link: 'information' },
  { route: 'R_TRANSPORT', key: 'TRANSPORT', label: 'TRANSPORT', link: 'transport' },
];

function getColumnName(column) {
  let columnName = column;
  const columnNames = {
    'p.c': 'comandes_clients.numero',
    fecha: 'comandes_clients.data',
    cliente: 'clients.nom',
    referència: 'comandes_clients.referencia',
    referencia: 'comandes_clients.referencia',
    destino: 'comandes_clients.codi_desti',
    mercancía: 'comandes_clients.descripcio_mercaderia',
    bultos: 'comandes_clients.paquets_mercaderia',
    'kgs.': 'comandes_clients.pes_mercaderia',
  };
  if (columnNames[columnName?.toLowerCase()]) {
    columnName = columnNames[columnName?.toLowerCase()];
  }
  return columnName;
}

function Singlebooking({ dispatch, actualBooking, actualModal, reduxTable, newBookingOrderID, modalFormData, actualBookingType }) {
  const [booking, setBooking] = useState(actualBooking);
  const history = useHistory();
  const type = useLocation().pathname.split('/')[3];
  const locationState = useLocation().state;
  const [settedOrderNumber, setSettedOrderNumber] = useState(null);
  const location = useLocation().pathname.split('/')[4];
  const bookingNumber = useLocation().pathname.split('/')[5];
  const refreshTabs = ['documentation', 'delivery-orders', 'incidences', 'bls', 'transshipments', 'clients-orders', 'transport'];
  const newBookingKey = 'new';
  const [openModal, setOpenModal] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');
  const transportDefaultID = '30';
  const transportTab = 'transport';
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  useEffect(() => {
    if (actualBookingType?.form?.inputs[27]?.textFieldProps?.defaultValue === 'IMP') {
      $('main').removeClass('crosstrade export').addClass('import');
      $('main .makeStyles-paper-5').removeClass('crosstrade export').addClass('import');
    } else if (actualBookingType?.form?.inputs[27]?.textFieldProps?.defaultValue === 'EXP') {
      $('main').removeClass('import crosstrade').addClass('export');
      $('main .makeStyles-paper-5').removeClass('import crosstrade').addClass('export');
    } else if (actualBookingType?.form?.inputs[27]?.textFieldProps?.defaultValue === 'X') {
      $('main').removeClass('import export').addClass('crosstrade');
      $('main .makeStyles-paper-5').removeClass('import export').addClass('crosstrade');
    }
  }, [actualBooking, actualBookingType]);
  const selectModalButton = {
    buttonProps: {
      buttonLink: 'clients-orders/update/client-order-contacts/16332/receiver',
      buttonMethod: 'PUT',
      getsModalForm: true,
      icon: undefined,
      label: 'Guardar',
      onClick: (modalForm) => {
        dispatch(getBooking(actualModal?.name, bookingNumber, location, null, modalForm.table_selection?.itemID));
        dispatch(setNewBookingOrderID(modalForm.table_selection?.itemID));
        setOpenModal(false);
      },
      requestModalLink: undefined,
    },
    gridProps: { lg: 2 },
    id: 'select_order_booking',
  };
  const form = {
    grid: { spacing: 3 },
    inputs: [
      { space: true, gridProps: { lg: 10 } },
      selectModalButton,
    ],
    newBookingOrderID,
  };

  function getActualTab(actualLocation) {
    let tabComponent = null;

    switch (actualLocation) {
      case 'information':
        tabComponent = <BookingInformation detail={booking} setDetail={setBooking} />;
        break;
      case 'transport':
        tabComponent = <TransportType detail={booking} setDetail={setBooking} />;
        break;
      case 'clients-orders':
        tabComponent = <ClientsOrders detail={booking} setDetail={setBooking} />;
        break;
      case 'bls':
        tabComponent = <BLS detail={booking} setDetail={setBooking} />;
        break;
      case 'documentation':
        tabComponent = <Documents detail={booking} setDetail={setBooking} />;
        break;
      case 'delivery-orders':
        tabComponent = <TransportOrders detail={booking} setDetail={setBooking} />;
        break;
      case 'incidences':
        tabComponent = <Incidents detail={booking} setDetail={setBooking} />;
        break;
      case 'transshipments':
        tabComponent = <Incidents detail={booking} setDetail={setBooking} />;
        break;
      default:
        tabComponent = <Documents detail={booking} setDetail={setBooking} />;
        break;
    }

    return tabComponent;
  }

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';
    const productsOrder = isAsc ? 'desc' : 'asc';
    let queryIndex = null;
    let url = null;

    if (reduxTable) {
      queryIndex = reduxTable?.links.first.href.indexOf('?');
      url = reduxTable?.links.first.href.slice(0, queryIndex);
    } else {
      queryIndex = actualModal?.links.first.href.indexOf('?');
      url = actualModal?.links.first.href.slice(0, queryIndex);
    }

    if (isDesc) {
      setOrderBy(false);
    } else {
      setOrderBy(column);
    }

    setOrderProducts(isAsc ? 'desc' : 'asc');
    dispatch(getTable({ url, page, perPage: rowsPerPage, order: productsOrder, orderBy: getColumnName(column) }));
  }

  const cancelModal = () => {
    setOpenModal(false);
    history.push('/inquiries/inquiries-butransa');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    let queryIndex = null;
    let url = null;
    if (reduxTable) {
      queryIndex = reduxTable?.links.first.href.indexOf('?');
      url = reduxTable?.links.first.href.slice(0, queryIndex);
    } else {
      queryIndex = actualModal?.links.first.href.indexOf('?');
      url = actualModal?.links.first.href.slice(0, queryIndex);
    }
    dispatch(getTable({ url, page: newPage + 1, perPage: rowsPerPage, order: orderProducts, orderBy }));
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPage);
    const queryIndex = reduxTable?.links.first.href.indexOf('?');
    const url = reduxTable?.links.first.href.slice(0, queryIndex) + '/';
    dispatch(getTable({ url, page, perPage: rowsPage, order: orderProducts, orderBy }));
  };

  useEffect(() => {
    if ((bookingNumber === newBookingKey) && (!locationState?.orderNumber)) {
      setOpenModal(true);
    } else if (locationState?.orderNumber) {
      setSettedOrderNumber(locationState.orderNumber);
      dispatch(setOrderNumberAction(locationState.orderNumber));
      dispatch(getBooking(actualModal?.name, 'new', 'information', null, locationState.orderNumber));
    }
  }, []);

  useEffect(() => {
    if (bookingNumber === newBookingKey && !reduxTable) {
      dispatch(getTable({ url: '/bookings/client-orders' }));
    }
  }, []);

  useEffect(() => {
    dispatch(getBookingType(bookingNumber));
    if (bookingNumber && (bookingNumber !== newBookingKey) && location && !settedOrderNumber) {
      dispatch(getBooking(actualModal?.name, bookingNumber, location));
    } else if (bookingNumber === newBookingKey && newBookingOrderID && location && !settedOrderNumber) {
      let orderID = newBookingOrderID;
      if (location === transportTab && modalFormData.tipus_transport_id) {
        orderID += `/${modalFormData.tipus_transport_id.id}`;
      }
      dispatch(getBooking(actualModal?.name, bookingNumber, location, null, orderID));
    }
    if (settedOrderNumber) {
      dispatch(getBooking(actualModal?.name, 'new', location, null, settedOrderNumber));
    }
  }, [location]);

  useEffect(() => {
    if (refreshTabs.includes(location)) {
      dispatch(getBooking(actualModal?.name, bookingNumber, location, null, newBookingOrderID));
    }
  }, [actualModal]);

  useEffect(() => {
    setBooking(actualBooking);
  }, [actualBooking]);

  useEffect(() => {
    dispatch(emptyFormData());
    dispatch(emptyModalFormData());
    dispatch(setFormData({ id: 'validated', value: false }));
    if (bookingNumber === newBookingKey) {
      const dateKey = 'data';
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const fullDate = `${year}-${month}-${day}`;
      dispatch(setFormData({ id: dateKey, value: fullDate }));
    }
  }, []);

  return (
    <Paper>
      <TabsNavigation tabs={bookingNumber === newBookingKey ? newBookingTabs : tabs} type={type} />
      {getActualTab(location)}
      <BasicModal open={openModal} closeFunction={cancelModal} title="Seleccionar pedido de cliente">
        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          <Form newForm={form} isModal />
        </div>
        {Boolean(reduxTable)
        && (
        <Table
          tableData={reduxTable.table}
          maxHeight="57.4vh"
          orderBy={orderBy}
          orderProducts={orderProducts}
          onOrderProducts={handleRequestSort}
          selectItems
          isModal
          pageObject={{ page, handleChangePage }}
          rowsPerPageObject={{ rowsPerPage, handleChangeRowsPerPage }}
          pageCount={reduxTable?.meta?.totalCount || actualModal?.meta?.totalCount || 0}
          withPaginate
        />
        )}
      </BasicModal>
    </Paper>
  );
}

Singlebooking.propTypes = {
  dispatch: PropTypes.func,
  actualBooking: PropTypes.shape({
    id: PropTypes.string,
    form: PropTypes.shape({
      inputs: PropTypes.shape([]),
    }),
  }),
  actualModal: PropTypes.shape({
    name: PropTypes.string,
    meta: PropTypes.shape({
      totalCount: PropTypes.number,
    }),
    links: PropTypes.shape({
      first: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
  }).isRequired,
  reduxTable: PropTypes.shape({
    table: PropTypes.shape({}),
    meta: PropTypes.shape({
      totalCount: PropTypes.number,
    }),
    links: PropTypes.shape({
      first: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
  }).isRequired,
  newBookingOrderID: PropTypes.string,
  modalFormData: PropTypes.shape({
    tipus_transport_id: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  actualBookingType: PropTypes.shape({
    form: PropTypes.shape({
      inputs: PropTypes.shape([]),
    }),
  }),
};

Singlebooking.defaultProps = {
  dispatch: () => {},
  actualBooking: {},
  actualBookingType: {},
  newBookingOrderID: '',
};

function mapStateToProps({ globalReducer, modalReducer, tableReducer }) {
  return {
    actualBooking: globalReducer.actualBooking,
    newBookingOrderID: globalReducer.newBookingOrderID,
    actualModal: modalReducer.actualModal,
    modalFormData: modalReducer.modalFormData,
    reduxTable: tableReducer.table,
    actualBookingType: globalReducer.actualBookingType,
  };
}

export default connect(mapStateToProps)(Singlebooking);
