import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './style';
import BasicModal from '../../Generic/BasicModal';
import Form from '../../Generic/Form';

function FormModal({ open, form, title, setCloseModal }) {
  const classes = useStyles();

  return (
    <BasicModal open={open} closeFunction={setCloseModal} title={title}>
      <div className={classes.test}>
        <Form newForm={form} />
      </div>
    </BasicModal>

  );
}

FormModal.defaultProps = {
  open: PropTypes.bool,
  form: PropTypes.shape({}),
  title: PropTypes.string,
  setCloseModal: PropTypes.func,
};
FormModal.propTypes = {
  open: PropTypes.bool,
  form: PropTypes.shape({}),
  title: PropTypes.string,
  setCloseModal: PropTypes.func,

};

export default FormModal;
