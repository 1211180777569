/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import actionModalTypes from './actionModalTypes';
import apiClient from '../../domain/fetchers/apiClient';
import { setFormData } from './globalActions';
import { getTable, setTable } from './tableActions';

function loadError(error) {
  return {
    type: actionModalTypes.LOAD_ERROR,
    data: error,
  };
}

export function capitalizeFirstLetter(string) {
  const newString = string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
  return newString;
}

export function setModalFormData({ id, value }) {
  return {
    type: actionModalTypes.SET_MODAL_FORM_DATA,
    data: { id, value },
  };
}

export function emptyModalFormData() {
  return {
    type: actionModalTypes.EMPTY_MODAL_FORM_DATA,
    data: {},
  };
}

export function emptyActualModal() {
  return {
    type: actionModalTypes.EMPTY_ACTUAL_MODAL,
  };
}

export function setActualModal(modal) {
  return {
    type: actionModalTypes.SET_ACTUAL_MODAL,
    data: modal,
  };
}

export function buildModalTable(table) {
  const newTable = {};
  if (table && table?.headers && table?.items) {
    newTable.titles = table.headers.map((header) => (
      {
        align: 'center',
        title: header.name,
      }
    ));

    newTable.rows = table.items.map((item) => {
      const rowValues = [];
      let itemID = null;
      let selectedItem = false;

      Object.values(item.item).forEach((value) => {
        if (!value.item_id && value.field_name !== 'selected') {
          let newValue = null;
          if (value.field_type === 'checkbox') {
            newValue = { checkbox: true, value };
          } else if (value.field_value instanceof Object) {
            newValue = value.field_value.name || '';
          } else {
            newValue = value.field_value || '';
          }
          rowValues.push(newValue);
        } else if (value.field_name === 'selected' && value.field_value === 'si') {
          selectedItem = true;
        } else if (value.item_id !== undefined && value.item_id !== 'undefined') {
          itemID = value.item_id;
        }
      });
      const newItem = {};

      newTable.titles.forEach((title, index) => {
        newItem[newTable.titles[index].title] = rowValues[index];
      });
      if (item.actions) {
        newItem.actions = { actions: item.actions.map((action) => ({ title: capitalizeFirstLetter(action?.name), icon: action?.icon, function: () => {} })) };
      }

      newItem.itemID = itemID;
      const rowItem = { row: newItem, selected: selectedItem };
      return rowItem;
    });

    if (newTable.rows[0] && newTable.rows[0].actions) {
      newTable.titles.push({ align: 'center', title: '' });
    }
  }
  return newTable;
}

function buildModalInput(input, dispatch) {
  let inputObject = {};
  switch (input.field_type) {
    case 'input':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { fullWidth: true, defaultValue: input.field_value },
        id: input.field_name,
      };
      break;
    case 'label':
      inputObject = {
        textFieldProps: { fullWidth: true, defaultValue: input.field_name.name, type: 'label' },
        labelProps: { backgroundColor: input.field_name['background-color'], textColor: input.field_name.color },
        id: input.field_name.name,
      };
      break;
    case 'date':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { fullWidth: true, defaultValue: input.field_value, InputLabelProps: { shrink: true }, type: 'date' },
        id: input.field_name,
      };
      break;
    case 'select':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { fullWidth: true, defaultValue: input.field_value, required: true, InputLabelProps: { shrink: true }, select: true },
        selectOptions: input.field_select || null,
        autocompleteProps: { url: input.field_action?.link, queryValue: input.field_name },
        id: input.field_name,
      };
      break;
    case 'textArea': {
      let val = input?.field_value;
      if (typeof input?.field_value === 'string') {
        val = val.replace(/<br\/>/gi, () => '\n');
      }
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { multiline: true, rows: 6, fullWidth: true, defaultValue: val },
        id: input.field_name,
      };
      break;
    }
    case 'time':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { fullWidth: true, defaultValue: input.field_value, InputLabelProps: { shrink: true }, type: 'time' },
        id: input.field_name,
      };
      break;
    case 'checkbox':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        textFieldProps: { defaultValue: input.field_value, InputLabelProps: { shrink: false }, type: 'checkbox' },
        id: input.field_name,
      };
      break;
    case 'space':
      inputObject = {
        space: true,
        id: Math.random() ** Math.random(),
      };
      break;
    case 'button':
      inputObject = {
        buttonProps: {
          label: capitalizeFirstLetter(input.field_label),
          icon: input.field_modal?.icon,
          requestModalLink: input.field_modal?.link,
          requestModalMethod: input.field_modal?.method,
          buttonLink: input.link,
          buttonMethod: input.method,
        },
        id: input.field_label,
      };
      break;
    case 'multiselect':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: { fullWidth: true, defaultValue: input.field_value, required: true, InputLabelProps: { shrink: true }, select: true },
        multiselectOptions: input.field_select,
        id: input.field_name,
      };
      break;
    case 'hidden':
      inputObject = {
        hidden: true,
        label: '',
        textFieldProps: { fullWidth: true, defaultValue: input.field_value, type: 'hidden' },
        id: input.field_name,
      };
      break;
    case 'search':
      inputObject = {
        label: capitalizeFirstLetter(input.field_label),
        placeholder: capitalizeFirstLetter(input.field_label),
        textFieldProps: {
          fullWidth: true,
          defaultValue: input.field_value,
          required: true,
          onChange: (event) => dispatch(getTable({ url: input.field_action?.link, query: event.target.value })),
        },
        id: input.field_name,
        searchProps: { url: input.field_action?.link, queryValue: input.field_name },
      };
      break;
    default:
      break;
  }

  inputObject.gridProps = !inputObject.hidden ? input.field_width || { lg: 3 } : { lg: 0 };
  if (input.field_disabled) {
    inputObject.textFieldProps.disabled = true;
  }

  if (inputObject.buttonProps) {
    if (inputObject.buttonProps.buttonLink && inputObject.buttonProps.buttonMethod) {
      inputObject.buttonProps.onClick = () => console.log(`link: ${inputObject.buttonProps.buttonLink}, method: ${inputObject.buttonProps.buttonMethod}`);
    }
  }

  return inputObject;
}

export function saveFinalModalForm({ data, link, id, method }) {
  return async (dispatch) => {
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const filteredData = {};
    const endpoint = (link && link[link.length - 1] === '/') ? `${link}${id}` : link;
    const postMethods = ['POST', 'post', 'PUT', 'put'];

    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] instanceof Array) {
          filteredData[key] = data[key].map((element) => {
            if (element instanceof Object) {
              return element.id;
            }
            return element;
          });
        } else if (data[key] instanceof Object) {
          filteredData[key] = data[key].id || data[key].value;
        } else if (data[key] || data[key] === '' || data[key] === 0) {
          filteredData[key] = data[key];
        }
      });
    }
    try {
      let response = null;
      if (postMethods.includes(method)) {
        response = await apiClient[method.toLowerCase()](endpoint, filteredData, config);
      } else {
        response = await apiClient[method.toLowerCase()](endpoint, config);
      }

      if (response.status === 200 || response.status === 204) {
        if (!response.data.success) {
          dispatch(loadError(response.data));
        } else {
          dispatch(loadError(response.data));
          dispatch(emptyModalFormData());
          dispatch(emptyActualModal());
        }
      }
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

export function buildFinalModal(modal, dispatch) {
  const actualModal = { ...modal };

  if (modal.table) {
    actualModal.table = buildModalTable(modal.table);
    const reduxTable = {
      table: actualModal.table,
      links: modal.links,
      meta: modal.meta,
    };
    dispatch(setTable(reduxTable));
  }
  if (modal.inputs && modal.inputs instanceof Array && modal.inputs.length) {
    actualModal.form = {};
    actualModal.form.inputs = modal.inputs.map((input) => {
      const inputObject = buildModalInput(input, dispatch);
      if (input.link && input.method) {
        inputObject.buttonProps.getsModalForm = true;
        inputObject.buttonProps.onClick = (modalForm) => {
          const detailID = window.location.pathname.split('/')[6];
          const modalObject = { data: modalForm, link: inputObject.buttonProps.buttonLink, method: inputObject.buttonProps.buttonMethod, id: detailID };
          dispatch(saveFinalModalForm(modalObject));
        };
      } else if (input.field_type === 'button') {
        inputObject.buttonProps.getsModalForm = true;
        inputObject.buttonProps.onClick = () => {};
      }

      return inputObject;
    });
  }

  if (modal.modal_message) {
    actualModal.message = modal.modal_message;
  }

  dispatch(setActualModal(actualModal));
}

export function saveModalForm({ data, link, id, method, recharge }) {
  return async (dispatch) => {
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const filteredData = {};
    const endpoint = (link && link[link.length - 1] === '/') ? `${link}${id}` : link;
    const postMethods = ['POST', 'post', 'PUT', 'put'];

    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] instanceof Array) {
          filteredData[key] = data[key].map((element) => {
            if (element instanceof Object) {
              return element.id;
            }
            return element;
          });
        } else if (data[key] instanceof Object) {
          filteredData[key] = data[key].id || data[key].value || data[key].itemID;
        } else if (data[key] || data[key] === '' || data[key] === 0) {
          filteredData[key] = data[key];
        }
      });
    }

    try {
      let response = null;
      if (postMethods.includes(method)) {
        response = await apiClient[method.toLowerCase()](endpoint, filteredData, config);
      } else {
        response = await apiClient[method.toLowerCase()](endpoint, config);
      }

      if (response.status === 200 || response.status === 204) {
        if (!response.data.success) {
          dispatch(loadError(response.data));
          if (response.data.validate_container) {
            const bypassObject = { id: 'bypass_container_validation', value: true };
            dispatch(setModalFormData(bypassObject));
          }
        } else {
          if (recharge) {
            location.reload();
          }
          if (response.data.exp_number) {
            const expedientObject = {
              id: 'expedient',
              value: response.data.exp_number,
            };
            dispatch(setFormData(expedientObject));
          }
          dispatch(loadError(response.data));
          dispatch(emptyModalFormData());
          dispatch(emptyActualModal());
          if (response.data.inputs || response.data.table) {
            const modalObject = {
              name: response.data.name || response.data.modal_name,
              inputs: response.data.inputs || response.data.modal_inputs,
              table: response.data.table || response.data.modal_table,
              message: response.data.message || response.data.modal_message,
            };
            buildFinalModal(modalObject, dispatch);
          }
        }
      }
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}

export async function getFinalModal(modal, dispatch) {
  const { method, link, modal_name, modal_data } = modal;
  let response = null;
  const token = localStorage.getItem('accessToken');
  const config = { headers: { Authorization: `Bearer ${token}` } };

  try {
    switch (method) {
      case 'GET':
        response = await apiClient.get(link, config);
        break;
      case 'POST':
        response = await apiClient.post(link, modal_data, config);
        break;
      case 'PUT':
        response = await apiClient.put(link, modal_data, config);
        break;
      case 'DELETE':
        response = await apiClient.delete(link, config);
        break;
      case 'PATCH':
        response = await apiClient.patch(link, config);
        break;
      default:
        response = await apiClient.get(link, config);
        break;
    }

    const { data } = response;

    const modalObject = {
      name: modal_name || data.modal_name,
      inputs: data.inputs,
      table: data.table,
      meta: data._meta,
      links: data._links,
      multiselect: modal.multiselect,
    };

    buildFinalModal(modalObject, dispatch);
  } catch (error) {
    dispatch(loadError(error));
  }
}

function handleIMOSelection({ modalForm, dispatch }) {
  if (modalForm?.table_selection) {
    dispatch(setFormData({ id: 'clase_imo', value: modalForm.table_selection.Clase }));
    dispatch(setFormData({ id: 'grup_imo', value: modalForm.table_selection.Grupo }));
    dispatch(setFormData({ id: 'pagina_imo', value: modalForm.table_selection.Página }));
    dispatch(setFormData({ id: 'codi_imo', value: modalForm.table_selection.Código }));
    dispatch(setFormData({ id: 'tipus_imo_mercaderia', value: modalForm.table_selection.Descripción }));
    dispatch(setFormData({ id: 'imo_id', value: modalForm.table_selection.itemID }));
  }
}

function handleChangeStopOver({ modalForm, dispatch }) {
  if (modalForm.table_selection) {
    dispatch(setFormData({ id: 'escala', value: modalForm.table_selection.Escala }));
    dispatch(setFormData({ id: 'moll', value: modalForm.table_selection.Muelle }));
    dispatch(setFormData({ id: 'lloyd', value: modalForm.table_selection.Lloyd }));
    dispatch(setFormData({ id: 'bandera', value: modalForm.table_selection.Bandera }));
    dispatch(setFormData({ id: 'vaixell', value: modalForm.table_selection.Barco }));
  }
}

function handleChangeShip({ modalForm, dispatch }) {
  if (modalForm.table_selection) {
    dispatch(setFormData({ id: 'vaixell', value: modalForm.table_selection.Nombre }));
    dispatch(setFormData({ id: 'escala', value: '' }));
    dispatch(setFormData({ id: 'moll', value: '' }));
    dispatch(setFormData({ id: 'lloyd', value: '' }));
    dispatch(setFormData({ id: 'bandera', value: '' }));
  }
}

const imoButtonID = 'select_client_order_imo';
const pickPointOriginID = 'select_pick_point_origin';
const pickPointDestinyID = 'select_pick_point_destiny';
const selectBookingScale = 'select_booking_scale';
const selectBookingShip = 'select_booking_ship';
const newBl = 'bl_button';
const createBl = 'create_bl_button';
const cancelID = 'cancel';
const selectClientContainer = 'edit_container_button';
const numerateExpedient = 'Numerar expediente';
function handlePickTransportSelection({ modalForm, dispatch, id }) {
  if (modalForm?.table_selection) {
    if (id === pickPointOriginID) {
      dispatch(setFormData({ id: 'origen_dades', value: modalForm.table_selection.Dirección }));
    } else if (id === pickPointDestinyID) {
      dispatch(setFormData({ id: 'desti_dades', value: modalForm.table_selection.Dirección }));
    }
  }
}

export function buildModal(modal, dispatch) {
  const fields = ['numero', 'tipus_bl_portic', 'desti_final', 'informacio_shipper', 'informacio_destinatari', 'informacio_notify', 'descripcio_mercaderia', 'observacions', 'tipus_transport_id', 'flete'];
  const actualModal = { ...modal };
  if (modal.table) {
    actualModal.table = buildModalTable(modal.table);
    const reduxTable = {
      table: actualModal.table,
      links: modal.links,
      meta: modal.meta,
    };
    dispatch(setTable(reduxTable));
  }
  if (modal.inputs && modal.inputs instanceof Array && modal.inputs?.length) {
    actualModal.form = {};
    actualModal.form.inputs = modal.inputs.map((input) => {
      const inputObject = buildModalInput(input, dispatch);
      if (input?.field_name && fields.includes(input.field_name)) {
        inputObject.gridProps = { lg: 6 };
        inputObject.textFieldProps = { ...inputObject.textFieldProps, rows: 6 };
      }
      if (input.link && input.method) {
        inputObject.buttonProps.getsModalForm = true;
        if (input.field_label === createBl) {
          inputObject.buttonProps.onClick = (modalForm) => {
            const detailID = window.location.pathname.split('/')[6];
            const modalObject = { data: { ...modalForm }, link: inputObject.buttonProps.buttonLink, method: inputObject.buttonProps.buttonMethod, id: detailID };
            dispatch(saveModalForm(modalObject));
          };
        } else if (input.field_name === selectClientContainer) {
          inputObject.buttonProps.onClick = (modalForm) => {
            let link = inputObject.buttonProps.buttonLink;
            if (link && link[link.length - 1] === '/') {
              link = link.slice(0, -1);
            }
            const modalObject = { data: modalForm, link, method: 'POST' };
            dispatch(saveModalForm(modalObject));
          };
        } else if (modal.recharge) {
          inputObject.buttonProps.onClick = (modalForm) => {
            const detailID = window.location.pathname.split('/')[6];
            const modalObject = {
              data: modalForm,
              link: inputObject.buttonProps.buttonLink,
              method: inputObject.buttonProps.buttonMethod,
              id: detailID,
              recharge: true,
            };
            dispatch(saveModalForm(modalObject));
          };
        } else if (input.field_name === numerateExpedient) {
          inputObject.buttonProps.onClick = (modalForm) => {
            const detailID = window.location.pathname.split('/')[6];
            const modalObject = { data: modalForm, link: inputObject.buttonProps.buttonLink, method: inputObject.buttonProps.buttonMethod, id: detailID };
            dispatch(saveModalForm(modalObject));
          };
        } else {
          inputObject.buttonProps.onClick = (modalForm) => {
            const detailID = window.location.pathname.split('/')[6];
            const modalObject = { data: modalForm, link: inputObject.buttonProps.buttonLink, method: inputObject.buttonProps.buttonMethod, id: detailID };
            dispatch(saveModalForm(modalObject));
          };
        }
      } else if (input.field_type === 'button') {
        inputObject.buttonProps.getsModalForm = true;
        if (input.field_name === pickPointOriginID) {
          inputObject.buttonProps.onClick = (modalForm) => {
            handlePickTransportSelection({ modalForm, dispatch, id: pickPointOriginID });
            dispatch(emptyActualModal());
          };
        } else if (input.field_name === pickPointDestinyID) {
          inputObject.buttonProps.onClick = (modalForm) => {
            handlePickTransportSelection({ modalForm, dispatch, id: pickPointDestinyID });
            dispatch(emptyActualModal());
          };
        } else if (input.field_name === imoButtonID) {
          inputObject.buttonProps.onClick = (modalForm) => {
            handleIMOSelection({ modalForm, dispatch });
            dispatch(emptyActualModal());
          };
        } else if (input.field_name === selectBookingScale) {
          inputObject.buttonProps.onClick = (modalForm) => {
            handleChangeStopOver({ modalForm, dispatch });
            dispatch(emptyActualModal());
          };
        } else if (input.field_name === selectBookingShip) {
          inputObject.buttonProps.onClick = (modalForm) => {
            handleChangeShip({ modalForm, dispatch });
            dispatch(emptyActualModal());
          };
        } else if (input.field_name === newBl) {
          inputObject.buttonProps.onClick = (modalForm) => {
            if (modalForm.table_selection instanceof Array && modalForm.table_selection.length) {
              const modalObject = {
                method: input.method,
                link: input.link,
                modalData: { pedidos: [...modalForm.table_selection] },
              };
              dispatch(emptyActualModal());
              getFinalModal(modalObject, dispatch);
            } else {
              dispatch(loadError({ message: 'Debes seleccionar al menos un pedido' }));
            }
          };
        } else if (input.field_name === cancelID) {
          inputObject.buttonProps.onClick = () => {
            dispatch(emptyModalFormData());
            dispatch(emptyActualModal());
          };
        } else {
          inputObject.buttonProps.onClick = () => {};
        }
      }
      return inputObject;
    });
  }

  if (modal.modal_message) {
    actualModal.message = modal.modal_message;
  }

  dispatch(setActualModal(actualModal));
}

export async function getActualModal(modal, dispatch) {
  const { method, link, modal_name } = modal;
  let response = null;
  const token = localStorage.getItem('accessToken');
  const config = { headers: { Authorization: `Bearer ${token}` } };

  try {
    switch (method) {
      case 'GET':
        response = await apiClient.get(link, config);
        break;
      case 'POST':
        response = await apiClient.post(link, config);
        break;
      case 'PUT':
        response = await apiClient.put(link, config);
        break;
      case 'DELETE':
        response = await apiClient.delete(link, config);
        break;
      case 'PATCH':
        response = await apiClient.patch(link, config);
        break;
      default:
        response = await apiClient.get(link, config);
        break;
    }
    const { data } = response;
    const modalObject = {
      name: modal_name,
      inputs: data.inputs,
      table: data.table,
      meta: data._meta || data.table?._meta,
      links: data._links || data.table?._links,
      multiselect: modal.multiselect || data.multiselect,
    };

    buildModal(modalObject, dispatch);
  } catch (error) {
    dispatch(loadError(error));
  }
}

export function sendModalAction({ link, name }) {
  return async (dispatch) => {
    const endpoint = link;
    const token = localStorage.getItem('accessToken');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const successStatuses = [200, 204];

    try {
      const response = await apiClient.get(endpoint, config);
      if (successStatuses.includes(response.status)) {
        if (response.data.table && response.data.inputs) {
          buildModal({
            name: response.data.name || name,
            inputs: response.data.inputs,
            table: response.data.table,
          }, dispatch);
        }
      }
    } catch (error) {
      dispatch(loadError(error));
    }
  };
}
