/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import $ from 'jquery';
import Global from '../Contexts/Global';
import NavigationBar from '../Components/Generic/NavigationBar';
import SideBar from '../Components/Generic/SideBar';
import BasicModal from '../Components/Generic/BasicModal';
import Table from '../Components/Generic/Table';
import Form from '../Components/Generic/Form';
import PushNotification from '../Components/Generic/PushNotification';
import { emptyActualModal } from '../redux/actions/modalActions';
import { emptyReduxTable, getTable } from '../redux/actions/tableActions';
import { sendAction, saveForm, setFormData, setResendForm } from '../redux/actions/globalActions';
import useStyles from './style';

const Layout = ({ dispatch, children, actualModal, error, reduxTable, buscar, formData, modalFormData }) => {
  const { i18n } = useContext(Global);
  const classes = useStyles();
  const location = useLocation();
  const [modal, setModal] = useState(actualModal);
  const [openNav, setOpenNav] = useState(false);
  const [margin, setMargin] = useState('0px');
  const [openModal, setOpenModal] = useState(!!actualModal);
  const [openError, setOpenError] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorSeverity, setErrorSeverity] = useState('');
  const autocompleteError = 'debería contener al menos';
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');
  const [openPopup, setOpenPopup] = useState(false);
  const [popup, setPopup] = useState({});
  const [linkAceptar, setAceptar] = useState(false);
  const [linkCancelar, setCancelar] = useState(false);
  const [popupIndex, setPopupIndex] = useState(false);
  const [popupForm, setPopupForm] = useState(null);

  useEffect(() => {
    const path = children?.props?.location?.pathname;
    if (!path.includes('/inquiries/inquiries-butransa/order/') && !path.includes('/inquiries/inquiries-butransa/booking/')) {
      $('main').removeClass('crosstrade export import');
      $('main .makeStyles-paper-5').removeClass('crosstrade export import');
    }
  });

  useEffect(() => {
    if (openNav) {
      setMargin('240px');
    } else {
      setMargin('0px');
    }
  }, [openNav]);

  useEffect(() => {
    setOpenModal(!!actualModal);
    setModal(actualModal);
  }, [actualModal]);

  useEffect(() => {
    if (reduxTable?.meta) {
      setRowsPerPage(reduxTable?.meta?.perPage);
    }
  }, [reduxTable]);

  function handleAccept() {
    const loc = location.pathname.split('/');
    if (popupIndex) {
      let sendId = loc[5] + '/0';
      if (linkAceptar) {
        dispatch(setResendForm(linkAceptar));
        sendId = loc[5] + '/' + linkAceptar;
      }
      const isNew = loc[5];
      const tabName = loc[3];
      const newForm = {
        data: formData,
        tab: tabName,
        id: sendId,
        isNew: isNew === 'new',
        onSuccess: (reload) => {
          if (reload) {
            window.location.reload();
          }
        },
      };
      dispatch(saveForm(newForm));
    } else if (linkAceptar) {
      dispatch(sendAction({ link: linkAceptar }));
    }
    setOpenPopup(false);
  }
  function handleCancel() {
    const loc = location.pathname.split('/');
    if (popupIndex) {
      let sendId = loc[5] + '/0';
      if (linkCancelar) {
        dispatch(setResendForm(linkCancelar));
        sendId = loc[5] + '/' + linkCancelar;
      }
      const isNew = loc[5];
      const tabName = loc[3];
      const newForm = {
        data: formData,
        tab: tabName,
        id: sendId,
        isNew: isNew === 'new',
        onSuccess: (reload) => {
          if (reload) {
            window.location.reload();
          }
        },
      };
      dispatch(saveForm(newForm));
    } else if (linkCancelar) {
      dispatch(sendAction({ link: linkCancelar }));
    }
    setOpenPopup(false);
  }
  function handleClosePopup() {
    if (linkCancelar) {
      dispatch(sendAction({ link: linkCancelar }));
      setOpenPopup(false);
    } else {
      setPopupIndex(9);
      dispatch(setResendForm(9));
      setOpenPopup(false);
    }
  }

  function oldHandleDispatchAction() {
    if (linkAceptar) {
      dispatch(sendAction({ link: linkAceptar }));
      setOpenPopup(false);
    } else if (popupIndex) {
      const isNew = location.pathname.split('/')[5];
      const tabName = location.pathname.split('/')[3];
      const sendId = location.pathname.split('/')[5] + '/' + popupIndex;
      const newForm = {
        data: formData,
        tab: tabName,
        id: sendId,
        isNew: isNew === 'new',
        onSuccess: (reload) => {
          if (reload) {
            window.location.reload();
          }
        },
      };
      dispatch(saveForm(newForm));
      setOpenPopup(false);
    }
  }

  function oldHandleClosePopup() {
    if (linkCancelar) {
      dispatch(sendAction({ link: linkCancelar }));
    } else {
      setOpenPopup(false);
    }
  }

  function handleOpenPopup() {
    setOpenPopup(true);
  }

  useEffect(() => {
    const message = error?.message || error?.data?.validate_error?.message || error?.data?.message;
    const severity = (error?.success || error?.result === 'success') ? 'success' : 'error';
    const haspopup = error?.popup ? error?.popup : false;
    const stateValue = error?.value ? error?.value : false;
    if (!haspopup) {
      setOpenError(error);
      setErrorMessage(message);
      setErrorSeverity(severity);
    } else {
      setOpenModal(false);
      const link = haspopup?.link_aceptar ? haspopup?.link_aceptar : false;
      const link_cancelar = haspopup?.link_cancelar ? haspopup?.link_cancelar : false;
      const popupform = haspopup?.popupform ? haspopup.popupform : false;
      setAceptar(link);
      setCancelar(link_cancelar);
      setPopupIndex(popupform);
      const newFormData = {
        grid: { style: { width: '85%', margin: 'inherit' }, spacing: 1 },
        inputs: [],
        buttons: [
          { label: 'Si', gridProps: { lg: 6, style: { display: 'flex', alignItems: 'flex-center' } }, buttonProps: { variant: 'contained', onClick: handleAccept } },
          { label: 'No', gridProps: { lg: 6, style: { display: 'flex', alignItems: 'flex-center' } }, buttonProps: { variant: 'outlined', onClick: handleCancel } },
        ],
      };
      setPopupForm(newFormData);
      setPopup(haspopup);
      handleOpenPopup();
    }
    if (severity === 'success' && stateValue && modalFormData?.inquiry_new_state?.name) {
      dispatch(setFormData({ id: 'estat_desc', value: modalFormData?.inquiry_new_state?.name }));
      dispatch(setFormData({ id: 'estat_id', value: stateValue }));
    }
  }, [error, linkAceptar, linkCancelar, popupIndex]);

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';
    const productsOrder = isAsc ? 'desc' : 'asc';
    const query = buscar;
    let queryIndex = null;
    let url = null;
    if (reduxTable) {
      queryIndex = reduxTable?.links.first.href.indexOf('?');
      url = reduxTable?.links.first.href.slice(0, queryIndex);
    } else {
      queryIndex = actualModal?.links.first.href.indexOf('?');
      url = actualModal?.links.first.href.slice(0, queryIndex);
    }
    if (isDesc) {
      setOrderBy(false);
    } else {
      setOrderBy(column);
    }

    setOrderProducts(productsOrder);
    dispatch(getTable({ url, query, page, perPage: rowsPerPage, order: productsOrder, orderBy: column }));
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    const query = buscar;
    let queryIndex = null;
    let url = null;
    if (reduxTable) {
      queryIndex = reduxTable?.links.first.href.indexOf('?');
      url = reduxTable?.links.first.href.slice(0, queryIndex);
    } else {
      queryIndex = actualModal?.links.first.href.indexOf('?');
      url = actualModal?.links.first.href.slice(0, queryIndex);
    }
    dispatch(getTable({ url, query, page: newPage + 1, perPage: rowsPerPage, order: orderProducts, orderBy }));
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPage);
    const queryIndex = reduxTable?.links.first.href.indexOf('?');
    const query = buscar;
    const url = reduxTable?.links.first.href.slice(0, queryIndex) + '/';
    dispatch(getTable({ url, query, page, perPage: rowsPage, order: orderProducts, orderBy }));
  };

  return (
    <>
      <PushNotification
        open={(openError && !openError?.data?.validate_errors?.nom?.includes(autocompleteError))}
        message={errorMessage}
        handleClose={() => setOpenError(false)}
        severity={errorSeverity}
      />
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <NavigationBar
            menuActive={openNav}
            handleToggleDrawer={() => setOpenNav(!openNav)}
          />
        </Grid>
        <Grid item xs={12}>
          <SideBar
            open={openNav}
            handleToggleDrawer={() => setOpenNav(!openNav)}
          />
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: openNav,
            })}
          >
            <div className={classes.drawerHeader} />
            <div className={classes.paper} style={{ marginLeft: margin }}>
              <div>
                {children}
              </div>
            </div>
          </main>
        </Grid>
      </Grid>
      { (openPopup) && (
        <BasicModal
          open={openPopup}
          title={popup?.header}
          closeFunction={() => { setOpenPopup(false); }}
        >
          {Boolean(popup?.message) && (
            <div>
              <span>{popup?.message}</span>
            </div>
          )}
          { Boolean(popupForm) && (
            <div style={{ marginBottom: '10px', marginTop: '10px' }}>
              <Form newForm={popupForm} />
            </div>
          )}
        </BasicModal>
      )}

      {(modal && Boolean(Object.keys(modal)?.length) && !modal.flag && !openPopup)
        && (
          <BasicModal
            open={openModal}
            title={i18n.t(modal.name)}
            closeFunction={() => {
              setOpenModal(false);
              dispatch(emptyActualModal());
              dispatch(emptyReduxTable());
            }}
          >
            {Boolean(modal?.modal_message || modal?.message) && (
              <div>
                <span>{modal?.modal_message || modal?.message}</span>
              </div>
            )}
            {Boolean(modal.form?.inputs?.length) && (
              <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                <Form newForm={modal.form} isModal />
              </div>
            )}
            {Boolean(modal?.table?.titles?.length || modal?.table?.rows?.length) && (
              <>
                {!(reduxTable?.meta?.totalCount || actualModal?.meta?.totalCount)
                  ? (
                    <Table
                      tableData={{
                        ...modal.table,
                        rows: modal?.table?.rows?.map((row, index) => ({ ...row, rowID: index })),
                      }}
                      maxHeight="57.4vh"
                      isModal
                      selectItems
                      allowMultipleSelects={actualModal?.multiselect}
                    />
                  )
                  : (
                    <Table
                      tableData={{
                        ...modal.table,
                        rows: modal?.table?.rows?.map((row, index) => ({ ...row, rowID: index })),
                      }}
                      maxHeight="57.4vh"
                      orderBy={orderBy}
                      orderProducts={orderProducts}
                      onOrderProducts={handleRequestSort}
                      isModal
                      selectItems
                      pageObject={{ page, handleChangePage }}
                      rowsPerPageObject={{ rowsPerPage, handleChangeRowsPerPage }}
                      pageCount={reduxTable?.meta?.totalCount || actualModal?.meta?.totalCount || 0}
                      withPaginate
                      allowMultipleSelects={actualModal?.multiselect}
                    />
                  )}
              </>
            )}
          </BasicModal>
        )}
    </>
  );
};

function mapStateToProps({ globalReducer, modalReducer, tableReducer }) {
  return {
    modalFormData: modalReducer.modalFormData,
    formData: globalReducer.formData,
    actualModal: modalReducer.actualModal,
    buscar: modalReducer.modalFormData.Buscar,
    error: globalReducer.error,
    reduxTable: tableReducer.table,
  };
}

export default connect(mapStateToProps)(Layout);
