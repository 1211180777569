import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dashboardCard: {
    width: '400px',
    margin: theme.spacing(3),
    cursor: 'pointer',
    transition: 'transform .2s',
    '&:hover': {
      '-ms-transform': 'scale(1.05)', /* IE 9 */
      '-webkit-transform': 'scale(1.05)', /* Safari 3-8 */
      transform: 'scale(1.05)',
    },
  },
  dashboardInfo: {
    padding: theme.spacing(3),
    borderRadius: '8px',
  },
  topCard: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    borderRadius: '50%',
    height: 'fit-content',
  },
}));

export default useStyles;
