/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Paper, InputAdornment } from '@material-ui/core';
import { AccountCircle, Lock } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Form from '../../Components/Generic/Form';
import { emptyError, loginUser } from '../../redux/actions/globalActions';
import useStyles from './style';
import logo from '../../assets/butransa-logo.png';
import PushNotification from '../../Components/Generic/PushNotification';

function Login({ dispatch, actualUser, error }) {
  const classes = useStyles();
  const history = useHistory();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');

  const form = {
    inputs: [
      {
        label: 'USER',
        name: 'user',
        placeholder: '',
        textFieldProps: {
          fullWidth: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start" style={{ paddingBottom: '3px' }}>
                <AccountCircle />
              </InputAdornment>
            ),
          },
          style: { marginBottom: '5%' },
        },
        gridProps: { lg: 12 },
      },
      {
        label: 'PASSWORD',
        name: 'password',
        placeholder: '',
        textFieldProps: {
          fullWidth: true,
          type: 'password',
          InputProps: {
            startAdornment: (
              <InputAdornment position="start" style={{ paddingBottom: '3px' }}>
                <Lock />
              </InputAdornment>
            ),
          },
        },
        gridProps: { lg: 12 },
      },
    ],
    buttons: [
      { label: 'LOGIN', buttonProps: { type: 'submit', variant: 'contained', fullWidth: true }, gridProps: { lg: 12, style: { display: 'flex', justifyContent: 'center', paddingTop: '30px' } } },
    ],
  };

  if (actualUser?.success) {
    history.push('/dashboard');
  }

  const handleOnSubmitLogin = (event) => {
    const { user, password } = event.target;
    const newUser = { user: user.value, password: password.value };
    (dispatch(loginUser(newUser)));
    event.preventDefault();
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    if (error) {
      setOpenAlert(true);
      setAlertMessage(error.data?.message || error.message);
      setAlertSeverity(error.success ? 'success' : 'error');
    }
    return () => {
      dispatch(emptyError());
    };
  }, [error]);

  return (
    <div className={classes.loginContainer}>
      <div className={classes.formContainer}>
        <Paper className={classes.paper}>
          <div className={classes.logoCont}>
            <img src={logo} alt="Butransa Logo" />
          </div>
          <Form newForm={form} onSubmit={handleOnSubmitLogin} shrink />
        </Paper>
      </div>
      <PushNotification
        open={openAlert}
        message={alertMessage}
        handleClose={handleCloseAlert}
        anchor={{ top: '-3px', right: '10px' }}
        severity={alertSeverity}
      />
    </div>
  );
}

function mapStateToProps({ globalReducer }) {
  return {
    actualUser: globalReducer.actualUser,
    error: globalReducer.error,
  };
}

export default connect(mapStateToProps)(Login);
