import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import $ from 'jquery';
import TabsNavigation from '../../Components/Generic/TabsNavigation';
import ExportForm from '../../Components/Specific/ExportForm';
import Incidents from '../../Components/Specific/Incidents';
import TransportOrders from '../../Components/Specific/TransportOrders';
import Comodities from '../../Components/Specific/Comodities';
import Documents from '../../Components/Specific/Documents';
import Equatorial from '../../Components/Specific/Equatorial';
import { emptyFormData, getOrder, setFormData, getOrderType } from '../../redux/actions/globalActions';
import { emptyModalFormData } from '../../redux/actions/modalActions';

const tabs = [
  { route: 'R_INFORMATION', key: 'INFORMATION', label: 'INFORMATION', link: 'information' },
  { route: 'R_COMMODITY', key: 'COMMODITY', label: 'COMMODITY', link: 'comodity' },
  { route: 'R_DOCUMENTATION', key: 'DOCUMENTATION', label: 'DOCUMENTATION', link: 'documentation' },
  { route: 'R_DELIVERY_ORDERS', key: 'DELIVERY_ORDERS', label: 'DELIVERY_ORDERS', link: 'delivery-orders' },
  { route: 'R_INCIDENCE', key: 'INCIDENCE', label: 'INCIDENCES', link: 'incidences' },
];

const tabsWithEquatorial = [
  { route: 'R_INFORMATION', key: 'INFORMATION', label: 'INFORMATION', link: 'information' },
  { route: 'R_EDI', key: 'EDI', label: 'EDI', link: 'edi' },
  { route: 'R_COMMODITY', key: 'COMMODITY', label: 'COMMODITY', link: 'comodity' },
  { route: 'R_DOCUMENTATION', key: 'DOCUMENTATION', label: 'DOCUMENTATION', link: 'documentation' },
  { route: 'R_DELIVERY_ORDERS', key: 'DELIVERY_ORDERS', label: 'DELIVERY_ORDERS', link: 'delivery-orders' },
  { route: 'R_INCIDENCE', key: 'INCIDENCE', label: 'INCIDENCES', link: 'incidences' },
];

const createOrderTabs = [
  { route: 'R_INFORMATION', key: 'INFORMATION', label: 'INFORMATION', link: 'information' },
  { route: 'R_COMMODITY', key: 'COMMODITY', label: 'COMMODITY', link: 'comodity' },
];

function SingleOrder({ dispatch, actualOrder, hasEquatorialTab, actualModal, actualOrderType }) {
  const [order, setOrder] = useState(actualOrder);
  const type = useLocation().pathname.split('/')[3];
  const location = useLocation().pathname.split('/')[4];
  const orderNumber = useLocation().pathname.split('/')[5];
  const refreshTabs = ['documentation', 'delivery-orders', 'incidences'];
  const newOrderKey = 'new';

  function getActualTab(actualLocation) {
    let tabComponent = null;
    switch (actualLocation) {
      case 'information':
        tabComponent = <ExportForm detail={order} setDetail={setOrder} />;
        break;
      case 'comodity':
        tabComponent = <Comodities detail={order} setDetail={setOrder} />;
        break;
      case 'documentation':
        tabComponent = <Documents detail={order} setDetail={setOrder} />;
        break;
      case 'delivery-orders':
        tabComponent = <TransportOrders detail={order} setDetail={setOrder} />;
        break;
      case 'incidences':
        tabComponent = <Incidents detail={order} setDetail={setOrder} />;
        break;
      case 'edi':
        tabComponent = <Equatorial detail={order} setDetail={setOrder} />;
        break;
      default:
        tabComponent = <ExportForm detail={order} setDetail={setOrder} />;
        break;
    }

    return tabComponent;
  }

  function getTabs() {
    let actualTabs = tabs;
    if (orderNumber === newOrderKey) {
      actualTabs = createOrderTabs;
    } else if (hasEquatorialTab) {
      actualTabs = tabsWithEquatorial;
    }

    return actualTabs;
  }

  useEffect(() => {
    dispatch(getOrderType(orderNumber));
    if (orderNumber && location) {
      dispatch(getOrder(orderNumber, location));
    }
  }, [location]);

  useEffect(() => {
    if (refreshTabs.includes(location)) {
      dispatch(getOrder(orderNumber, location));
    }
  }, [actualModal]);

  useEffect(() => {
    setOrder(actualOrder);
  }, [actualOrder]);

  useEffect(() => {
    if (actualOrderType?.form?.inputs[0]?.textFieldProps?.defaultValue?.id === 'IMP') {
      $('main').removeClass('crosstrade export').addClass('import');
      $('main .makeStyles-paper-5').removeClass('crosstrade export').addClass('import');
    } else if (actualOrderType?.form?.inputs[0]?.textFieldProps?.defaultValue?.id === 'EXP') {
      $('main').removeClass('import crosstrade').addClass('export');
      $('main .makeStyles-paper-5').removeClass('import crosstrade').addClass('export');
    } else if (actualOrderType?.form?.inputs[0]?.textFieldProps?.defaultValue?.id === 'X') {
      $('main').removeClass('import export').addClass('crosstrade');
      $('main .makeStyles-paper-5').removeClass('import export').addClass('crosstrade');
    }
  }, [actualOrderType]);

  useEffect(() => {
    dispatch(emptyFormData());
    dispatch(emptyModalFormData());
    dispatch(setFormData({ id: 'validated', value: false }));
    if (orderNumber === newOrderKey) {
      const dateKey = 'data';
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const fullDate = `${year}-${month}-${day}`;
      dispatch(setFormData({ id: dateKey, value: fullDate }));
    }
  }, []);

  return (
    <Paper>
      <TabsNavigation tabs={getTabs()} type={type} />
      {getActualTab(location)}
    </Paper>
  );
}

SingleOrder.propTypes = {
  dispatch: PropTypes.func.isRequired,
  actualOrder: {
    form: {
      inputs: {
        textFieldProps: {
          defaultValue: {
            id: PropTypes.string,
          },
        },
      },
    },
  },
  actualOrderType: {
    form: {
      inputs: {
        textFieldProps: {
          defaultValue: {
            id: PropTypes.string,
          },
        },
      },
    },
  },
  hasEquatorialTab: PropTypes.bool,
  actualModal: PropTypes.shape({}).isRequired,
};

SingleOrder.defaultProps = {
  actualOrder: {
    form: {
      inputs: {
        textFieldProps: {
          defaultValue: {
            id: PropTypes.string,
          },
        },
      },
    },
  },
  actualOrderType: {
    form: {
      inputs: {
        textFieldProps: {
          defaultValue: {
            id: PropTypes.string,
          },
        },
      },
    },
  },
  hasEquatorialTab: false,
};

function mapStateToProps({ globalReducer, modalReducer }) {
  return {
    actualOrder: globalReducer.actualOrder,
    actualOrderType: globalReducer.actualOrderType,
    hasEquatorialTab: globalReducer.hasEquatorialTab,
    actualModal: modalReducer.actualModal,
  };
}

export default connect(mapStateToProps)(SingleOrder);
