import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from '../../Generic/Form';
import useStyles from './style';

export default function ReceiverInformation({ detail }) {
  const classes = useStyles();
  const grid = { spacing: 3 };
  const newDetail = { ...detail, form: { ...detail.form, grid } };

  return (
    <div className={classes.mainContainer}>
      <section>
        {Boolean(newDetail?.form?.inputs?.length || newDetail?.form?.buttons?.length)
        && (
        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          <Form newForm={newDetail.form} formType="receiver" />
        </div>
        )}
      </section>
    </div>
  );
}

ReceiverInformation.propTypes = {
  detail: PropTypes.shape({
    form: PropTypes.shape({}),
  }).isRequired,
};

ReceiverInformation.defaultProps = {};
