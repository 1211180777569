import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import AppRoute from './AppRoute';
import Layout from './Layout';
import List from './Pages/List/index';
import Receiver from './Pages/Receiver/index';
import Receivers from './Pages/Receivers/index';
import SingleOrder from './Pages/SingleOrder';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Singlebooking from './Pages/SingleBooking';

const Routes = () => (
  <BrowserRouter basename="/butransa">
    <Switch>
      <Route path="/login" component={Login} />
      <AppRoute path="/dashboard" component={Dashboard} layout={Layout} />
      <AppRoute path="/inquiries/inquiries-butransa/order/:tab/:order" component={SingleOrder} layout={Layout} />
      <AppRoute path="/inquiries/inquiries-butransa/booking/:tab/:booking" component={Singlebooking} layout={Layout} />
      <AppRoute path="/inquiries/inquiries-butransa" component={List} layout={Layout} />
      <AppRoute path="/receivers/receiver/edit/:id" component={Receiver} layout={Layout} />
      <AppRoute path="/receivers/receiver/new" component={Receiver} layout={Layout} />
      <AppRoute path="/receivers" component={Receivers} layout={Layout} />
      <Route exact path="/">
        <Redirect to="login" />
      </Route>
      <Route exact path="/inquiries/inquiries-butransa/">
        <Redirect to="/inquiries/inquiries-butransa/exportación" />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default Routes;
