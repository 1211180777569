/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from '../../Generic/Form';
import useStyles from './style';
import Table from '../../Generic/Table';

export default function Equatorial({ detail }) {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState('');
  const [orderProducts, setOrderProducts] = useState('asc');

  const grid = { spacing: 3 };
  const newDetail = { ...detail, form: { ...detail.form, grid } };

  async function handleRequestSort(column) {
    const isAsc = orderBy === column && orderProducts === 'asc';
    const isDesc = orderBy === column && orderProducts === 'desc';

    if (isDesc) {
      setOrderBy(false);
    } else {
      setOrderBy(column);
    }

    setOrderProducts(isAsc ? 'desc' : 'asc');
  }

  return (
    <div className={classes.mainContainer}>
      <section>
        {Boolean(newDetail?.form?.inputs?.length || newDetail?.form?.buttons?.length)
        && (
        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          <Form newForm={newDetail.form} />
        </div>
        )}
        {Boolean(newDetail?.table?.titles?.length || newDetail?.table?.rows?.length)
        && <Table tableData={newDetail.table} orderBy={orderBy} orderProducts={orderProducts} onOrderProducts={handleRequestSort} />}
      </section>
    </div>
  );
}

Equatorial.propTypes = {
  detail: PropTypes.shape({
    form: PropTypes.shape({}),
  }).isRequired,
};

Equatorial.defaultProps = {};
