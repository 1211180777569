const actionTypes = {
  SET_USER: 'SET_USER',
  LOAD_ERROR: 'LOAD_ERROR',
  SET_INQUIRES: 'SET_INQUIRES',
  SET_RECEIVERS: 'SET_RECEIVERS',
  REQUEST_RESPONSE: 'REQUEST_RESPONSE',
  SET_ACTUAL_ORDER: 'SET_ACTUAL_ORDER',
  SET_ACTUAL_BOOKING: 'SET_ACTUAL_BOOKING',
  SET_DASHBOARD: 'SET_DASHBOARD',
  SET_FILTER_LIST: 'SET_FILTER_LIST',
  SET_EQUATORIAL_TAB: 'SET_EQUATORIAL_TAB',
  SET_ACTUAL_MODAL: 'SET_ACTUAL_MODAL',
  SET_FORM_DATA: 'SET_FORM_DATA',
  EMPTY_FORM_DATA: 'EMPTY_FORM_DATA',
  SER_DOCUMENT_CREATION_STATUS: 'SER_DOCUMENT_CREATION_STATUS',
  SET_CLEAN_LIST: 'SET_CLEAN_LIST',
  EMPTY_INQUIRIES: 'EMPTY_INQUIRIES',
  SET_FILES: 'SET_FILES',
  SET_TABLE: 'SET_TABLE',
  SET_REDUX_TABLE_ROWS: 'SET_REDUX_TABLE_ROWS',
  SET_NEW_BOOKING_ORDER_ID: 'SET_NEW_BOOKING_ORDER_ID',
  EMPTY_POPUP_OPTIONS: 'EMPTY_POPUP_OPTIONS',
  SET_POPUP_OPTIONS: 'SET_POPUP_OPTIONS',
  SET_LAST_INQUIRES_SEARCH: 'SET_LAST_INQUIRES_SEARCH',
  EMPTY_ERROR: 'EMPTY_ERROR',
  EMPTY_TABLE: 'EMPTY_TABLE',
  SET_SETTED_ORDER_NUMBER: 'SET_SETTED_ORDER_NUMBER',
  SET_RESEND_FORM: 'SET_RESEND_FORM',
  SET_ACTUAL_ORDER_TYPE: 'SET_ACTUAL_ORDER_TYPE',
  SET_ACTUAL_BOOKING_TYPE: 'SET_ACTUAL_BOOKING_TYPE',
  SET_RECEIVER: 'SET_RECEIVER',
  SET_CLIENTS: 'SET_CLIENTS',
};

export default actionTypes;
